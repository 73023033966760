import { gql } from "@apollo/client";

export const GET_WOS_QUERY = gql`
  query getWorkOrders(
    $facilityId: String!
    $startDate: DateTime!
    $endDate: DateTime!
  ) {
    facilityById(facilityId: $facilityId) {
      workOrders(startDate: $startDate, stopDate: $endDate) {
        id
        name
        customerName
        customerDisplayName
        description
        name
        orderDate
        orderedBy
        orderNumber
        plannedStartDate
        plannedStopDate
        startDate
        status
        derivedStatus
        stopDate
        poNumber
        mCO {
          referenceOrderLine
          referenceOrderNumber
        }
        workOrderType {
          id
          name
        }
      }
    }
  }
`;

export const GET_WOS_EXTRA_QUERY = gql`
  query getWorkOrders_EXTRA(
    $facilityId: String!
    $startDate: DateTime!
    $endDate: DateTime!
  ) {
    facilityById(facilityId: $facilityId) {
      workOrders(startDate: $startDate, stopDate: $endDate) {
        id
        # assignedTeam {
        #   id
        #   name
        # }
        mCO {
          referenceOrderLine
          referenceOrderNumber
        }
        workOrderType {
          id
          name
          description
          status
        }
      }
    }
  }
`;
