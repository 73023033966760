import React, { FC, memo, useState } from "react";

import filter from "@/style/img/icons/filter.svg";
import search from "@/style/img/icons/search_icon.svg";
import { SelectSingleProps } from "@/types";

import CustomSelect from "../formElements/reactSelect";
import TeamTagChips from "./createNewTeam/teamTagChips";

import stylesBuilder from "./index.module.scss";
import styles from "@/components/common/formElements/customFormStyles.module.scss";

type Props = {
  searchValue: string;
  tagFilterValues: string[];
  sortFilter: SelectSingleProps | undefined;
  setSearchFilter: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setSortFilter: (value: SelectSingleProps) => void;
  setTagFilter: (e: string) => void;
  removeTag: (e: string) => void;
};

const sortFilterOptions = [
  {
    value: "a-z",
    label: "ALPHABETICAL A-Z",
  },
  {
    value: "z-a",
    label: "ALPHABETICAL Z-A",
  },
  {
    value: "LAST CREATED",
    label: "LAST CREATED",
  },
  {
    value: "LAST USED",
    label: "LAST USED",
  },
  {
    value: "PLANNED FOR",
    label: "PLANNED FOR",
  },
];

const TeamBuilderFilters: FC<Props> = ({
  searchValue,
  tagFilterValues,
  setSearchFilter,
  setTagFilter,
  setSortFilter,
  sortFilter,
  removeTag,
}) => {
  const [newTagValue, setNewTagValue] = useState("");

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [errors, setErrors] = useState<any>({});

  const searchInput = () => {
    const render = (
      <div className={styles.searchFilter}>
        <img src={search} alt="search" />
        <input
          type="text"
          placeholder="SEARCH BY TEAM NAME"
          value={searchValue}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setSearchFilter(e)
          }
        />
      </div>
    );
    return render;
  };

  const setNewTag = () => {
    if (newTagValue !== "") {
      if (tagFilterValues.find((e) => e === newTagValue)) {
        setErrors({ ...errors, tags: true });
      } else {
        setTagFilter(newTagValue);
        setNewTagValue("");
        if (errors.tags) {
          setErrors({ ...errors, tags: false });
        }
      }
    }
  };

  const tagFilter = () => {
    const render = (
      <div className={`${styles.searchFilter} ${styles.tagFilter}`}>
        <img src={filter} alt="filter" />
        <input
          style={errors.tags ? { border: "1px solid #BB0000" } : {}}
          type="text"
          placeholder="FILTER BY TAG"
          value={newTagValue}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setNewTagValue(e.target.value)
          }
          onBlur={setNewTag}
          onKeyPress={(e: React.KeyboardEvent<HTMLDivElement>) => {
            if (e.key === "Enter") {
              setNewTag();
            }
          }}
        />
      </div>
    );
    return render;
  };

  return (
    <>
      <div className={stylesBuilder.teamFilters}>
        {searchInput()}
        <CustomSelect
          value={sortFilter}
          onChange={(e) => {
            setSortFilter(e as SelectSingleProps);
          }}
          placeholder="SORT BY"
          options={sortFilterOptions}
        />
        {tagFilter()}
      </div>
      <div className={stylesBuilder.teamsListGroup}>
        <section>
          <span>Team</span>
          <span>Tags</span>
          <span>Planned for</span>
          <span>Actions</span>
        </section>
      </div>
      <TeamTagChips tags={tagFilterValues} handleRemoveTag={removeTag} />
    </>
  );
};
export default memo(TeamBuilderFilters);
