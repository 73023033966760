import { useMutation, useQuery } from "@apollo/client";
import isEmpty from "lodash/isEmpty";
import uniqBy from "lodash/uniqBy";
import React, { FC, memo, useMemo, useState } from "react";

import ReactLoader from "@/components/common/formElements/loader";
import CustomMultiSelect from "@/components/common/formElements/multiselect/customMultiSelect";
import useDeleteTeamFromWorkOrder from "@/hooks/mutation/useDeleteTeamFromWorkOrder";
import plusIcon from "@/style/img/icons/plus.svg";
import {
  Nullable,
  SelectSingleProps,
  Vessel,
  WorkOrder as WorkOrderTypes,
  WorkOrderLineOperator,
  WorkOrderLineWithTimestampOperations,
} from "@/types";
import { useSettingsStore, useUserData } from "@/zustand/useSettingsStore";

import {
  ASSIGN_EMPLOYEE_TO_WORK_ORDER_LINE,
  AssignEmployeeToWorkOrderLineInputType,
  AssignEmployeeToWorkOrderLineReturnType,
  GET_CERTIFIED_OPERATORS,
} from "./queries";

import styles from "../sidePanel.module.scss";
import textStyles from "@/style/textStyles.module.scss";

const DEFAULT_PLANNING_AREA = "BASE";
type Props = {
  activeWorkOrderLine: WorkOrderLineWithTimestampOperations | undefined;
  searchValue: string;
  setOperator: (v: { name: string; id: string }) => void;
  equipment: { id: string; name: string };
  entry: Nullable<Vessel> | Nullable<WorkOrderTypes>;
  requiresEquipmentOperator: boolean;
};

function getTeamId(
  entry: Nullable<Vessel> | Nullable<WorkOrderTypes>,
  activeWO: string | undefined,
): string | undefined {
  if (!entry || !activeWO) {
    return undefined;
  }
  let team;
  if ("assignedTeam" in entry) {
    team = entry?.assignedTeam;
  } else {
    // Vessel work orders
    const activeWorkOrder = entry?.workOrders?.filter(
      (wo) => wo.id === activeWO,
    )[0];
    team = activeWorkOrder?.assignedTeam;
  }

  return team?.id;
}

const EmployeesList: FC<Props> = ({
  activeWorkOrderLine,
  searchValue,
  setOperator,
  equipment,
  entry,
  requiresEquipmentOperator,
}) => {
  const userData = useUserData();
  const selectedFacility = useSettingsStore((state) => state.selectedFacility);
  const deleteTeam = useDeleteTeamFromWorkOrder();
  const [assignEmployeeToWorkOrderLine] = useMutation<
    AssignEmployeeToWorkOrderLineReturnType,
    AssignEmployeeToWorkOrderLineInputType
  >(ASSIGN_EMPLOYEE_TO_WORK_ORDER_LINE);
  const [availableOperators, setAvailableOperators] = useState<
    WorkOrderLineOperator[]
  >([]);
  const [allOperators, setAllOperators] = useState<WorkOrderLineOperator[]>([]);
  const selectedPAsForEmployees = useSettingsStore(
    (state) => state.selectedPAsForEmployees,
  );
  const setSelectedPAsForEmployees = useSettingsStore(
    (state) => state.setSelectedPAsForEmployees,
  );
  // console.debug(
  //   activeWorkOrderLine?.capabilityRequirements?.[0]?.id,
  //   equipment?.id,
  //   equipment?.id?.split("-")[1],
  //   equipment?.name,
  //   "<-- value to be used just now for CapableOperators"
  // );

  let operationElementId =
    equipment.name !== "Unassigned" ? equipment.id.split("-")[1] : undefined;
  // Notice that this attribute activeWorkOrderLine?.capabilityRequirements?.[0]?.id
  // can be a Category (general work center) or a concrete equipment.
  // AND it takes time to get refreshed, so the employees list gets out of sync.
  // We don't want to send wrong associations equpment+operator to M3.
  // That's why to use better (if available) the equipment that user just clicked.
  if (!operationElementId) {
    operationElementId = activeWorkOrderLine?.assignment?.operationElementId;
  }
  const { loading } = useQuery(GET_CERTIFIED_OPERATORS, {
    variables: {
      from: activeWorkOrderLine?.plannedStartDate,
      to: activeWorkOrderLine?.plannedStartDate,
      facilityId: selectedFacility,
      operationElementId,
    },
    skip: !activeWorkOrderLine,
    onCompleted: (res) => {
      const areaCounts = res.certifiedOperatorsWithinTimeframe?.reduce(
        (acc, el) => {
          acc[el?.planningAreaId] = (acc[el?.planningAreaId] || 0) + 1;
          return acc;
        },
        {},
      );
      const formattedOperators = res.certifiedOperatorsWithinTimeframe.map(
        (op) => {
          return {
            planningAreaCount: areaCounts[op.planningAreaId],
            ...op,
          };
        },
      );
      setAllOperators(formattedOperators);
      setAvailableOperators(
        formattedOperators
          .map((op) => {
            return {
              planningAreaCount: areaCounts[op.planningAreaId],
              ...op,
            };
          })
          .filter((operator: WorkOrderLineOperator) =>
            isEmpty(selectedPAsForEmployees)
              ? [
                  {
                    value: DEFAULT_PLANNING_AREA,
                    label: DEFAULT_PLANNING_AREA,
                  },
                ]
                  .map((i) => i.value)
                  .includes(operator.planningAreaId)
              : selectedPAsForEmployees
                  ?.map((i) => i.value)
                  .includes(operator.planningAreaId),
          ),
      );
    },
  });
  const handleAssignEmployee = (operatorId: string) => {
    assignEmployeeToWorkOrderLine({
      variables: {
        facilityId: selectedFacility,
        issuedBy: userData.mail,
        // Here, there is no need to split the id ("220-CR10100") like in capableOperators
        workCenterId: equipment.id || "",
        operatorId,
        workOrderLineId: activeWorkOrderLine?.id ?? "",
        capabilityType:
          activeWorkOrderLine?.assignment?.capabilityTypeNumber?.toString() ??
          "",
      },
      onCompleted: () => {
        const activeWorkOrderId = activeWorkOrderLine?.workOrderId;
        const workOrder = entry as WorkOrderTypes;
        const teamId = getTeamId(workOrder, activeWorkOrderId);
        if (teamId && activeWorkOrderId) {
          deleteTeam(teamId, userData.mail, activeWorkOrderLine?.workOrderId);
        }
      },
    });
  };

  function sortEmployees(a: WorkOrderLineOperator, b: WorkOrderLineOperator) {
    const noCollator = new Intl.Collator("no");
    const firstNameA = a.firstName.toString();
    const firstNameB = b.firstName.toString();
    const lastNameA = a.lastName.toString();
    const lastNameB = b.lastName.toString();
    return noCollator.compare(firstNameA, firstNameB) === 0
      ? noCollator.compare(lastNameA, lastNameB)
      : noCollator.compare(firstNameA, firstNameB);
  }
  const possiblePlanningAreas = uniqBy(allOperators, "planningAreaId").map(
    (operator) => ({
      label: operator.planningAreaId,
      value: operator.planningAreaId,
      count: operator.planningAreaCount,
    }),
  );

  const defaultActivePAs =
    selectedPAsForEmployees?.length > 0
      ? selectedPAsForEmployees
      : [
          {
            value: DEFAULT_PLANNING_AREA,
            label: DEFAULT_PLANNING_AREA,
          },
        ];
  const possibleDefaultActivePAs = defaultActivePAs.filter((a) =>
    possiblePlanningAreas.find((b) => b.value === a.value),
  );

  const sortedEmployees = useMemo(() => {
    if (!isEmpty(availableOperators)) {
      return [...availableOperators].sort(sortEmployees);
    }
    return [];
  }, [availableOperators]);

  if (!activeWorkOrderLine) {
    return null;
  }
  return (
    <>
      {!loading && (
        <div className={styles.planningArea}>
          <span className={textStyles.secondaryText}>Planning Area:</span>
          <CustomMultiSelect
            onChange={(values: SelectSingleProps[]) => {
              setSelectedPAsForEmployees(values);
              setAvailableOperators(
                allOperators.filter((operator: WorkOrderLineOperator) =>
                  values
                    .map((item) => item.value)
                    .includes(operator.planningAreaId),
                ),
              );
            }}
            placeholder="PLANNING AREA"
            options={possiblePlanningAreas}
            allButtonName="Select all"
            noneButtonName="Deselect all"
            preselected={[]}
            activeOptions={possibleDefaultActivePAs}
            storageKey="employee-list-dropdown"
          />
        </div>
      )}
      <div className={styles.employeesList}>
        {loading ? (
          <ReactLoader />
        ) : (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {!loading && !isEmpty(sortedEmployees)
              ? sortedEmployees.map(
                  (el: WorkOrderLineOperator) =>
                    activeWorkOrderLine?.assignment?.operator?.id !== el.id &&
                    `${el.firstName} ${el.lastName}`
                      .toLowerCase()
                      .includes(searchValue.toLowerCase()) && (
                      <div key={el.id} className={styles.employeesListItem}>
                        <div>
                          <span
                            className={`${styles.greenBlock} ${textStyles.primaryText}`}
                          >
                            {`${el.firstName} ${el.middleName} ${el.lastName}`}
                          </span>
                        </div>
                        <div className={styles.assignButton}>
                          <button
                            className={styles.iconButton}
                            type="button"
                            onClick={() => {
                              handleAssignEmployee(el.id);
                              setOperator({
                                name: `${el.firstName} ${el.lastName}`,
                                id: el.id,
                              });
                            }}
                          >
                            <img src={plusIcon} alt="Add input" />
                            <span className={textStyles.secondaryText}>
                              Assign
                            </span>
                          </button>
                        </div>
                      </div>
                    ),
                )
              : requiresEquipmentOperator &&
                equipment?.name && (
                  <div
                    className={textStyles.secondaryText}
                    style={{ paddingTop: "10px", color: "#888" }}
                  >
                    No operators available for {equipment.name}
                  </div>
                )}
          </>
        )}
      </div>
    </>
  );
};
export default memo(EmployeesList);
