import { gql } from "@apollo/client";

export const UPDATE_VESSEL_ORDERED_BY = gql`
  mutation updateVesselOrderOrderedBy(
    $issuedBy: String!
    $orderedBy: String!
    $rentalAgreementId: String!
    $rentalAgreementLineId: String!
  ) {
    updateVesselOrderOrderedBy(
      issuedBy: $issuedBy
      orderedBy: $orderedBy
      rentalAgreementId: $rentalAgreementId
      rentalAgreementLineId: $rentalAgreementLineId
    )
  }
`;

export const UPDATE_WORKORDER_ORDERED_BY = gql`
  mutation updateWorkOrderOrderedBy(
    $issuedBy: String!
    $orderedBy: String!
    $maintenanceCustomerOrderNumber: String!
    $maintenanceCustomerOrderLineNumber: String!
  ) {
    updateWorkOrderOrderedBy(
      issuedBy: $issuedBy
      orderedBy: $orderedBy
      maintenanceCustomerOrderNumber: $maintenanceCustomerOrderNumber
      maintenanceCustomerOrderLineNumber: $maintenanceCustomerOrderLineNumber
    )
  }
`;
