import { LinearProgress } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import React, { FC, memo, useMemo, useState } from "react";

import { useOrderStore } from "@/zustand/useOrderStore";

import EachMaterialItem, { MaterialItem } from "./EachMaterialItem";
import NewMaterialItem from "./newMaterialItem";

import styles from "./index.module.scss";
import textStyles from "@/style/textStyles.module.scss";

type Props = {
  workOrderId: string;
  initialItems: MaterialItem[];
};

// Custom sorting function
function customSort(a, b) {
  if (a.itemName < b.itemName) {
    return -1;
  }
  if (a.itemName > b.itemName) {
    return 1;
  }
  return 0;
}

const MaterialsList: FC<Props> = ({ workOrderId, initialItems }) => {
  const [materials, setMaterials] = useState<MaterialItem[]>(initialItems);
  // const { loading, refetch } = useQuery(GET_MATERIALS, {
  //   variables: { workOrderId },
  //   onCompleted: (res) => {
  //     setMaterials(res?.workOrderById?.workOrderMaterials || []);
  //   },
  // });
  const broadcastWO = useOrderStore((state) => state.broadcastWO);
  useMemo(() => {
    setMaterials(broadcastWO?.workOrderById?.workOrderMaterials);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(broadcastWO)]);

  const handleDeleteMaterial = (id: string) => {
    const newArr = [...materials];
    if (newArr.find((el) => id === el.id)) {
      newArr.splice(
        newArr.findIndex((i) => i.id === id),
        1,
      );
      setMaterials(newArr);
    }
  };
  const handleUpdateMaterial = (id: string, quantity) => {
    const newArr = [...materials];
    const materialIndex = newArr.findIndex((i) => i.id === id);

    if (materialIndex !== -1) {
      newArr[materialIndex] = {
        ...newArr[materialIndex],
        quantity,
      };
      setMaterials(newArr);
    }
  };

  const sortedMaterials = useMemo(() => {
    // Sort the operation lines using the custom comparator
    // Create a new array from orderLines because sometimes could complain about "read-only" array
    if (isEmpty(materials)) {
      return [];
    }
    return [...materials].sort(customSort);
  }, [materials]);

  return (
    <div className={styles.ItemsWrapper}>
      <h5 className={textStyles.primaryText} style={{ marginBottom: "10px" }}>
        {materials?.length} ITEMS:
      </h5>
      {/* <HandleNotifications debouncedCallback={debouncedRefetchWorkOrder} /> */}

      <div style={{ position: "relative" }}>
        {!broadcastWO && <LinearProgress />}
      </div>
      {!isEmpty(sortedMaterials) &&
        sortedMaterials.map((item) => (
          <EachMaterialItem
            key={item?.id}
            data={item}
            removeMaterial={handleDeleteMaterial}
            updateMaterial={handleUpdateMaterial}
          />
        ))}
      <NewMaterialItem workOrderId={workOrderId} />
    </div>
  );
};
export default memo(MaterialsList);
