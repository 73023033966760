import React, { memo } from "react";

import CustomSnackbar from "@/components/common/snackbar/customSnackBar";
import { useSettingsStore } from "@/zustand/useSettingsStore";

const TimelineSnackbar: React.FC = () => {
  const isSnackbarOn = useSettingsStore((state) => state.isSnackbarOn);

  if (isSnackbarOn) {
    return <CustomSnackbar />;
  }
  return null;
};

export default memo(TimelineSnackbar);
