export function isInsideModal(
  element: HTMLElement | null,
  modalClass: string,
  modalClass2: string,
  modalClass3: string,
): boolean {
  while (element) {
    if (
      element.classList.contains(modalClass) ||
      element.classList.contains(modalClass2) ||
      element.classList.contains(modalClass3)
    ) {
      return true;
    }
    // eslint-disable-next-line no-param-reassign
    element = element.parentElement;
  }
  return false;
}
