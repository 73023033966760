import isEmpty from "lodash/isEmpty";
import React, { memo } from "react";

import { useOrderStore } from "@/zustand/useOrderStore";

import SidePanel from "./sidePanel";

// We have this component to force the mount/unmount of SidePanel.
// There is a lot of state that will benefit from reseting, instead of having <SidePanel> always mounted, even when SidePanel is hidden
const SidePanelWrapper = (props) => {
  const sidePanelOrder = useOrderStore((state) => state.sidePanelOrder);
  return isEmpty(sidePanelOrder) ? null : (
    <SidePanel sidePanelOrder={sidePanelOrder} {...props} />
  );
};

export default memo(SidePanelWrapper);
