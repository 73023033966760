import { gql } from "@apollo/client";

export const GET_GHOSTS_QUERY = gql`
  query getGhostOrders(
    $facilityId: String!
    $startDate: DateTime!
    $stopDate: DateTime!
  ) {
    facilityById(facilityId: $facilityId) {
      ghostWorkOrderActivities(startDate: $startDate, stopDate: $stopDate) {
        customerName
        reserve
        id
        name
        plannedStart
        plannedStop
        attachments {
          id
          filename
        }
      }
    }
  }
`;
