import { gql } from "@apollo/client";

export const SEARCH_CUSTOMERS = gql`
  query searchRentalAgreements($searchTerm: String!, $facilityId: String!) {
    searchRentalAgreements(searchTerm: $searchTerm, facilityId: $facilityId) {
      facilityId
      agreementCustomer
      name
      id
    }
  }
`;

export const SEARCH_NAMES = gql`
  query searchVessels($searchTerm: String!) {
    searchVessels(searchTerm: $searchTerm) {
      name
      id
    }
  }
`;
