import moment, { Moment } from "moment-timezone";
import React, { FC, memo, useMemo, useState } from "react";

import DateAndTimeField from "@/components/common/formElements/dateAndTimeField";
import { validateStartBeforeStop } from "@/helpers/dateTimeValidator";
import { useVesselMooring } from "@/hooks/mutation/useVesselMooring";
import { useSnackbar } from "@/zustand/useSnackbar";

import styles from "../../index.module.scss";
import buttons from "@/style/buttons.module.scss";
import textStyles from "@/style/textStyles.module.scss";

type Props = {
  orderId: string;
  initialStartDate: string;
  initialStopDate: string;
  initialPlannedStartDate: string;
  changeModalState: () => void;
};
const ChangeStartStopModal: FC<Props> = ({
  orderId,
  initialStartDate,
  initialStopDate,
  initialPlannedStartDate,
  changeModalState,
}) => {
  const initialStart = initialStartDate ? moment(initialStartDate) : null;
  const initialStop = initialStopDate ? moment(initialStopDate) : null;
  const [startDate, setStartDate] = useState<Moment | null>(initialStart);

  const [dirtyStartDate, setDirstyStartDate] = useState<boolean>(false);

  const { setStart: startVesselMooring } = useVesselMooring();

  const setSnack = useSnackbar((state) => state.setSnack);

  const isValidRange = useMemo(() => {
    if (!initialStop) {
      // TODO: Delete after it works fine.
      if (
        startDate &&
        startDate.isAfter(moment(initialPlannedStartDate).add(1, "days"))
      ) {
        return false;
      }
      return true;
    }
    if (startDate && initialStop) {
      return validateStartBeforeStop(startDate, initialStop);
    }

    return false;
  }, [initialPlannedStartDate, initialStop, startDate]);

  const showConfirmButton = dirtyStartDate && isValidRange;

  return (
    <div className={styles.startVesselModal}>
      <h5 className={textStyles.primaryText}>Start Vessel</h5>
      <span className={textStyles.primaryText}>
        Confirm or change actual start time.
      </span>
      <div>
        <span className={textStyles.primaryText}>Actual start:</span>
        <DateAndTimeField
          autoFocus
          dateValue={startDate}
          handleChange={(newValue) => {
            setDirstyStartDate(true);
            setStartDate(newValue);
          }}
        />
      </div>

      {startDate && initialStop && !isValidRange && (
        <p className="error-message">
          Check that the start date is earlier than the stop date{" "}
          {initialStopDate}
          <br /> Give it another try!
        </p>
      )}
      {startDate &&
        startDate.isAfter(moment(initialPlannedStartDate).add(1, "days")) && (
          <p className="error-message">
            There is a big difference between planned start date and actual
            start date.
            <br /> First, move the planned start date to the same day, then
            start the Vessel
          </p>
        )}

      <section>
        <button
          className={buttons.modalButton}
          type="button"
          onClick={changeModalState}
        >
          Cancel
        </button>
        <button
          className={buttons.modalButton}
          type="button"
          onClick={() => {
            if (!orderId) return;

            if (startDate && dirtyStartDate) {
              startVesselMooring({
                id: orderId,
                plannedStartDate: startDate?.toISOString(),
              });
            }
            setSnack(true, "Vessel mooring started.");
            changeModalState();
          }}
          disabled={!showConfirmButton}
        >
          Confirm changes
        </button>
      </section>
    </div>
  );
};
export default memo(ChangeStartStopModal);
