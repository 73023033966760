import React from "react";

import styles from "./styles.module.scss";

interface Props extends React.HTMLProps<HTMLInputElement> {
  label: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  disabled?: boolean;
}

const Checkbox: React.FC<Props> = ({
  label,
  onChange,
  checked,
  disabled = false,
}) => (
  <label
    style={disabled ? { opacity: 0.3 } : { opacity: 1 }}
    className={`${styles.root} stoppropagation`}
  >
    <input
      className={`${styles.checkbox} stoppropagation`}
      type="checkbox"
      checked={checked}
      onChange={onChange}
      disabled={disabled}
    />
    <span className={`${styles.label} stoppropagation`}>{label}</span>
  </label>
);

export default Checkbox;
