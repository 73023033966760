import React, { FC } from "react";

import styles from "@/style/buttons.module.scss";
import textStyles from "@/style/textStyles.module.scss";

interface Props extends React.HTMLProps<HTMLButtonElement> {
  firstTitle: string;
  secondTitle?: string;
  activeButtonIndex: number;
  handleActiveButton: (idx: number) => void;
  textAllowed?: boolean;
  iconsAllowed?: boolean;
  disabled?: boolean;
  leftButtonText?: string;
  rightButtonText?: string;
}

const GroupButtons: FC<Props> = ({
  firstTitle,
  secondTitle = "",
  activeButtonIndex = 1,
  handleActiveButton,
  iconsAllowed = false,
  disabled = false,
  textAllowed = false,
  leftButtonText = "",
  rightButtonText = "",
}) => {
  return (
    <div className={styles.groupButtonContainer}>
      <span
        onClick={() => handleActiveButton(1)}
        onKeyPress={() => handleActiveButton(1)}
        role="button"
        tabIndex={0}
        className={textStyles.secondaryText}
      >
        {textAllowed && leftButtonText}
      </span>
      <div className={styles.groupButton}>
        <div
          tabIndex={0}
          role="button"
          onKeyDown={() => null}
          title={leftButtonText}
          onClick={() => handleActiveButton(1)}
          style={
            activeButtonIndex === 1
              ? {
                  backgroundColor: disabled
                    ? "#F7F7F7"
                    : "rgba(0, 173, 187, 0.5)",
                  border: disabled ? "1px solid #aeaeae" : "1px solid #00adbb",
                }
              : { backgroundColor: "#F7F7F7" }
          }
          className="flex-center"
        >
          {iconsAllowed ? (
            <img src={firstTitle} alt="icon" />
          ) : (
            <span>{firstTitle}</span>
          )}
        </div>
        {secondTitle && (
          <div
            tabIndex={0}
            role="button"
            onKeyDown={() => null}
            title={rightButtonText}
            onClick={() => handleActiveButton(2)}
            style={
              activeButtonIndex === 2
                ? {
                    backgroundColor: disabled
                      ? "#F7F7F7"
                      : "rgba(0, 173, 187, 0.5)",
                    border: disabled
                      ? "1px solid #aeaeae"
                      : "1px solid #00adbb",
                  }
                : { backgroundColor: "#F7F7F7" }
            }
            className="flex-center"
          >
            {iconsAllowed ? (
              <img src={secondTitle} alt="icon" />
            ) : (
              <span>{secondTitle}</span>
            )}
          </div>
        )}
      </div>

      <span
        onClick={() => handleActiveButton(2)}
        onKeyPress={() => handleActiveButton(2)}
        role="button"
        tabIndex={0}
        className={textStyles.secondaryText}
      >
        {textAllowed && rightButtonText}
      </span>
    </div>
  );
};

export default GroupButtons;
