import React, { FC, memo, useRef } from "react";

// import minus from "style/img/minus.svg";
import search from "@/style/img/icons/search_icon.svg";

// import moment, { Moment } from "moment-timezone";
// import st from "../../header/index.module.scss";
// import DateAndTimeField from "../formElements/dateAndTimeField";
import styles from "./index.module.scss";

type Props = {
  handleSearch: (v: string) => void;
  // setDate: (dates: (Moment | null)[]) => void;
  // startDate: Moment | null;
  // endDate: Moment | null;
  // searchValue: string;
};
// const customStyle = {
//   width: "140px",
//   label: { top: "-7px" },
//   "label.MuiInputLabel-shrink": { top: "0px" },
// };

const SearchFilters: FC<Props> = ({
  handleSearch,
  // searchValue,
  // startDate,
  // endDate,
  // setDate,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const inputRef = useRef<HTMLInputElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      handleSearch(e.target.value);
    }
  };

  const handleClick = () => {
    const inputValue = inputRef?.current?.value;
    if (inputValue) {
      handleSearch(inputValue);
    }
  };
  return (
    <div className={styles.filters}>
      <div className={styles.searchFilter}>
        <input
          type="text"
          placeholder="Search by order number"
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          ref={inputRef}
          // value={searchValue}
          onKeyPress={handleKeyPress}
          // onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          //   handleSearch(e.target.value)
          // }
        />
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <img
          src={search}
          onClick={handleClick}
          onKeyPress={handleKeyPress}
          alt="search"
        />
      </div>
      {/* <div className={`${st.datePickerWrapper} flex-center`}>
        <DateAndTimeField
          label="Start"
          dateFormat="DD.MM.YYYY          HH"
          dateValue={moment(startDate)}
          customStyle={customStyle}
          handleChange={(newValue) => setDate([newValue, endDate])}
        />
        <img src={minus} alt="min" style={{ margin: "0 6px" }} />
        <DateAndTimeField
          label="End"
          dateFormat="DD.MM.YYYY          HH"
          dateValue={endDate}
          customStyle={customStyle}
          handleChange={(newValue) => setDate([startDate, newValue])}
        />
      </div> */}
    </div>
  );
};
export default memo(SearchFilters);
