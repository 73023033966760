import React, { FC, RefObject } from "react";

import { DraggableItemType, DropEventEmitter } from "@/types";

import { DroppableCell } from "./droppableCell";

import styles from "./index.module.scss";

type Props = DropEventEmitter & {
  timeLine: string[];
  quayId: string;
  height: number;
  elementWidth: number;
  children: React.ReactNode;
  draggingTooltipRef: RefObject<HTMLDivElement>;
};

const TableRow: FC<Props> = ({
  timeLine,
  height,
  elementWidth,
  quayId,
  onDrop,
  children,
  draggingTooltipRef,
}) => {
  const dynamicStyles = {
    height,
    width: (elementWidth / 2) * timeLine.length,
  };

  return (
    <div className={styles.tableRow} style={dynamicStyles}>
      <div className={styles.cellsWrapper}>
        <DroppableCell
          timeLine={timeLine}
          quayId={quayId}
          onDrop={onDrop}
          elementWidth={elementWidth}
          draggingTooltipRef={draggingTooltipRef}
          accept={[
            DraggableItemType.Order,
            DraggableItemType.WorkOrder,
            DraggableItemType.GhostOrder,
            DraggableItemType.SubWorkOrder,
          ]}
        >
          {children}
        </DroppableCell>
      </div>
    </div>
  );
};
export default TableRow;
