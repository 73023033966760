/* eslint-disable no-restricted-syntax */
import { useEffect } from "react";

import { useInProgressStore } from "@/zustand/useInProgressStore";
import { useSnackbar } from "@/zustand/useSnackbar";

/**
 * Listen for inProgress changes and display snackbar if there is an entry that expired.
 */
const useSnackbarOnTimeoout = (): void => {
  const setSnack = useSnackbar((state) => state.setSnack);

  // TODO: Better to do this in a .subscribe function inside the orderstore
  // Then this component only has to subscribe to some derived value
  const inProgressSnackMessage = useInProgressStore(
    (state) => state.inProgressSnackMessage,
  );
  useEffect(() => {
    if (inProgressSnackMessage) {
      setSnack(true, inProgressSnackMessage, true);
    }
  }, [inProgressSnackMessage, setSnack]);
  // const inProgressWorkOrders = useAppSelector(

  //   (state) => state.WorkOrderUpdatedFromM3.inProgress
  // );
  // const inProgressVessels = useAppSelector(
  //   (state) => state.VesselAddedToQuay.inProgress
  // );

  // useEffect(() => {
  //   let expiredObj: ExpiredReadModelDraft = null;

  //   for (const key in inProgressWorkOrders) {
  //     if (inProgressWorkOrders[key].expired) {
  //       expiredObj = {
  //         id: inProgressWorkOrders[key].id,
  //         slice: "WorkOrderUpdatedFromM3",
  //       } as ExpiredReadModelDraft;
  //       break;
  //     }
  //   }

  //   for (const key in inProgressVessels) {
  //     if (inProgressVessels[key].expired) {
  //       expiredObj = {
  //         id: inProgressVessels[key].id,
  //         slice: "VesselAddedToQuay",
  //       } as ExpiredReadModelDraft;
  //       break;
  //     }
  //   }

  //   if (expiredObj) {
  //     setSnack(
  //       true,
  //       `Something might have gone wrong with saving ${expiredObj.id}`,
  //       true
  //     );

  //     // dispatch failed action, removing them from the inProgress list
  //     switch (expiredObj.slice) {
  //       case "VesselAddedToQuay":
  //         dispatch(vesselAddedToQuayFailed(vessel(expiredObj.id)));
  //         break;
  //       case "WorkOrderUpdatedFromM3":
  //         dispatch(workOrderUpdatedFromM3Failed(workOrder(expiredObj.id)));
  //         break;
  //       default:
  //     }
  //   }
  // }, [setSnack, inProgressWorkOrders, inProgressVessels, dispatch]);
};

export default useSnackbarOnTimeoout;
