import { Fade, Snackbar } from "@mui/material";
import React, { FC, useMemo } from "react";

import { useSnackbar } from "@/zustand/useSnackbar";

import buttons from "@/style/buttons.module.scss";
import textStyles from "@/style/textStyles.module.scss";

const CustomSnackbar: FC = () => {
  const snackbarData = useSnackbar((state) => state.snackbarData);
  const setSnack = useSnackbar((state) => state.setSnack);

  const onClose = () => {
    setSnack(false, "", false);
  };
  const messageClassname = useMemo(() => {
    const message = snackbarData?.message || "";

    if (
      message.includes("failed") ||
      message.includes("error") ||
      message.includes("reject") ||
      snackbarData?.type === "error"
    ) {
      return " error-message";
    }

    return "";
  }, [snackbarData?.message, snackbarData?.type]);

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      TransitionComponent={Fade}
      open={snackbarData.isOpen || false}
      autoHideDuration={6000}
      onClose={onClose}
    >
      <div className="snack-bar">
        <span className={`${textStyles.primaryText} ${messageClassname}`}>
          {snackbarData.message || ""}
        </span>
        {snackbarData.isButtonVisible && (
          <button
            className={buttons.modalButton}
            type="button"
            onClick={onClose}
          >
            dismiss
          </button>
        )}
      </div>
    </Snackbar>
  );
};

export default CustomSnackbar;
