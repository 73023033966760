import { gql } from "@apollo/client";

export const UPDATE_VESSEL_MUTATION = gql`
  mutation allocateVessel(
    $startDate: DateTime!
    $stopDate: DateTime!
    $facilityId: String!
    $issuedBy: String!
    $quayId: String!
    $vesselId: String!
  ) {
    allocateVesselToQuayNew(
      startDate: $startDate
      stopDate: $stopDate
      facilityId: $facilityId
      issuedBy: $issuedBy
      quayId: $quayId
      vesselId: $vesselId
    )
  }
`;
