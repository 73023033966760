import React, { useCallback, useEffect } from "react";
import MouseTooltip from "react-sticky-mouse-tooltip";

import useLiveDomainEvents from "@/hooks/subscriptions/useLiveDomainEvents";
import { useSettingsStore } from "@/zustand/useSettingsStore";

import textStyles from "@/style/textStyles.module.scss";

export const AssignmentLinkTooltip: React.FC = () => {
  const superLinking = useSettingsStore((state) => state.superLinking);
  const disableSuperLinking = useSettingsStore(
    (state) => state.disableSuperLinking,
  );

  const escFunction = useCallback(
    (event) => {
      if (event.key === "Escape") {
        disableSuperLinking();
      }
    },
    [disableSuperLinking],
  );

  // Get quickly the Bearer Token, to use it in GraphQL IDE
  const doubleClick = useCallback((event) => {
    if (
      event?.shiftKey &&
      event?.target?.className?.includes("sidebar-db-click")
    ) {
      navigator?.clipboard?.writeText(window?.BEARER);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    document.addEventListener("dblclick", doubleClick, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
      document.removeEventListener("dblclick", doubleClick, false);
    };
  }, [escFunction, doubleClick]);

  return (
    superLinking?.tooltipText && (
      <MouseTooltip className="mouse-tooltip" visible offsetX={15} offsetY={10}>
        <span className={textStyles.primaryText}>
          {`${superLinking?.tooltipText} (esc to cancel)` || "click"}
        </span>
      </MouseTooltip>
    )
  );
};

// Own sub component to avoid rerenders
export const LiveDomainEvents: React.FC = () => {
  useLiveDomainEvents();
  return null;
};
