import { gql, useMutation } from "@apollo/client";
import { useCallback } from "react";

import { Vessel } from "@/types";
import { useSettingsStore, useUserData } from "@/zustand/useSettingsStore";

const START_VESSEL_MOORING = gql`
  mutation startVesselMooring(
    $facilityId: String!
    $issuedBy: String!
    $actualStartDate: DateTime!
    $vesselId: String!
  ) {
    startVesselMooring(
      facilityId: $facilityId
      issuedBy: $issuedBy
      actualStartDate: $actualStartDate
      vesselId: $vesselId
    )
  }
`;

const STOP_VESSEL_MOORING = gql`
  mutation stopVesselMooring(
    $facilityId: String!
    $issuedBy: String!
    $actualStopDate: DateTime!
    $vesselId: String!
  ) {
    stopVesselMooring(
      facilityId: $facilityId
      issuedBy: $issuedBy
      actualStopDate: $actualStopDate
      vesselId: $vesselId
    )
  }
`;

type StartParams = Pick<Vessel, "plannedStartDate" | "id">;
type StopParams = Pick<Vessel, "plannedStopDate" | "id">;

export const useVesselMooring = (): {
  setStart: typeof setStart;
  setStop: typeof setStop;
} => {
  const selectedFacility = useSettingsStore((state) => state.selectedFacility);
  const userData = useUserData();

  const [startVesselMooring] = useMutation(START_VESSEL_MOORING);
  const [stopVesselMooring] = useMutation(STOP_VESSEL_MOORING);

  const setStart = useCallback(
    async (params: StartParams) => {
      const response = await startVesselMooring({
        variables: {
          facilityId: selectedFacility,
          issuedBy: userData.mail,
          vesselId: params.id,
          actualStartDate: params.plannedStartDate, // update params?
        },
      });
      return response;
    },
    [selectedFacility, startVesselMooring, userData.mail],
  );

  const setStop = useCallback(
    async (params: StopParams) => {
      const response = await stopVesselMooring({
        variables: {
          facilityId: selectedFacility,
          issuedBy: userData.mail,
          vesselId: params.id,
          actualStopDate: params.plannedStopDate,
        },
      });
      return response;
    },
    [selectedFacility, stopVesselMooring, userData.mail],
  );

  return { setStart, setStop };
};
