import { gql, useMutation } from "@apollo/client";

export type UpsertTeamWithOperationLinesModelInput = {
  model: {
    facilityId: string;
    id: string;
    name: string;
    tags: string[];
    issuedBy: string;
    teamWorkOrderLines: TeamWorkOderLineInput[];
  };
};

export type TeamWorkOderLineInput = {
  workCenterMachineId: string | null;
  operatorId: string | null;
  workCenterCategoryId: string | null;
};

const QUERY = gql`
  mutation upsertTeamWithOperationLines(
    $model: UpsertTeamWithOperationLinesModelInput!
  ) {
    upsertTeamWithOperationLines(model: $model)
  }
`;

const useUpdateTeamWithOperationLines = (): { setUpdate: typeof setUpdate } => {
  const [updateTeam, { error }] = useMutation(QUERY);

  if (error) {
    console.error(error);
  }

  const setUpdate = (
    name: string,
    tags: string[],
    issuedBy: string,
    facilityId: string,
    teamWorkOrderLines: TeamWorkOderLineInput[],
    startDate: Date | null,
    id?: string,
  ) => {
    if (id) {
      updateTeam({
        variables: {
          model: {
            id,
            name,
            tags,
            issuedBy,
            facilityId,
            teamWorkOrderLines,
            startDate,
          },
        },
      });
    } else {
      updateTeam({
        variables: {
          model: {
            name,
            tags,
            issuedBy,
            facilityId,
            teamWorkOrderLines,
            startDate,
          },
        },
      });
    }
  };

  return { setUpdate };
};

export default useUpdateTeamWithOperationLines;
