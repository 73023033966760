/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { FC } from "react";
import { Grid } from "react-loader-spinner";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

type Props = {
  color?: string;
  height?: number;
  width?: number;
};

const ReactLoader: FC<Props> = ({
  color = "#00adbb",
  height = 150,
  width = 250,
}) => {
  // const props = {
  //   color,
  //   secondaryColor: color,
  //   height,
  //   width,
  // };
  const props = {
    Component: Grid,
    color,
    secondaryColor: color,
    props: {
      height,
      width,
    },
    name: "Grid",
  };

  return <Grid wrapperClass="loader" {...props} />;
};

export default ReactLoader;
