import React, { FC, memo } from "react";

import arrow from "@/style/img/arrow.svg";
import { useSettingsStore } from "@/zustand/useSettingsStore";

import GhostActivityForm from "./ghostActivityForm";

import styles from "./index.module.scss";

const GhostActivityWrapper: FC = () => {
  const closeSidebarTab = useSettingsStore((state) => state.closeSidebarTab);

  return (
    <div className={styles.ghostActivityWrapper}>
      <nav>
        <button type="button" onClick={() => closeSidebarTab()}>
          <img src={arrow} alt="arrow" />
          GHOST ACTIVITY
        </button>
      </nav>
      <GhostActivityForm />
    </div>
  );
};
export default memo(GhostActivityWrapper);
