import { gql, useMutation } from "@apollo/client";
import React, { FC, memo } from "react";

import { useSettingsStore, useUserData } from "@/zustand/useSettingsStore";

import styles from "../index.module.scss";
import buttons from "@/style/buttons.module.scss";
import textStyles from "@/style/textStyles.module.scss";

type Props = {
  changeModalState: () => void;
  orderId: string;
};

const DELETE_WORK_ORDER = gql`
  mutation deleteWorkOrder(
    $facilityId: String!
    $userId: String!
    $workOrderId: String!
  ) {
    deleteWorkOrder(
      facilityId: $facilityId
      userId: $userId
      workOrderId: $workOrderId
    )
  }
`;

const DeleteWorkOrderModal: FC<Props> = ({ changeModalState, orderId }) => {
  const userData = useUserData();
  const selectedFacility = useSettingsStore((state) => state.selectedFacility);
  const [deleteWorkOrder] = useMutation(DELETE_WORK_ORDER);

  const handleDeleteActivity = () => {
    deleteWorkOrder({
      variables: {
        facilityId: selectedFacility,
        userId: userData.mail,
        workOrderId: orderId,
      },
    });
    changeModalState();
  };

  return (
    <div className={`${styles.startVesselModal} ${styles.deleteVesselModal}`}>
      <h5 className={textStyles.primaryText}>Delete Work Order?</h5>
      <span className={textStyles.primaryText}>
        {`Deleting this work order will
        remove it from the planning board, Merit and M3. Are you sure you want
        to continue?`}
      </span>
      <section>
        <button
          className={buttons.modalButton}
          type="button"
          onClick={changeModalState}
        >
          Cancel
        </button>
        <button
          className={buttons.modalButton}
          type="button"
          onClick={handleDeleteActivity}
        >
          DELETE
        </button>
      </section>
    </div>
  );
};
export default memo(DeleteWorkOrderModal);
