import React, { FC, memo } from "react";

import { Team } from "@/types";

import TeamsListItem from "./teamListItem";

import styles from "./index.module.scss";

type Props = {
  duplicateTeam: (team: Team) => void;
  deleteTeam: (id: string) => void;
  setEditData: (value: Team) => void;
  toggleComponent: () => void;
  teams: Team[];
};

const TeamsList: FC<Props> = ({
  teams,
  duplicateTeam,
  setEditData,
  toggleComponent,
  deleteTeam,
}) => {
  const clone = teams?.slice();
  const sortedTeams = clone?.sort((a, b) => {
    if (a?.name < b?.name) {
      return -1;
    }
    if (a?.name > b?.name) {
      return 1;
    }
    return 0; // names are equal
  });
  return (
    <div className={styles.teamsList}>
      <div className={styles.teamsListItems}>
        {sortedTeams?.map((team) => {
          return (
            <div key={team.id} className={styles.teamsListGroup}>
              <TeamsListItem
                key={team.id}
                data={team}
                duplicateTeam={duplicateTeam}
                setEditData={setEditData}
                toggleComponent={toggleComponent}
                deleteTeam={deleteTeam}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default memo(TeamsList);
