import { useEffect, useState } from "react";

type WindowDimensions = { width: number; height: number };

function getWindowDimensions(): WindowDimensions {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function useWindowDimensions(): WindowDimensions {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    if (document.body.style) {
      // ONLY in Chrome (not firefox)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // document.body.style.zoom = 0.75; // 75%
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}
