import { useMutation } from "@apollo/client";
import React, { FC, memo } from "react";

import {
  DELETE_GHOST_ACTIVITY,
  DELETE_VESSEL_MUTATION,
} from "@/zustand/mutations/deleteVessel";
import { useSettingsStore, useUserData } from "@/zustand/useSettingsStore";

import styles from "../../index.module.scss";
import buttons from "@/style/buttons.module.scss";
import textStyles from "@/style/textStyles.module.scss";

type Props = {
  changeModalState: () => void;
  orderId: string;
  isGhostActivity: boolean;
};

const DeleteVesselModal: FC<Props> = ({
  changeModalState,
  orderId,
  isGhostActivity,
}) => {
  const userData = useUserData();
  const selectedFacility = useSettingsStore((state) => state.selectedFacility);

  const [removeVessel] = useMutation(DELETE_VESSEL_MUTATION);
  const [deleteGhostWorkOrder] = useMutation(DELETE_GHOST_ACTIVITY);
  const handleDeleteActivity = () => {
    if (isGhostActivity) {
      deleteGhostWorkOrder({
        variables: {
          facilityId: selectedFacility,
          issuedBy: userData.mail,
          ghostWorkOrderId: orderId,
        },
      });
    } else {
      removeVessel({
        variables: {
          facilityId: selectedFacility,
          issuedBy: userData.mail,
          vesselId: orderId,
        },
      });
    }
    changeModalState();
  };

  return (
    <div className={`${styles.startVesselModal} ${styles.deleteVesselModal}`}>
      <h5 className={textStyles.primaryText}>
        {`Delete ${isGhostActivity ? "ghost activity" : "vessel"}?`}
      </h5>
      <span className={textStyles.primaryText}>
        <p>
          Want to delete this
          {isGhostActivity
            ? " ghost order? "
            : " vessel from M3 and Planning Board? "}
          <br />
          This action cannot be undone.
        </p>
      </span>
      <section>
        <button
          className={buttons.modalButton}
          type="button"
          onClick={changeModalState}
        >
          No, cancel
        </button>
        <button
          className={buttons.modalButton}
          type="button"
          onClick={handleDeleteActivity}
        >
          Yes, delete
        </button>
      </section>
    </div>
  );
};
export default memo(DeleteVesselModal);
