import {
  AccountInfo,
  AuthError,
  InteractionRequiredAuthError,
  IPublicClientApplication,
} from "@azure/msal-browser";
import { useIsAuthenticated } from "@azure/msal-react";
import { useEffect, useMemo, useState } from "react";

import { captureError, captureMessage } from "@/helpers/captureError";

import { JWT, parseJwt, redirectToLogin } from "./authUtils";

const FIVE_MINUTE_IN_MS = 5 * 60 * 1000;

/**
 * handle token refresh with MSAL library.
 * Polling intervals is adjusted to use the token expiration remaining life (with a safe margin of 1 minute).
 * If isAuthenticated is false by the time we are refreshing the token, then force redirect to login.
 * Refresh tokens expire in 24 hours to which no access token is returned and therefore a
 * interactive-flow is triggered in the app, as per workings of https://docs.microsoft.com/en-us/azure/active-directory/develop/scenario-spa-acquire-token?tabs=javascript2
 * @param instance
 * @param activeToken
 * @param accounts
 */

// Inspired on https://github.com/microsoft/vscode-mssql/blob/ecab696fef15290b3e34f584c1dad5d5245c8a10/src/azure/msal/msalAzureAuth.ts#L154
const accountNeedsRefresh = (error: AuthError) => {
  return (
    error instanceof InteractionRequiredAuthError ||
    error?.errorMessage.includes("AADSTS70043") ||
    error?.errorMessage.includes("AADSTS50020") ||
    error?.errorMessage.includes("AADSTS50173")
  );
};
const useKeepSessionAlive = (
  instance: IPublicClientApplication,
  activeToken: string | null,
  setActiveToken: (token: string) => void,
  accounts: AccountInfo[],
): void => {
  const isAuthenticated = useIsAuthenticated();

  const [tokenExpiration, setTokenExpiration] = useState<Date | null>(null);

  useMemo(() => {
    if (activeToken) {
      const { exp } = parseJwt(activeToken) as JWT;
      setTokenExpiration(new Date(exp * 1000)); // convert epoch seconds to Date
    }
  }, [activeToken]);

  useEffect(() => {
    let currentTimeoutId: NodeJS.Timeout | null = null;

    const refreshAccessToken = async () => {
      try {
        const response = await instance.acquireTokenSilent({
          scopes: ["User.Read"],
          forceRefresh: true, // don't get token from cache, refresh token even if token still is valid
          account: accounts[0],
        });
        const { idToken } = response;
        setActiveToken(idToken);
      } catch (error) {
        // console.error(
        //   "useKeepSessionAlive -> refreshAccessToken",
        //   error,
        //   error instanceof InteractionRequiredAuthError,
        //   error instanceof AuthError,
        //   error?.name,
        //   error?.errorMessage
        // );
        if (accountNeedsRefresh(error)) {
          captureError(error);
          captureMessage("Acquire token silent failure, redirecting to login"); // Expected behavior, but we want to see how often happens
          redirectToLogin(instance);
        }
      }
    };

    if (tokenExpiration) {
      const now = new Date();
      const expiresInMs = tokenExpiration.getTime() - now.getTime();
      if (expiresInMs <= FIVE_MINUTE_IN_MS) {
        if (isAuthenticated) {
          console.debug("refreshing token");
          refreshAccessToken();
        } else {
          redirectToLogin(instance);
        }
      } else {
        // Check again when the token is about to expire
        currentTimeoutId = setTimeout(() => {
          refreshAccessToken();
        }, expiresInMs - FIVE_MINUTE_IN_MS); // A bit before it expires
      }
    }

    return () => {
      if (currentTimeoutId) {
        clearTimeout(currentTimeoutId);
      }
    };
  }, [tokenExpiration, instance, isAuthenticated, accounts, setActiveToken]);
};

export default useKeepSessionAlive;
