import { gql } from "@apollo/client";

export const DELETE_VESSEL_MUTATION = gql`
  mutation deleteVessel(
    $facilityId: String!
    $issuedBy: String!
    $vesselId: String!
  ) {
    deleteVessel(
      facilityId: $facilityId
      issuedBy: $issuedBy
      vesselId: $vesselId
    )
  }
`;

export const DELETE_GHOST_ACTIVITY = gql`
  mutation deleteGhostWorkOrder(
    $facilityId: String!
    $issuedBy: String!
    $ghostWorkOrderId: UUID!
  ) {
    deleteGhostWorkOrder(
      facilityId: $facilityId
      ghostWorkOrderId: $ghostWorkOrderId
      issuedBy: $issuedBy
    )
  }
`;
