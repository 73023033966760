import moment from "moment-timezone";
import { useMemo } from "react";

import { calculateOrderSize } from "@/helpers/calculateOrderSize";
import { VesselPreviewInfo } from "@/types";
import { useSettingsStore } from "@/zustand/useSettingsStore";

type Params = {
  order: Pick<VesselPreviewInfo, "plannedStartDate" | "plannedStopDate">;
  selectedDate: Date;
  elementWidth: number;
  visibleDays: number;
};

export const useOrderSize = ({
  order,
  elementWidth,
}: Params): { width: number; adjustedWidth: number } => {
  const { visibleStartDate, visibleEndDate } =
    useSettingsStore.getState().visibleDateRange;
  return useMemo(() => {
    let finalStartDate = order?.plannedStartDate;
    if (
      order?.normalizedOrderType !== "wo" &&
      order?.normalizedOrderType !== "subwo"
    ) {
      finalStartDate = order?.startDate
        ? order?.startDate
        : order.plannedStartDate;
    }
    const normalizedStartDate = moment(finalStartDate).isSameOrBefore(
      visibleStartDate,
    )
      ? visibleStartDate.toISOString()
      : finalStartDate;

    let finalStopDate = order?.plannedStopDate;
    if (
      order?.normalizedOrderType !== "wo" &&
      order?.normalizedOrderType !== "subwo"
    ) {
      finalStopDate = order?.stopDate ? order?.stopDate : order.plannedStopDate;
    }

    let normalizedStopDate = moment(finalStopDate).isSameOrAfter(visibleEndDate)
      ? visibleEndDate.toISOString()
      : finalStopDate;

    const widthInPixels = calculateOrderSize(
      normalizedStartDate,
      normalizedStopDate,
      elementWidth,
    );

    // ONLY FOR VESSELS
    let adjustedWidthInPixels;
    if (order?.vessel && order?.adjustedStopDate) {
      // if (order.id === "A103051:425:142317") {
      //   console.log(order?.id, order?.adjustedStopDate);
      // }
      normalizedStopDate = moment(order.adjustedStopDate).isSameOrAfter(
        visibleEndDate,
      )
        ? visibleEndDate.toISOString()
        : order.adjustedStopDate;

      adjustedWidthInPixels = calculateOrderSize(
        normalizedStartDate,
        normalizedStopDate,
        elementWidth,
        true,
      );
    }

    return { width: widthInPixels, adjustedWidth: adjustedWidthInPixels };
  }, [order, visibleStartDate, visibleEndDate, elementWidth]);
};
