import { gql, useMutation } from "@apollo/client";
import moment, { Moment } from "moment-timezone";
import React, { FC, memo } from "react";

import DateAndTimeField from "@/components/common/formElements/dateAndTimeField";
import { useUserData } from "@/zustand/useSettingsStore";

import styles from "./index.module.scss";
import otherStyles from "@/components/timeline/sidePanel/sidePanel.module.scss";

type Props = {
  dates: {
    startDate: string;
    stopDate: string | null;
  };
  timestampId: string | undefined;
  operatorId: string | undefined;
};

const UPDATE_START_TIME = gql`
  mutation updateTimestampStartTime(
    $employeeNumber: String!
    $issuedBy: String!
    $startDate: DateTime!
    $timestampId: String!
  ) {
    updateTimestampStartTime(
      employeeNumber: $employeeNumber
      issuedBy: $issuedBy
      startDate: $startDate
      timestampId: $timestampId
    )
  }
`;

const UPDATE_STOP_TIME = gql`
  mutation updateTimestampStopTime(
    $employeeNumber: String!
    $issuedBy: String!
    $stopDate: DateTime!
    $timestampId: String!
  ) {
    updateTimestampStopTime(
      employeeNumber: $employeeNumber
      issuedBy: $issuedBy
      stopDate: $stopDate
      timestampId: $timestampId
    )
  }
`;

const OperationLineDates: FC<Props> = ({ dates, timestampId, operatorId }) => {
  const userData = useUserData();
  const [updateTimestampStartTime] = useMutation(UPDATE_START_TIME);
  const [updateTimestampStopTime] = useMutation(UPDATE_STOP_TIME);

  const handleUpdateStartDate = (newDate: Moment | null) => {
    if (!timestampId || !operatorId || !newDate) return;
    updateTimestampStartTime({
      variables: {
        employeeNumber: operatorId,
        issuedBy: userData.mail,
        startDate: newDate?.toISOString() || "",
        timestampId,
      },
    });
  };

  const handleUpdateStopDate = (newDate: Moment | null) => {
    if (!timestampId || !operatorId || !newDate) return;
    updateTimestampStopTime({
      variables: {
        employeeNumber: operatorId,
        issuedBy: userData.mail,
        stopDate: newDate?.toISOString() || "",
        timestampId,
      },
    });
  };
  return (
    <div className={styles.datePickers}>
      <DateAndTimeField
        disableOpenPicker
        className={otherStyles.nsgDatetimePicker}
        dateValue={dates.startDate ? moment(dates.startDate) : undefined}
        handleChange={handleUpdateStartDate}
      />
      {dates.stopDate && (
        <DateAndTimeField
          disableOpenPicker
          className={otherStyles.nsgDatetimePicker}
          dateValue={dates.stopDate ? moment(dates.stopDate) : undefined}
          handleChange={handleUpdateStopDate}
        />
      )}
    </div>
  );
};
export default memo(OperationLineDates);
