import { useMsal } from "@azure/msal-react";
import React from "react";

import logo from "@/style/img/nsg_logo.svg";

import buttons from "@/style/buttons.module.scss";

const SignIn: React.FC = () => {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginPopup().catch((error) => {
      console.error(error);
    });
  };

  return (
    <div className="login-block">
      <img
        alt="NSG welcome"
        width="200px"
        style={{ marginTop: "-10%" }}
        src={logo}
      />
      <br />
      <button
        className={buttons.mainButton}
        type="button"
        onClick={handleLogin}
        style={{ border: "1px solid #00ADBB" }}
      >
        Log In
      </button>
    </div>
  );
};

export default SignIn;
