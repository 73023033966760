import React, { FC } from "react";

import {
  ORDER_CARD_BORDER_WIDTH,
  ORDER_DRAGGABLE_SIDE_WIDTH,
} from "@/helpers/constants";
import { FunctionParams } from "@/types";

import { draggableSideRendererFactory } from "./draggableSide";

// @TODO: fix card side pulling during the scroll

type Props = FunctionParams<typeof draggableSideRendererFactory> & {
  orderRegularWidth: number;
  orderExpandedWidth: number;
  inDetailedView: boolean;
};

const verticalPositionAlignment = {
  top: ORDER_CARD_BORDER_WIDTH,
  bottom: ORDER_CARD_BORDER_WIDTH,
};

const OrderCardDraggableSides: FC<Props> = ({
  orderRegularWidth,
  orderExpandedWidth,
  inDetailedView,
  ...factoryParams
}) => {
  const renderDraggableSide = draggableSideRendererFactory(factoryParams);

  return (
    <>
      {renderDraggableSide({
        styles: {
          position: {
            ...verticalPositionAlignment,
            left: ORDER_CARD_BORDER_WIDTH,
          },
          backgroundColor: "transparent",
        },
        position: "start",
      })}

      {renderDraggableSide({
        styles: {
          position: {
            ...verticalPositionAlignment,
            left:
              orderRegularWidth -
              (ORDER_CARD_BORDER_WIDTH + ORDER_DRAGGABLE_SIDE_WIDTH),
          },
          backgroundColor: "transparent",
        },
        position: "end",
      })}
      {/* TODO: only render if width < than natural width */}
      {inDetailedView &&
        renderDraggableSide({
          styles: {
            position: {
              ...verticalPositionAlignment,
              left:
                orderExpandedWidth -
                (ORDER_CARD_BORDER_WIDTH + ORDER_DRAGGABLE_SIDE_WIDTH),
            },
            backgroundColor: "transparent",
          },
          position: "end",
        })}
    </>
  );
};

export default OrderCardDraggableSides;
