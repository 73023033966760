import { useLazyQuery } from "@apollo/client";
import isEmpty from "lodash/isEmpty";
import React, { FC, useCallback, useEffect, useState } from "react";

import CustomSelect from "@/components/common/formElements/reactSelect";
import { isOperatorDEPRECATED } from "@/helpers/normalizeCapabilityRequirements";
import trash from "@/style/img/icons/trash.svg";
import {
  CenterById,
  ItemById,
  SelectSingleProps,
  TeamOperationLine,
  TeamWorkCenter,
  WorkOrderLineOperator,
} from "@/types";
import { useSettingsStore } from "@/zustand/useSettingsStore";

import {
  GET_EQUIPMENT_FOR_CATEGORY,
  GET_OPERATORS_FOR_WORKCENTER,
} from "./queries";

import st from "../index.module.scss";
import styles from "./index.module.scss";

type OnSelectionSet = (
  selection: SelectSingleProps,
  index: number,
  changeOrigin: Dropdown,
) => void;

type OnSelectionRemove = (id: string, index: number) => void;

type OperationItemRowProps = {
  index: number;
  id: string;
  isLastRow: boolean;
  defaultOptions: TeamOperationLine | null;
  allCategories: SelectSingleProps[];
  allEquipment: SelectSingleProps[];
  allEquipmentById: CenterById;
  allCategoriesById: ItemById;
  allOperators: SelectSingleProps[];
  selectedPAsForEquip: any[];
  selectedPAsForOperators: any[];
  onDeleteItem: OnSelectionRemove;
  onSelectItem: OnSelectionSet;
};

export enum Dropdown {
  EQUIPMENT,
  CATEGORY,
  OPERATOR,
}

const OperationLineRow: FC<OperationItemRowProps> = ({
  index,
  id,
  isLastRow,
  defaultOptions,
  allCategories,
  allEquipment,
  allEquipmentById,
  allCategoriesById,
  allOperators,
  selectedPAsForEquip,
  selectedPAsForOperators,
  onDeleteItem,
  onSelectItem,
}) => {
  const selectedFacility = useSettingsStore((state) => state.selectedFacility);
  const [enabledDropdowns, setEnableDropdowns] = useState<
    Record<string, boolean | null>
  >({ category: true, equipment: false, operator: false });

  const [selectedCategory, setCategory] = useState<SelectSingleProps | null>();
  const [selectedEquipment, setEquipment] =
    useState<SelectSingleProps | null>();
  const [selectedOperator, setOperator] = useState<SelectSingleProps | null>();

  const [useDefaultOptions, setUseDefaultOptions] =
    useState<boolean>(!!defaultOptions);
  const filteredEquip = allEquipment.filter((item: any) =>
    selectedPAsForEquip?.map((i) => i.value).includes(item.planningAreaId),
  );
  const [equipmentList, setEquipmentList] = useState(filteredEquip);
  const [categoryList, setCategoryList] = useState(allCategories);
  const [operatorsList, setOperatorsList] = useState<SelectSingleProps[]>([]);

  const [getEquipmentForCategory, equipmentForCategory] = useLazyQuery(
    GET_EQUIPMENT_FOR_CATEGORY,
  );

  const [getOperatorsForEquipment, operatorsForEquipment] = useLazyQuery(
    GET_OPERATORS_FOR_WORKCENTER,
  );
  const [getOperatorsForCategory, operatorsForCategory] = useLazyQuery(
    GET_OPERATORS_FOR_WORKCENTER,
  );

  // TODO: Reevaluate if this is necesary
  const rowHasSelection = useCallback(
    (origin: Dropdown) => {
      switch (origin) {
        case Dropdown.CATEGORY:
          return selectedEquipment || selectedOperator;
        case Dropdown.EQUIPMENT:
          return false;
        case Dropdown.OPERATOR:
          return selectedEquipment || selectedCategory;
        default:
          break;
      }
      return true;
    },
    [selectedEquipment, selectedOperator, selectedCategory],
  );

  const updateDropdownOptionsForRow = useCallback(
    (origin: Dropdown, newSelection: SelectSingleProps) => {
      if (origin === Dropdown.CATEGORY) {
        const { workCenterType, operationElementId } =
          allCategoriesById[newSelection?.value];
        getEquipmentForCategory({
          variables: {
            facilityId: selectedFacility,
            workCenterType,
          },
        });
        getOperatorsForCategory({
          variables: {
            facilityId: selectedFacility,
            operationElementId,
          },
        });
      }

      if (origin === Dropdown.EQUIPMENT) {
        const { parentId, operationElementId } =
          allEquipmentById[newSelection?.value];
        if (!rowHasSelection(origin)) {
          getOperatorsForEquipment({
            variables: {
              facilityId: selectedFacility,
              operationElementId,
            },
          });
        }

        // populate category (parent work center) based on equipment (child work center)
        // setCategory(allCategoriesById?.[parentId]);
      }
    },
    [
      allCategoriesById,
      getEquipmentForCategory,
      selectedFacility,
      getOperatorsForCategory,
      allEquipmentById,
      rowHasSelection,
      getOperatorsForEquipment,
    ],
  );

  useEffect(() => {
    const normalizeOperators = (
      op: WorkOrderLineOperator,
    ): SelectSingleProps => {
      return {
        value: op.id,
        label: `${op.firstName} ${op.middleName ?? ""} ${op.lastName}`,
      } as SelectSingleProps;
    };

    // Can normalize both category and equipment
    const normalizeWorkCenter = (op: TeamWorkCenter): SelectSingleProps => {
      return {
        value: op.workCenterId,
        label: `${op.name}`,
      } as SelectSingleProps;
    };

    if (defaultOptions && useDefaultOptions) {
      if (defaultOptions.workCenterCategory) {
        const defaultCategory = normalizeWorkCenter(
          defaultOptions.workCenterCategory,
        );
        setCategory(defaultCategory);
        setEnableDropdowns({ category: true, equipment: true, operator: true });
        updateDropdownOptionsForRow(Dropdown.CATEGORY, defaultCategory);
      }

      if (defaultOptions.workCenterMachine) {
        const defaultEquipment = normalizeWorkCenter(
          defaultOptions.workCenterMachine,
        );
        setEquipment(defaultEquipment);
        updateDropdownOptionsForRow(Dropdown.EQUIPMENT, defaultEquipment);
      }

      if (defaultOptions.operator) {
        const defaultOperator = normalizeOperators(
          // TODO: Check for possible source of problems. Operator vs WorkOrderLineOperator
          defaultOptions.operator as WorkOrderLineOperator,
        );
        setOperator(defaultOperator);
        updateDropdownOptionsForRow(Dropdown.OPERATOR, defaultOperator);
      }
    }
  }, [defaultOptions, useDefaultOptions, updateDropdownOptionsForRow]);

  useEffect(() => {
    setUseDefaultOptions(false);
  }, [setUseDefaultOptions]);

  const filteredEquipmentForCategory =
    equipmentForCategory?.data?.workCentersMachinesByType
      .filter((equip: TeamWorkCenter) => equip?.facilityId === selectedFacility)
      .filter((item: any) =>
        selectedPAsForEquip?.map((i) => i.value).includes(item.planningArea),
      );

  useEffect(() => {
    // console.log(
    //   { allEquipment },
    //   { equipmentForCat: equipmentForCategory?.data },
    //   { equipmentForOperator: equipmentForOperator?.data }
    // );
    if (filteredEquipmentForCategory === undefined) {
      setEquipmentList(allEquipment);
      return;
    }
    if (filteredEquipmentForCategory?.length > 0) {
      const equipmentOptions = filteredEquipmentForCategory.map(
        (ctr: { workCenterId: string; name: string }) => {
          return {
            value: ctr.workCenterId,
            label: ctr.name,
          } as SelectSingleProps;
        },
      );
      setEquipmentList(equipmentOptions);
    } else {
      setEquipmentList([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(filteredEquipmentForCategory),
    allEquipment,
  ]);

  const filteredOperatorsForEquipment =
    operatorsForEquipment?.data?.certifiedOperators
      // .filter((cat: Operator) => cat?.facilityId === selectedFacility)
      .filter((item: any) =>
        selectedPAsForOperators
          ?.map((i) => i.value)
          .includes(item.planningAreaId),
      );

  const filteredOperatorsForCategory =
    operatorsForCategory?.data?.certifiedOperators
      // .filter((cat: Operator) => cat?.facilityId === selectedFacility)
      .filter((item: any) =>
        selectedPAsForOperators
          ?.map((i) => i.value)
          .includes(item.planningAreaId),
      );

  useEffect(
    () => {
      if (
        filteredOperatorsForEquipment === undefined &&
        filteredOperatorsForCategory === undefined
      ) {
        const filteredOperators = allOperators.filter((operator: any) =>
          selectedPAsForOperators
            ?.map((i) => i.value)
            .includes(operator.planningAreaId),
        );
        setOperatorsList(filteredOperators);
        return;
      }
      if (filteredOperatorsForEquipment?.length > 0) {
        const operatorsOptions2 = filteredOperatorsForEquipment.map(
          (ctr: { id: string; firstName: string; lastName: string }) => {
            return {
              value: ctr.id,
              label: `${ctr.firstName} ${ctr.lastName}`,
            } as SelectSingleProps;
          },
        );
        setOperatorsList(operatorsOptions2);
      } else if (filteredOperatorsForCategory?.length > 0) {
        const operatorsOptions1 = filteredOperatorsForCategory.map(
          (ctr: { id: string; firstName: string; lastName: string }) => {
            return {
              value: ctr.id,
              label: `${ctr.firstName} ${ctr.lastName}`,
            } as SelectSingleProps;
          },
        );
        setOperatorsList(operatorsOptions1);
        // } else if (allOperators && allOperators?.length > 0) {
        //   setOperatorsList(allOperators);
      } else {
        setOperatorsList([]);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(filteredOperatorsForEquipment),
      allOperators,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(filteredOperatorsForCategory),
    ],
  );

  useEffect(() => {
    setCategoryList(allCategories);
  }, [allCategories]);

  const handleOperationItemChange = async (
    origin: Dropdown,
    selection: SelectSingleProps,
  ) => {
    if (origin === Dropdown.CATEGORY) {
      setCategory(selection);
      setEnableDropdowns({
        ...enabledDropdowns,
        equipment: true,
        operator: true,
      });
      updateDropdownOptionsForRow(Dropdown.CATEGORY, selection);
    }

    if (origin === Dropdown.EQUIPMENT) {
      setEquipment(selection);
      updateDropdownOptionsForRow(Dropdown.EQUIPMENT, selection);
    }

    if (origin === Dropdown.OPERATOR) {
      setOperator(selection);
      updateDropdownOptionsForRow(Dropdown.OPERATOR, selection);
    }
    onSelectItem(selection, index, origin);
  };

  const disableForNonMachineOperator = (
    selection: SelectSingleProps | null | undefined,
  ) => {
    if (!selection || selection === undefined || selection.value === undefined)
      return false;

    return !isOperatorDEPRECATED(selection.value);
  };

  const handleDeleteItem = () => {
    onDeleteItem(id, index);
  };

  return (
    <div className={`${styles.operations} ${isLastRow ? " last-row" : ""}`}>
      <section key={id}>
        <CustomSelect
          value={selectedCategory}
          onChange={(e) => {
            return handleOperationItemChange(
              Dropdown.CATEGORY,
              e as SelectSingleProps,
            );
          }}
          placeholder="Work Center Type"
          options={categoryList}
          disabled={isEmpty(categoryList) && !enabledDropdowns.category}
        />
        <CustomSelect
          value={selectedEquipment}
          onChange={(e) =>
            handleOperationItemChange(
              Dropdown.EQUIPMENT,
              e as SelectSingleProps,
            )
          }
          placeholder="Equipment"
          options={equipmentList}
          disabled={
            isEmpty(equipmentList) ||
            disableForNonMachineOperator(selectedCategory) ||
            !enabledDropdowns.equipment
          }
        />
        <CustomSelect
          value={selectedOperator}
          onChange={(e) =>
            handleOperationItemChange(Dropdown.OPERATOR, e as SelectSingleProps)
          }
          placeholder={`Employee${
            allOperators.length !== operatorsList.length
              ? ` (${operatorsList.length})`
              : ""
          }`}
          options={operatorsList}
          disabled={isEmpty(operatorsList) || !enabledDropdowns.operator}
        />
        <button
          className={st.actionButton}
          type="button"
          onClick={() => handleDeleteItem()}
        >
          <img src={trash} alt="trash" />
        </button>
      </section>
    </div>
  );
};
export default OperationLineRow;
