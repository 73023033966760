import { gql, useMutation } from "@apollo/client";

export const DELETE_TEAM_FROM_ORDER_MUTATION = gql`
  mutation deleteTeam(
    $teamId: UUID!
    $issuedBy: String!
    $workOrderId: String!
  ) {
    deleteTeamFromWorkOrder(
      teamId: $teamId
      issuedBy: $issuedBy
      workOrderId: $workOrderId
    )
  }
`;

const useDeleteTeamFromWorkOrder = (): typeof setUpdate => {
  const [deleteTeam, { error }] = useMutation(DELETE_TEAM_FROM_ORDER_MUTATION);

  if (error) {
    console.error(error);
  }
  const setUpdate = (teamId: string, issuedBy: string, workOrderId: string) => {
    deleteTeam({
      variables: {
        teamId,
        issuedBy,
        workOrderId,
      },
    });
  };

  return setUpdate;
};

export default useDeleteTeamFromWorkOrder;
