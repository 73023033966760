import React, { FC, useEffect, useRef } from "react";
import { useDragLayer } from "react-dnd";

import {
  DraggableItemType,
  DraggableSideDnDItem,
  DroppableItemType,
  Nullable,
} from "@/types";
import { getPlainObjectHash } from "@/utils/common";

import styles from "./index.module.scss";

export type OnDraggableIntersectsBordersFn = (value: {
  horizontally: number;
  vertically: number;
}) => void;

export type DragLayerState = {
  item: Nullable<DraggableSideDnDItem>;
  itemType: Nullable<DraggableItemType>;
  parentItemType: Nullable<DroppableItemType>;
  differenceByXFromInitialOffset: Nullable<number>;
  clientOffset: Nullable<{ x: number; y: number }>;
};

export type OnDragLayerStateChangedFn = (
  dragLayerState: DragLayerState,
) => void;

type Props = {
  children: React.ReactNode;
  leftBorder: number;
  rightBorder: number;
  topBorder: number;
  bottomBorder: number;
  onDraggableIntersectsBorders: OnDraggableIntersectsBordersFn;
  onDragLayerStateChanged: OnDragLayerStateChangedFn;
};

const DragLayer = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      leftBorder,
      rightBorder,
      topBorder,
      bottomBorder,
      onDraggableIntersectsBorders,
      onDragLayerStateChanged,
    },
    ref,
  ) => {
    const dragLayerProps = useDragLayer((monitor) => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      clientOffset: monitor.getClientOffset(),
      differenceByXFromInitialOffset:
        monitor.getDifferenceFromInitialOffset()?.x || 0,
      isDragging: monitor.isDragging(),
    }));
    const dragLayerPrevState = useRef<{ value: Nullable<string> }>({
      value: null,
    });

    useEffect(() => {
      const clientOffsetX = dragLayerProps.clientOffset?.x || 0;
      const clientOffsetY = dragLayerProps.clientOffset?.y || 0;

      if (!dragLayerProps.isDragging || !clientOffsetX || !clientOffsetY) {
        return;
      }

      const dragLayerState: Omit<DragLayerState, "parentItemType"> = {
        clientOffset: dragLayerProps.clientOffset,
        differenceByXFromInitialOffset:
          dragLayerProps.differenceByXFromInitialOffset,
        item: dragLayerProps.item,
        itemType: dragLayerProps.itemType as Nullable<DraggableItemType>,
      };

      const dragLayerStateHash = getPlainObjectHash(dragLayerState, {
        maxLevel: 2,
      });

      if (dragLayerStateHash !== dragLayerPrevState.current.value) {
        dragLayerPrevState.current.value = dragLayerStateHash;

        onDragLayerStateChanged({
          ...dragLayerState,
          parentItemType: dragLayerState?.item?.parentItemType,
        });
      }

      const leftDiff = clientOffsetX - leftBorder;
      const rightDiff = Math.abs(rightBorder - clientOffsetX);
      const topDiff = clientOffsetY - topBorder;
      const bottomDiff = bottomBorder - clientOffsetY;
      const minimalDiff = 50;
      const scrollParams = {
        horizontally: 0,
        vertically: 0,
      };

      if (leftDiff < minimalDiff) {
        scrollParams.horizontally = leftDiff - minimalDiff;
      }

      if (rightDiff < minimalDiff) {
        scrollParams.horizontally = minimalDiff - rightDiff;
      }

      if (topDiff < minimalDiff) {
        scrollParams.vertically = topDiff - minimalDiff;
      }

      if (bottomDiff < minimalDiff) {
        scrollParams.vertically = minimalDiff - bottomDiff;
      }

      onDraggableIntersectsBorders(scrollParams);
    }, [
      bottomBorder,
      dragLayerProps.item,
      dragLayerProps.itemType,
      dragLayerProps.clientOffset,
      dragLayerProps.differenceByXFromInitialOffset,
      dragLayerProps.isDragging,
      leftBorder,
      rightBorder,
      onDraggableIntersectsBorders,
      topBorder,
      onDragLayerStateChanged,
    ]);

    return (
      <div id="timelineTable" ref={ref} className={styles.timelineItemsList}>
        {children}
      </div>
    );
  },
);

export default DragLayer;
