import Dialog from "@mui/material/Dialog";
import React, { FC, PropsWithChildren } from "react";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const DialogModal: FC<PropsWithChildren<Props>> = ({
  isOpen,
  onClose,
  children = null,
}) => {
  return (
    <div>
      <Dialog open={isOpen} onClose={onClose}>
        {children}
      </Dialog>
    </div>
  );
};

export default DialogModal;
