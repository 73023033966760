import { captureMessage } from "@sentry/react";

import { removeIdFromWoLineInPogressList } from "@/zustand/useInProgressStore";
import usePubSubStore from "@/zustand/usePubSubStore";

type NotificationFromBackend = {
  message: string;
  messageType: string;
  recipient: string;
  payload: string;
  payloadType: string;
  scope: string;
};

export default class NotificationResolver {
  static resolve(event: NotificationFromBackend): void {
    // const eventType = event?.messageType?.trim();

    // if (eventType === "RentalAgreementToQuayConnectedToShorePower") {
    //   console.log("shorepower event: ", { event });
    // }
    // if (eventType === "Konciv") {
    //   console.log("Konciv event: ", { event });
    // }
    if (event?.message?.trim() === "WorkOrderLineCreated") {
      const payload = JSON.parse(event.payload);
      // TODO: Needed to also reflect changes in useOrderStore -> work lines in progress?
      // example 1006909140|230-BU013001
      const uniqueId = `${payload?.WorkOrderId}|${event?.recipient}-${payload?.Capability}`;
      removeIdFromWoLineInPogressList(
        `${payload?.WorkOrderId}|${payload?.Capability}`,
      );
      usePubSubStore.getState().publish("WorkOrderLineCreated", {
        id: uniqueId,
        newWorkOrderLineId: payload.Id,
      });
    }
    if (event?.message?.includes("exceeded while awaiting headers")) {
      captureMessage("M3 timeout");
    }
  }
}
