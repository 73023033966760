import { gql } from "@apollo/client";

// This mutation will produced these websocket notifications:
// RentalAgreementToQuayDeletedFromM3;
// RentalAgreementToQuayAddedFromM3;

export const ATTACH_WORK_ORDER_TO_VESSEL = gql`
  mutation attachWorkOrderToVessel(
    $facilityId: String!
    $issuedBy: String!
    $key: String! # id of the vessel that we want to attach workorder to
    $orderLineNumber: String! # OrderNumber and OrderLineNumber of the MCO (workorder), which we want to move
    $orderNumber: String!
  ) {
    attachWorkOrderToVessel(
      facilityId: $facilityId
      issuedBy: $issuedBy
      key: $key
      orderLineNumber: $orderLineNumber
      orderNumber: $orderNumber
    )
  }
`;
