/* eslint-disable @typescript-eslint/no-explicit-any */
import { gql, useMutation } from "@apollo/client";
import { useCallback } from "react";

import { GhostActivityNote } from "@/components/timeline/sidePanel/notes/ghostNotes";
import { GhostWorkOrderData } from "@/types";
import { useSettingsStore, useUserData } from "@/zustand/useSettingsStore";

type GhostWorkOrderWithNotes = GhostWorkOrderData & {
  ghostActivityNotes: GhostActivityNote[];
};

const UPSERT_GHOST_ACTIVITY = gql`
  mutation upsertGhostActivityWithNotes(
    $model: CreateGhostActivityModelInput!
  ) {
    upsertGhostActivityWithNotes(model: $model)
  }
`;

const useUpsertGhostActivityWithNotes = (): {
  setUpdate: typeof setUpdate;
} => {
  const selectedFacility = useSettingsStore((state) => state.selectedFacility);
  const userData = useUserData();

  const [upsertGhostActivity] = useMutation(UPSERT_GHOST_ACTIVITY);

  const setUpdate = useCallback(
    async (ghostOrder: GhostWorkOrderWithNotes) => {
      const response = await upsertGhostActivity({
        variables: {
          model: {
            customerName: ghostOrder.customerName,
            facilityId: selectedFacility,
            ghostActivityId: ghostOrder.ghostWorkOrderId,
            issuedBy: userData.mail,
            name: ghostOrder.name,
            plannedStart: ghostOrder.plannedStart,
            plannedStop: ghostOrder.plannedStop,
            reserve: ghostOrder.reserve,
            ghostActivityNotes: ghostOrder.ghostActivityNotes,
          },
        },
      });
      return response;
    },
    [selectedFacility, upsertGhostActivity, userData.mail],
  );

  return { setUpdate };
};

export default useUpsertGhostActivityWithNotes;
