import uniqBy from "lodash/uniqBy";
import React, { FC, useEffect, useMemo, useState } from "react";

import { TeamWorkOderLineInput } from "@/hooks/mutation/useUpdateTeamWithOperationLines";
import plusIcon from "@/style/img/icons/plus.svg";
import {
  CenterById,
  ItemById,
  SelectSingleProps,
  TeamOperationLine,
} from "@/types";

import CustomMultiSelect from "../../formElements/multiselect/customMultiSelect";
import OperationLineRow, { Dropdown } from "./operationLineRow";

import styles from "./index.module.scss";

type OnOperationItemSelected = (
  newOperationLines: TeamWorkOderLineInput[],
) => void;
type OnOperationItemRemoved = (
  newOperationLines: TeamWorkOderLineInput[],
) => void;
type OperationItem = {
  id: string;
  workCenterCategory: null | SelectSingleProps;
  workCenterMachine: null | SelectSingleProps;
  workCenterCategoryList: SelectSingleProps[];
  workCenterMachineList: SelectSingleProps[];
  operatorList: SelectSingleProps[];
  operator: null | SelectSingleProps;
};

type Props = {
  readonly allCategories: SelectSingleProps[];
  readonly allCategoriesById: ItemById;
  readonly allEquipment: SelectSingleProps[];
  readonly allEquipmentById: CenterById;
  readonly allOperators: SelectSingleProps[];
  readonly allOperatorsById: ItemById;
  readonly allOperatorsPlanningAreas: any[];
  error?: boolean;
  onOperationItemsAddition: OnOperationItemSelected;
  onOperationItemsRemoval: OnOperationItemRemoved;
  existingOperationItems: TeamOperationLine[] | undefined;
  onBlur?: () => void;
};

const DEFAULT_PLANNING_AREA = "BASE";

const OperationLinesList: FC<Props> = ({
  allCategories,
  error = false,
  allEquipment,
  allOperators,
  allCategoriesById,
  allOperatorsById,
  allEquipmentById,
  allOperatorsPlanningAreas,
  onOperationItemsAddition,
  onOperationItemsRemoval,
  existingOperationItems,
  onBlur = () => null,
}) => {
  const [dataLoaded, setDataLoaded] = useState(false);

  const possiblePlanningAreas = uniqBy(
    allOperatorsPlanningAreas,
    "planningAreaId",
  ).map((operator) => ({
    label: operator.planningAreaId,
    value: operator.planningAreaId,
  }));

  const [selectedPAsForEquip, setselectedPAsForEquip] = useState<
    SelectSingleProps[]
  >([
    {
      value: DEFAULT_PLANNING_AREA,
      label: DEFAULT_PLANNING_AREA,
    },
  ]);

  const [selectedPAsForOperators, setselectedPAsForOperators] = useState<
    SelectSingleProps[]
  >([
    {
      value: DEFAULT_PLANNING_AREA,
      label: DEFAULT_PLANNING_AREA,
    },
  ]);
  const mapExistingOperationItemsToOperationItemRow = useMemo(() => {
    if (!existingOperationItems) {
      return [];
    }

    if (
      allCategories.length === 0 ||
      allEquipment.length === 0 ||
      allOperators.length === 0
    ) {
      return [];
    }

    return existingOperationItems.map((operationLine) => {
      const parentWc = operationLine?.workCenterCategory?.workCenterId
        ? allCategoriesById[operationLine.workCenterCategory.workCenterId]
        : null;
      const childWc = operationLine?.workCenterMachine?.workCenterId
        ? allEquipmentById[operationLine.workCenterMachine.workCenterId]
        : null;
      const operator = operationLine?.operatorId
        ? allOperatorsById[operationLine.operatorId]
        : null;

      return {
        id: operationLine.id,
        workCenterCategory: parentWc,
        workCenterMachine: childWc,
        operator,
        workCenterCategoryList: allCategories,
        workCenterMachineList: allEquipment,
        operatorList: [],
      } as OperationItem;
    });
  }, [
    allEquipment,
    allEquipmentById,
    allOperators,
    allOperatorsById,
    allCategories,
    allCategoriesById,
    existingOperationItems,
  ]);

  const defaultValue = (
    el: TeamOperationLine,
    idx: number,
  ): TeamOperationLine | null => {
    if (!existingOperationItems?.[idx]) return null;

    // newly created rows don't have a parent work center assigned
    if (!el.workCenterCategory) {
      return null;
    }

    // adding a new row with existing operation items should not have default options
    if (idx > existingOperationItems.length - 1) {
      return null;
    }

    return existingOperationItems[idx];
  };

  /**
   * Reducer was used to handle re-serialization
   * The reducer's goal is to keep upsert params in sync with the dropdowns and ui changes based on user interactions
   */
  type ReducerState = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    list: any[];
  };

  enum ReducerActionType {
    REMOVE_ITEM,
    ADD_ITEM,
    SELECT_DROPDOWN,
    POPULATE_EXISTING,
  }
  type ReducerAction = {
    type: ReducerActionType;
    id: string;
    index: number;
    changeOrigin?: Dropdown;
    changeSelection?: SelectSingleProps;
    operationItems?: OperationItem[];
  };

  type SelectionsByIndex = {
    [key: string]: unknown; // Todo: Fix "unknown". The selections argument can have multiple types.
  };

  const updateSelections = (
    action: ReducerAction,
    selections: SelectionsByIndex[],
  ) => {
    const updatedItems = selections;

    switch (action.changeOrigin) {
      case Dropdown.EQUIPMENT:
        updatedItems[action.index].workCenterMachine = action.changeSelection;
        // Don't do this (Aug 2024 decision). Maybe later is needed if we allow right-to-left selection in the dropdowns.
        // const selection = action.changeSelection as SelectSingleProps;
        // const id = selection?.value;
        // // populate category (parent work center) based on equiment (child)
        // if (id) {
        //   const { parentId } = allEquipmentById[id];
        //   updatedItems[action.index].workCenterCategory =
        //     allCategoriesById[parentId];
        // }

        break;
      case Dropdown.OPERATOR:
        updatedItems[action.index].operator = action.changeSelection;
        break;
      case Dropdown.CATEGORY:
        updatedItems[action.index].workCenterCategory = action.changeSelection;
        break;
      default:
        break;
    }

    return updatedItems;
  };

  const listReducer = (
    state: ReducerState,
    action: ReducerAction,
  ): ReducerState => {
    switch (action.type) {
      case ReducerActionType.REMOVE_ITEM:
        // eslint-disable-next-line no-case-declarations
        const newState = state.list.filter((el) => el.id !== action.id);
        // eslint-disable-next-line no-case-declarations
        const newListAfterDeletion = newState.map((item) => {
          return {
            workCenterCategoryId: item.workCenterCategory?.value ?? "",
            workCenterMachineId: item.workCenterMachine?.value ?? "",
            operatorId: item.operator?.value || "",
          };
        });
        onOperationItemsRemoval(newListAfterDeletion);
        return {
          ...state,
          list: newState,
        };
      case ReducerActionType.ADD_ITEM: {
        const newItem = {
          id: state.list.length,
          workCenterCategory: null,
          workCenterMachine: null,
          operator: null,
          workCenterCategoryList: allCategories,
          workCenterMachineList: allEquipment ?? [],
          operatorList: [],
        };

        return {
          ...state,
          list: [...state.list, newItem],
        };
      }
      case ReducerActionType.SELECT_DROPDOWN: {
        const updatedItems = updateSelections(action, state.list);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const newListAfterAddition = updatedItems.map((item: any) => {
          return {
            workCenterCategoryId: item.workCenterCategory?.value ?? "",
            workCenterMachineId: item.workCenterMachine?.value ?? "",
            operatorId: item.operator?.value || "",
          };
        });
        onOperationItemsAddition(newListAfterAddition);

        return {
          ...state,
          list: updatedItems,
        };
      }
      case ReducerActionType.POPULATE_EXISTING: {
        const newItems = action.operationItems;

        if (!newItems) return state;

        return {
          ...state,
          list: newItems,
        };
      }

      default:
        throw new Error();
    }
  };

  const [state, dispatchList] = React.useReducer(listReducer, {
    list: mapExistingOperationItemsToOperationItemRow,
  });

  const removeOperationItem = (id: string, index: number) => {
    dispatchList({ type: ReducerActionType.REMOVE_ITEM, id, index });
  };

  const addOperationItem = () => {
    dispatchList({ type: ReducerActionType.ADD_ITEM, id: "", index: -1 });
    setTimeout(() => {
      const lastInput = document.querySelector(
        ".last-row input",
      ) as HTMLElement;
      if (lastInput) {
        lastInput.focus();
      }
    }, 50);
  };

  const selectOperationItem = (
    changeSelection: SelectSingleProps,
    index: number,
    changeOrigin: Dropdown,
  ) => {
    dispatchList({
      type: ReducerActionType.SELECT_DROPDOWN,
      id: "",
      index,
      changeSelection,
      changeOrigin,
    });
  };

  // When user opens the CREATE NEW TEAM window, one row in the OPERATION LINE list should already exist
  useEffect(() => {
    dispatchList({ type: ReducerActionType.ADD_ITEM, id: "", index: -1 });
  }, [ReducerActionType.ADD_ITEM]);

  useEffect(() => {
    if (
      mapExistingOperationItemsToOperationItemRow.length !== 0 &&
      !dataLoaded
    ) {
      dispatchList({
        type: ReducerActionType.POPULATE_EXISTING,
        id: "",
        index: -1,
        operationItems: mapExistingOperationItemsToOperationItemRow,
      });
      setDataLoaded(true);
    }
    // No dataLoaded in dependency array because we only want to run this when mapExistingOperationItemsToOperationItemRow changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    ReducerActionType.POPULATE_EXISTING,
    mapExistingOperationItemsToOperationItemRow,
  ]);

  return (
    <>
      <div className={styles.dropdownFilters}>
        <div style={{ margin: "auto", width: "196px" }} />
        <CustomMultiSelect
          onChange={(values: SelectSingleProps[]) => {
            setselectedPAsForEquip(values);
          }}
          placeholder="PLANNING AREA"
          options={possiblePlanningAreas}
          allButtonName="Select all"
          noneButtonName="Deselect all"
          preselected={[]}
          activeOptions={selectedPAsForEquip}
          storageKey="teamBuilder-machines-planningAreas-list-dropdown"
        />
        <CustomMultiSelect
          onChange={(values: SelectSingleProps[]) => {
            setselectedPAsForOperators(values);
          }}
          placeholder="PLANNING AREA"
          options={possiblePlanningAreas}
          allButtonName="Select all"
          noneButtonName="Deselect all"
          preselected={[]}
          activeOptions={selectedPAsForOperators}
          storageKey="teamBuilder-employees-planningAreas-list-dropdown"
        />
        <div style={{ width: "25px" }} />
      </div>
      <div className={styles.operations} onBlur={onBlur}>
        {state.list.map((el: TeamOperationLine, idx: number) => (
          <OperationLineRow
            key={el.id}
            index={idx}
            isLastRow={state.list.length - 1 === idx}
            id={el.id}
            defaultOptions={defaultValue(el, idx)}
            allEquipment={allEquipment}
            allEquipmentById={allEquipmentById}
            allCategories={allCategories}
            allCategoriesById={allCategoriesById}
            allOperators={allOperators}
            selectedPAsForEquip={selectedPAsForEquip}
            selectedPAsForOperators={selectedPAsForOperators}
            onDeleteItem={(id, index) => removeOperationItem(id, index)}
            onSelectItem={selectOperationItem}
          />
        ))}
      </div>
      {error && (
        <p className="error-message">
          Heads up! You hopped over a selection on the operation line above.
        </p>
      )}
      <button
        className={`${styles.addNewOperation}`}
        onClick={addOperationItem}
        tabIndex={0}
        type="button"
        onKeyDown={() => null}
      >
        <img src={plusIcon} alt="Add input" />
        <span>Add operation line</span>
      </button>
    </>
  );
};
export default OperationLinesList;
