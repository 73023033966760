import isEmpty from "lodash/isEmpty";
import moment from "moment-timezone";
import React, { FC, memo, useState } from "react";

import useDeleteTeam from "@/hooks/mutation/useDeleteTeam";
import duplicate from "@/style/img/icons/add_people.svg";
import assign from "@/style/img/icons/assign_to.svg";
import edit from "@/style/img/icons/edit.svg";
import trash from "@/style/img/icons/trash.svg";
import { Team, TeamOperationLine } from "@/types";
import { useSettingsStore } from "@/zustand/useSettingsStore";

import styles from "./index.module.scss";
import textStyles from "@/style/textStyles.module.scss";

type Props = {
  duplicateTeam: (team: Team) => void;
  setEditData: (value: Team) => void;
  deleteTeam: (id: string) => void;
  toggleComponent: () => void;
  data: Team;
};

const TeamListItem: FC<Props> = ({
  data,
  duplicateTeam,
  setEditData,
  toggleComponent,
  deleteTeam,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const setSuperLinking = useSettingsStore((state) => state.setSuperLinking);
  const closeSidebarTab = useSettingsStore((state) => state.closeSidebarTab);
  const setUpdate = useDeleteTeam();
  return (
    <div
      style={isExpanded ? { background: "#F7F7F7" } : { background: "#FFF" }}
      className={styles.teamListItem}
      onClick={() =>
        !isEmpty(data.operationLines) && setIsExpanded(!isExpanded)
      }
      tabIndex={0}
      role="button"
      onKeyDown={() => null}
    >
      <div>
        <div title={data.name}>
          <span>
            {`(${data.operationLines.length}) `}
            {data.name}
          </span>
        </div>
        <div style={isExpanded ? { flexWrap: "wrap" } : { flexWrap: "nowrap" }}>
          {!isEmpty(data.tags) &&
            data.tags.map(
              (el: string) =>
                el !== "  " && (
                  <div
                    style={isExpanded ? { margin: "4px" } : {}}
                    className={styles.tag}
                    title={el}
                    key={el}
                  >
                    <span>{el}</span>
                  </div>
                ),
            )}
        </div>
        <div>
          <span title="The date team is planned for">
            {moment(data.startDate).format("DD.MM.YYYY")}
          </span>
          <span title="The time team is planned for">
            {moment(data.startDate).format("HH:mm")}
          </span>
        </div>
        <div>
          <button
            className={styles.actionButton}
            type="button"
            title="Edit Team"
            onClick={(e) => {
              e.stopPropagation();
              setEditData(data);
              toggleComponent();
            }}
          >
            <img src={edit} alt="edit" />
          </button>
          {isExpanded && (
            <button
              className={styles.actionButton}
              type="button"
              title="Duplicate Team"
              onClick={(e) => {
                e.stopPropagation();
                duplicateTeam(data);
                toggleComponent();
              }}
            >
              <img src={duplicate} alt="duplicate" />
            </button>
          )}
          {isExpanded && (
            <button
              className={styles.actionButton}
              type="button"
              title="Delete team"
              onClick={(e) => {
                e.stopPropagation();
                setUpdate(data.id);
                deleteTeam(data.id);
              }}
            >
              <img src={trash} alt="delete" />
            </button>
          )}
          <button
            className={styles.actionButton}
            type="button"
            title="Assign to..."
            disabled={!data.operationLines.length}
            onClick={(e) => {
              e.stopPropagation();
              if (data.operationLines.length) {
                setSuperLinking({
                  tooltipText: "Click on the work order to attach the team.",
                  team: data,
                });
                closeSidebarTab();
              }
            }}
          >
            <img src={assign} alt="assign" />
          </button>
        </div>
      </div>
      {isExpanded && (
        <div className={styles.expandedArea}>
          <span>OPERATION LINES:</span>
          <section className={styles.operationLines}>
            <div>
              {data.operationLines.map((el: TeamOperationLine) => (
                <div className={textStyles.primaryText} key={el.id}>
                  {el.workCenterCategory?.name}
                </div>
              ))}
            </div>
            <div>
              {data.operationLines.map((el: TeamOperationLine) => (
                <div key={el.id} className={textStyles.secondaryText}>
                  {el?.workCenterMachine?.name
                    ? `${el.workCenterMachine.name}`
                    : "-"}
                </div>
              ))}
            </div>
            <div>
              {data.operationLines.map((el: TeamOperationLine) => (
                <div key={el.id} className={textStyles.secondaryText}>
                  {el?.operator
                    ? `${el.operator.firstName} ${el.operator.lastName}`
                    : "Unassigned"}
                </div>
              ))}
            </div>
          </section>
        </div>
      )}
    </div>
  );
};
export default memo(TeamListItem);
