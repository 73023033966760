import moment, { Moment } from "moment-timezone";
import { useEffect } from "react";

import { UpsertTeamWithOperationLinesModelInput } from "@/hooks/mutation/useUpdateTeamWithOperationLines";
import { Team, TeamOperationLine } from "@/types";

export const useExistingData = (
  editTeamData: Team | undefined,
  duplicateTeamData: Team | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  upsertParams: UpsertTeamWithOperationLinesModelInput,
  setTeamName: (value: React.SetStateAction<string>) => void,
  setTags: (value: React.SetStateAction<string[]>) => void,
  setStartDate: (value: React.SetStateAction<Moment | null>) => void,
  setTeamUpsertParams: (
    value: React.SetStateAction<UpsertTeamWithOperationLinesModelInput>,
  ) => void,
): void => {
  useEffect(
    () => {
      if (editTeamData) {
        setTeamName(editTeamData.name);
        setTags(editTeamData.tags);
        if (editTeamData.startDate) {
          setStartDate(moment(editTeamData.startDate));
        }
        if (editTeamData.operationLines) {
          const upsertOperationLines = editTeamData.operationLines.map(
            (ol: TeamOperationLine) => {
              return {
                workCenterCategoryId: ol.workCenterCategory?.workCenterId,
                workCenterMachineId: ol.workCenterMachine?.workCenterId,
                operatorId: ol.operatorId,
              };
            },
          );

          const upsert = upsertParams;
          upsert.model.teamWorkOrderLines = upsertOperationLines;
          setTeamUpsertParams(upsert);
        }
      }
      if (duplicateTeamData) {
        setTeamName("");
        setTags(duplicateTeamData.tags);
        if (duplicateTeamData.startDate) {
          setStartDate(moment());
        }
        if (duplicateTeamData.operationLines) {
          const upsertOperationLines = duplicateTeamData.operationLines.map(
            (operationLine: TeamOperationLine) => {
              return {
                workCenterCategoryId:
                  operationLine.workCenterCategory?.workCenterId,
                workCenterMachineId:
                  operationLine.workCenterMachine?.workCenterId || "",
                operatorId: operationLine.operatorId,
              };
            },
          );

          const upsert = upsertParams;
          upsert.model.teamWorkOrderLines = upsertOperationLines;
          setTeamUpsertParams(upsert);
        }
      }
    },
    // Only re-run the effect if editTeamData or duplicateTeamData changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editTeamData, duplicateTeamData],
  );
};
