import { DatePicker } from "@mui/x-date-pickers";
import moment, { Moment } from "moment-timezone";
import React, { FC, useState } from "react";

import { captureError } from "@/helpers/captureError";

import buttons from "@/style/buttons.module.scss";

type Props = {
  handleChange: (d: Moment | null) => void;
  dateValue: Moment | null;
  disabled?: boolean;
};

const style = {
  width: "155px",
  "& .Mui-selected": {
    backgroundColor: "red !important",
  },
  "& .MuiOutlinedInput-root": {
    cursor: "pointer",
    position: "relative",
    "& .MuiIconButton-root": {
      marginRight: "-10px",
      marginTop: "3px",
    },
    ":after": {
      content: "'SELECT DATE'",
      display: "block",
      fontFamily: "Open Sans",
      fontSize: "14px",
      letterSpacing: ".04em",
      color: "#2f2f2f",
      position: "absolute",
      left: "16px",
      top: "4px",
      opacity: 1,
    },
    input: {
      cursor: "pointer",
      opacity: 0,
      background: "#F7F7F7",
    },
    fieldset: {
      border: "none",
      background: "#F7F7F7",
      zIndex: -1,
    },
    "&.Mui-focused fieldset, &:hover fieldset": {
      borderColor: "#00adbb",
      borderWidth: "1px",
    },
    "&:hover fieldset": {
      borderColor: "#00adbb",
      borderWidth: "1px",
    },
  },
};

const SingleDatePicker: FC<Props> = ({
  dateValue,
  handleChange,
  disabled = false,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <DatePicker
      open={isOpen}
      value={dateValue}
      className="nsg-datetime-picker"
      disabled={disabled}
      disableHighlightToday
      sx={style}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      onChange={(newValue) => {
        handleChange(
          moment(newValue).set({
            second: 0,
            millisecond: 0,
          }),
        );
      }}
      onError={(error) => {
        console.error(error);
        captureError(error);
      }}
      slotProps={{
        field: {},
        textField: {
          className: `${buttons.mainButton}`,
          onClick: () => setIsOpen(true),
          spellCheck: "false",
        },
      }}
    />
  );
};

export default SingleDatePicker;
