import { gql, useMutation, useQuery } from "@apollo/client";
import isEmpty from "lodash/isEmpty";
import React, { FC, memo, useState } from "react";

import PopUp from "@/components/common/formElements/popUp";
import plusIcon from "@/style/img/icons/plus.svg";
import { Category, WorkOrderLineTypes } from "@/types";
import { addWoLineIdToInPogressList } from "@/zustand/useInProgressStore";
import { useSettingsStore, useUserData } from "@/zustand/useSettingsStore";

import { CREATE_WORK_ORDERLINE_MUTATION } from "../assignResources/queries";

import styles from "./index.module.scss";
import textStyles from "@/style/textStyles.module.scss";

const QUERY = gql`
  query getWorkCenters($facilityId: String!) {
    workCenters(facilityId: $facilityId) {
      description
      id
      name
      planningArea
    }
  }
`;

const NewOrderLineForm: FC<{ workOrderId: string }> = ({ workOrderId }) => {
  const selectedFacility = useSettingsStore((state) => state.selectedFacility);

  const userData = useUserData();

  const [createWorkOrderLine, { loading }] = useMutation(
    CREATE_WORK_ORDERLINE_MUTATION,
  );
  const { data } = useQuery(QUERY, {
    variables: { facilityId: selectedFacility },
  });
  const [description, setDescription] = useState("");
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);

  const createNewOrderLine = async (capabilityId: string) => {
    addWoLineIdToInPogressList({
      id: `${workOrderId}|${capabilityId}`,
      created: Date.now(),
    });
    await createWorkOrderLine({
      variables: {
        facilityId: selectedFacility,
        issuedBy: userData.mail,
        capabilityId,
        description,
        parentWorkOrderId: workOrderId,
      },
    });
    setDescription("");
    setIsPopUpOpen(false);
  };

  const allBaseWorkCenters = data?.workCenters?.filter(
    (cat: Category) => cat?.planningArea === "GEN WCS",
  );

  const renderWorkCenters = () => {
    return (
      <div className={styles.workCenterList}>
        {!isEmpty(allBaseWorkCenters) &&
          allBaseWorkCenters.map(
            (wc: WorkOrderLineTypes) =>
              wc.description !== "." && (
                <div
                  tabIndex={0}
                  role="button"
                  onKeyDown={() => null}
                  onClick={() => createNewOrderLine(wc.id)}
                  key={wc.id}
                >
                  <span
                    title={wc.description}
                    className={`${textStyles.primaryText} workCenter`}
                  >
                    {wc.description}
                  </span>
                </div>
              ),
          )}
      </div>
    );
  };
  return (
    <div className={styles.newOrderLineBlock}>
      <div className={styles.addNewItem}>
        <input
          type="text"
          value={loading ? "Loading" : description}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setDescription(e.target.value)
          }
          placeholder="Add operation line..."
        />
        <PopUp
          position="left top"
          isOpen={isPopUpOpen}
          onOpen={() => setIsPopUpOpen(true)}
          onClose={() => setIsPopUpOpen(false)}
          trigger={
            <button className={styles.deleteButton} type="button">
              <img
                src={plusIcon}
                title="Add operation line"
                alt="Add operation line"
              />
            </button>
          }
          content={renderWorkCenters()}
        />
      </div>
    </div>
  );
};
export default memo(NewOrderLineForm);
