import moment, { Moment } from "moment-timezone";
import React, { useState } from "react";

import DatePicker from "@/components/common/formElements/datePicker";
import { useSettingsStore } from "@/zustand/useSettingsStore";

import styles from "./index.module.scss";
import buttons from "@/style/buttons.module.scss";

const DateFilters: React.FC = () => {
  const selectedDate = useSettingsStore((state) => state.selectedDate);
  const setSelectedDate = useSettingsStore((state) => state.setSelectedDate);

  const [activeDate, setActiveDate] = useState<Moment | null>(
    moment(selectedDate),
  );

  if (!selectedDate) {
    setSelectedDate(moment().toDate());
  }

  const onDatePick = (date: Moment | null) => {
    setSelectedDate(date.toDate());
    setActiveDate(date);
  };

  return (
    <div className={styles.dateFilters}>
      <div className={`${styles.datePickerWrapper} flex-center`}>
        <DatePicker handleChange={onDatePick} dateValue={activeDate} />
      </div>
      <div className={`flex-center ${styles.dateIndicators}`}>
        <button
          onClick={(e) => {
            setSelectedDate(moment().toDate());
            setActiveDate(moment());
            e.currentTarget.blur();
          }}
          type="button"
          className={buttons.mainButton}
        >
          TODAY
        </button>
      </div>
    </div>
  );
};

export default DateFilters;
