import moment from "moment-timezone";
import React, { FC, memo, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import edit from "@/style/img/icons/edit.svg";
import { useUserData } from "@/zustand/useSettingsStore";

import stylesSidePanel from "../sidePanel.module.scss";
import styles from "./index.module.scss";
import textStyles from "@/style/textStyles.module.scss";

export type GhostActivityNote = { ghostActivityNoteId: string; text: string };

type Props = {
  title: string;
  setNotes: (notes: GhostActivityNote[]) => void;
  notes: GhostActivityNote[];
};

const GhostNotes: FC<Props> = ({ title, notes, setNotes }) => {
  const userData = useUserData();
  const [newNote, setNewNotes] = useState("");
  const [editedNoteValue, setEditedNoteValue] = useState("");
  const [editNoteId, setEditNoteId] = useState<string>();

  const handleChangeNewNote = (value: string) => {
    setNewNotes(value);
  };

  const addNote = async () => {
    if (newNote !== "") {
      setNotes([...notes, { ghostActivityNoteId: uuidv4(), text: newNote }]);
      setNewNotes("");
    }
  };

  const editNote = async (orderNoteId: string) => {
    if (editedNoteValue !== "") {
      const updatedNotes = notes.map((el) => {
        return el.ghostActivityNoteId === orderNoteId
          ? { ...el, text: editedNoteValue }
          : el;
      });
      setNotes(updatedNotes);
      setEditNoteId("");
    }
  };

  const renderNotes = (note: GhostActivityNote) => {
    return (
      <div key={note.ghostActivityNoteId} className={styles.noteMessage}>
        {editNoteId === note.ghostActivityNoteId ? (
          <div className={styles.noteInput}>
            <textarea
              className={styles.noteInput}
              value={editedNoteValue}
              onChange={(e) => setEditedNoteValue(e.target.value)}
              onBlur={() => {
                editNote(note.ghostActivityNoteId);
              }}
            />
          </div>
        ) : (
          <>
            <div>
              <div className={styles.noteMessageDate}>
                {`${userData.mail}: ${moment().format("DD-MM-YYYY HH:mm")}`}
              </div>
              <div className={styles.noteMessageValue}>{note.text}</div>
            </div>
            <button
              onClick={() => {
                setEditNoteId(note.ghostActivityNoteId);
                setEditedNoteValue(note.text);
              }}
              type="button"
              title="Edit note"
            >
              <img src={edit} alt="edit" />
            </button>
          </>
        )}
      </div>
    );
  };

  return (
    <div className={styles.notes}>
      <h3
        className={`${textStyles.secondaryText} ${stylesSidePanel.sectionHeader}`}
      >
        {title}
      </h3>
      {notes && notes.map((note: GhostActivityNote) => renderNotes(note))}
      <div className={styles.noteInput}>
        <textarea
          className={styles.noteInput}
          value={newNote}
          onChange={(e) => handleChangeNewNote(e.target.value)}
          placeholder="Type note..."
          onBlur={() => {
            addNote();
          }}
        />
      </div>
    </div>
  );
};
export default memo(GhostNotes);
