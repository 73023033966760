/* eslint-disable @typescript-eslint/no-explicit-any */
import { gql } from "@apollo/client";

export const UPSERT_GHOST_ORDER = gql`
  mutation upsertGhostWorkOrder(
    $facilityId: String!
    $issuedBy: String!
    $customerName: String!
    $ghostWorkOrderId: UUID
    $name: String!
    $plannedStart: DateTime!
    $plannedStop: DateTime!
    $reserve: String!
  ) {
    upsertGhostWorkOrder(
      customerName: $customerName
      facilityId: $facilityId
      ghostWorkOrderId: $ghostWorkOrderId
      issuedBy: $issuedBy
      name: $name
      plannedStart: $plannedStart
      plannedStop: $plannedStop
      reserve: $reserve
    )
  }
`;
