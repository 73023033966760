import React, { FC, memo } from "react";

const GhostSVG: FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.0112 17.991C25.0112 16.5726 25.0112 15.1542 25.0112 13.7355C25.0112 9.08003 21.7467 5.26403 17.1056 5.20067C17.0677 5.20019 17.0295 5.19995 16.9919 5.19995C12.2912 5.19995 8.87075 9.18707 8.87075 13.9083V20.9672C8.87075 21.0263 8.85803 21.3205 8.84507 21.6865C8.80331 22.8536 8.33291 23.9648 7.52531 24.8082L7.05899 25.2952C6.91547 25.4327 6.91091 25.6626 7.04843 25.8061L7.10843 25.8685C7.70291 26.4884 8.50187 26.7999 9.30203 26.7999C9.99875 26.7999 10.6962 26.5638 11.2611 26.0896C11.823 26.5623 12.5204 26.7999 13.2188 26.7999C13.9153 26.7999 14.6125 26.5638 15.1772 26.0896C15.7388 26.5623 16.4365 26.7999 17.1349 26.7999C17.8312 26.7999 18.5281 26.564 19.0928 26.09C19.1072 26.078 19.1319 26.0725 19.1658 26.0725C19.5085 26.0725 20.7726 26.6401 21.3556 26.6401C21.406 26.6401 21.4513 26.6358 21.4907 26.6267C25.6933 25.635 25.0112 21.19 25.0112 17.991Z"
        fill="#5A5A5A"
      />
      <path
        d="M14.6356 10.3591C13.9545 10.3591 13.3975 10.9164 13.3975 11.5973V13.2483C13.3975 13.9294 13.9547 14.4864 14.6356 14.4864C15.3165 14.4864 15.8738 13.9291 15.8738 13.2483V11.5973C15.8738 10.9162 15.3165 10.3591 14.6356 10.3591Z"
        fill="white"
      />
      <path
        d="M19.3817 10.3591C18.7006 10.3591 18.1436 10.9164 18.1436 11.5973V13.2483C18.1436 13.9294 18.7008 14.4864 19.3817 14.4864C20.0626 14.4864 20.6199 13.9291 20.6199 13.2483V11.5973C20.6199 10.9162 20.0626 10.3591 19.3817 10.3591Z"
        fill="white"
      />
    </svg>
  );
};
export default memo(GhostSVG);
