import React, { FC, memo } from "react";

import removeImg from "@/style/img/icons/remove.svg";

import styles from "./index.module.scss";

type Props = {
  tags: string[];
  handleRemoveTag: (el: string) => void;
};

const TeamTagChips: FC<Props> = memo(({ tags, handleRemoveTag }) => {
  return (
    <div className={styles.chipsBlock}>
      {tags.map((el) => (
        <div key={el}>
          <span>{el}</span>
          <button
            onClick={() => handleRemoveTag(el)}
            className={styles.deleteButton}
            type="button"
          >
            <img src={removeImg} alt="delete" />
          </button>
        </div>
      ))}
    </div>
  );
});

export default TeamTagChips;
