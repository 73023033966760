import React from "react";
import { DropTargetMonitor, useDragLayer, useDrop } from "react-dnd";

import { captureMessage } from "@/helpers/captureError";
import {
  DraggableDnDItem,
  DraggableItemType,
  DroppableItemType,
} from "@/types";
import {
  DEFAULT_ALLOCATION_HEIGHT,
  mutateAttachWorkOrderToVessel,
} from "@/zustand/useOrderStore";

import styles from "./index.module.scss";

type Props = {
  orderId: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  orderStyles: any;
  accept: DraggableItemType[] | string[];
};

type PartialDropPayload = {
  id: string;
  fromVesselId: string;
  toVesselId: string;
  orderLineNumber: string;
  orderNumber: string;
};

const VesselDroppableGroup: React.FC<Props> = ({
  orderId,
  orderStyles,
  accept,
}) => {
  const { isDragging, item: draggedItem } = useDragLayer((monitor) => ({
    isDragging: monitor.isDragging(),
    item: monitor.getItem(),
  }));

  const [{ isOver, canDrop }, drop] = useDrop<
    DraggableDnDItem,
    unknown,
    { isOver: boolean; canDrop: boolean }
  >(
    () => ({
      accept,
      drop: (item, monitor) => {
        const type = monitor.getItemType() as DroppableItemType;
        if (
          type === DraggableItemType.SubWorkOrder &&
          item?.orderNumber &&
          item?.orderLineNumber
        ) {
          const partialPayload: PartialDropPayload = {
            id: item.orderId,
            fromVesselId: item.parentVesselId,
            toVesselId: orderId, // here is the destination Vessel id, don't confuse with item.orderId, that is the work order being dragged.
            orderNumber: item?.orderNumber,
            orderLineNumber: item?.orderLineNumber,
          };
          mutateAttachWorkOrderToVessel(partialPayload);
        } else {
          captureMessage(
            `When attaching wo to a vessel, there is no orderNumber in the item: ${JSON.stringify(
              item,
            )}`,
          );
        }
        return {
          // TODO: Not sure if this first object is needed.
          ...{ orderId, type: monitor.getItemType() },
          viewportX: monitor.getClientOffset()?.x,
        };
      },
      collect: (monitor: DropTargetMonitor) => {
        return {
          isOver: monitor.isOver({ shallow: true }),
          canDrop: monitor.canDrop(),
        };
      },
      canDrop: (item) => {
        if (item?.parentVesselId && orderId) {
          // Let's ignore dropping inside the same vessel group
          if (item?.parentVesselId === orderId) {
            return false;
          }
          const prefix1 = item.parentVesselId.substring(
            0,
            item.parentVesselId.lastIndexOf(":"),
          );
          const prefix2 = orderId.substring(0, orderId.lastIndexOf(":"));
          return prefix1 === prefix2;
        }
        return false;
      },
    }),
    [accept],
  );

  const finalHeight =
    isDragging &&
    draggedItem?.draggingTheSide !== true &&
    canDrop &&
    (orderStyles.groupHeight || 0) < DEFAULT_ALLOCATION_HEIGHT
      ? DEFAULT_ALLOCATION_HEIGHT / 2
      : orderStyles.groupHeight;

  return (
    <div
      key={`${orderId}group`}
      style={{
        top: orderStyles.groupTop,
        left: orderStyles.groupLeft,
        height: finalHeight,
        width: orderStyles.groupWidth,
        backgroundColor: orderStyles.backgroundGroupColor,
        opacity: isOver ? "1" : "0.45",
        border:
          // eslint-disable-next-line no-nested-ternary
          isDragging && draggedItem?.draggingTheSide !== true && canDrop
            ? `3px dashed #${isOver ? "444" : "444"}`
            : finalHeight > 0
              ? `2px solid ${orderStyles.borderColor}`
              : "none",
      }}
      className={styles.groupedWOs}
      ref={drop}
    />
  );
};

export default VesselDroppableGroup;
