import { gql, useApolloClient } from "@apollo/client";
import find from "lodash/find";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import Checkbox from "@/components/common/formElements/checkbox/checkbox";
import { captureError, captureMessage } from "@/helpers/captureError";
import {
  isOperatorDEPRECATED,
  normalizeAssignment,
} from "@/helpers/normalizeCapabilityRequirements";
import { sleep } from "@/helpers/sleep";
import { DELETE_TEAM_FROM_ORDER_MUTATION } from "@/hooks/mutation/useDeleteTeamFromWorkOrder";
import { Team, WorkOrder } from "@/types";
import usePubSubStore from "@/zustand/usePubSubStore";
import { useSettingsStore, useUserData } from "@/zustand/useSettingsStore";
import { useSnackbar } from "@/zustand/useSnackbar";

import {
  ASSIGN_EMPLOYEE_TO_WORK_ORDER_LINE,
  ASSIGN_RESOURCE,
  ASSIGN_TEAM_MUTATION,
  CREATE_WORK_ORDERLINE_MUTATION,
  GET_TEAM_OPERATION_LINES,
  GET_WORK_ORDER_LINES,
} from "../../sidePanel/assignResources/queries";

import styles from "../../index.module.scss";
import buttons from "@/style/buttons.module.scss";
import textStyles from "@/style/textStyles.module.scss";

type Props = {
  isModalAssign: boolean;
  changeModalState: () => void;
  workOrders: WorkOrder[];
  teamData: Team;
};

type OrderLabel2 = {
  id: string;
  checked: boolean;
  description: string;
};

type OrderLabel = {
  id: string;
  teamLineMatching: boolean;
  description: string;
  operator: string;
  equipment: string;
  isOperator: boolean;
  workCenterCategoryId: string;
  workCenterMachineId: string;
  workOrderLineId: string;
  operatorId: string;
  type: string;
  isMerged?: boolean;
  capabilityType: string;
};

const GET_CAPABILITY_TYPE = gql`
  query getCapabilityType($workOrderLineId: String!) {
    workOrderLineById(workOrderLineId: $workOrderLineId) {
      id
      assignment {
        id
        operationElementId
        capabilityType
        capabilityTypeNumber
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mlog = (...args: any[]): void => {
  if (window?.DEBUG_MERGE) {
    console.debug("%c[MERGE]", "background: #f9f8df;", ...args);
  }
};

const AssignTeamModal: FC<Props> = ({
  isModalAssign,
  changeModalState,
  workOrders, // can be multiple ones (for a vessel)
  teamData,
}) => {
  const client = useApolloClient();
  const selectedFacility = useSettingsStore((state) => state.selectedFacility);
  const userData = useUserData();
  const setSnack = useSnackbar((state) => state.setSnack);

  const subscribe = usePubSubStore((state) => state.subscribe);
  const unsubscribe = usePubSubStore((state) => state.unsubscribe);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const pendingAssignmentList = useRef<Array<any | null>>([]);
  const alreadyProcessedLines = useRef<Array<string>>([]);
  const disableSuperLinking = useSettingsStore(
    (state) => state.disableSuperLinking,
  );
  const teamId = teamData?.id;
  const [checkedWorkOrders, setCheckedWorkOrders] = useState(
    workOrders.map((el: WorkOrder) => {
      return {
        id: el.id,
        checked: false,
        description: el.description,
      };
    }),
  );

  // Is a line that is waiting to be created? (so we have a proper woline ID to assign)
  const isPendingAssignmentCheck = useCallback(
    (pendingAssignmentId) => {
      return pendingAssignmentList?.current?.find(
        (item) => item.pendingAssignmentId === pendingAssignmentId,
      );
    },
    [pendingAssignmentList],
  );

  const mergeLine = useCallback(
    async (woId, el) => {
      // If no match, we need to create a new operation line
      const capabilityId = `${selectedFacility}-${el.workCenterCategoryId}`; // For example "230-BU013001",
      const uniqueId = `${woId}|${capabilityId}`;

      const isPendingAssignment = isPendingAssignmentCheck(uniqueId);
      if (isPendingAssignment) {
        mlog(
          "mergeLine should be aborted?",
          uniqueId,
          {
            isPendingAssignment,
          },
          el,
        );
      } else {
        const toRemoveIndex = alreadyProcessedLines.current.indexOf(
          el.workOrderLineId,
        );
        mlog("removing", el.workOrderLineId, toRemoveIndex);
        alreadyProcessedLines.current.splice(toRemoveIndex, 1);
      }
      if (!el.teamLineMatching && !el.readyToBeAssigned) {
        // TODO: Do we need to track it?
        // addWoLineIdToInPogressList(uniqueId);

        const variables2 = {
          facilityId: selectedFacility,
          issuedBy: userData.mail,
          capabilityId,
          description: el.description || "",
          parentWorkOrderId: woId,
        };
        const createLineResult = await client.mutate({
          mutation: CREATE_WORK_ORDERLINE_MUTATION,
          variables: variables2,
        });
        mlog(
          "POST-CREATE_WORK_ORDERLINE_MUTATION: ",
          createLineResult?.data?.createWorkOrderLine,
          variables2,
        );
        if (isArray(pendingAssignmentList.current)) {
          mlog("Assigning now to pendingList", `${woId}|${capabilityId}`);
          pendingAssignmentList.current.push({
            pendingAssignmentId: `${woId}|${capabilityId}`,
            ...el,
          });
          // Early return. We don't want to continue the assignment. We wait to have a proper ID via Events & PubSub for the next mutations.
          return true;
        }
        if (!createLineResult?.data?.createWorkOrderLine) {
          return false;
        }
      }

      let { capabilityType } = el;
      if (!capabilityType) {
        mlog("No capabilityType, let's fetch it via workOrderLineId", el);
        const LineById = await client.query({
          query: GET_CAPABILITY_TYPE,
          variables: { workOrderLineId: el.workOrderLineId },
        });
        capabilityType =
          LineById?.data?.workOrderLineById?.assignment?.capabilityTypeNumber?.toString();
        mlog("CapabilityFetched:", capabilityType);
      }
      if (!capabilityType) {
        await sleep(10000);
        mlog(
          "No capabilityType still, let's fetch it via workOrderLineId (2nd try)",
          el,
        );
        const LineById = await client.query({
          query: GET_CAPABILITY_TYPE,
          variables: { workOrderLineId: el.workOrderLineId },
        });
        capabilityType =
          LineById?.data?.workOrderLineById?.assignment?.capabilityType;
        mlog("CapabilityFetched:", capabilityType);
      }
      if (!capabilityType) {
        captureError(
          `We couldnt't assign equipment OR employee without having first the capabilityType. Aborting process for: ${el.workOrderLineId}`,
        );
        return false;
      }
      if (el.workCenterMachineId) {
        const variables2 = {
          facilityId: selectedFacility,
          issuedBy: userData.mail,
          workCenterId: el.workCenterMachineId.split("-")[1] || "",
          workOrderLineId: el.workOrderLineId,
          capabilityType: capabilityType || "",
        };
        const assignResourceResult = await client.mutate({
          mutation: ASSIGN_RESOURCE,
          variables: variables2,
        });
        mlog(
          "POST-ASSIGN_RESOURCE (EQUIP) MUTATION: ",
          assignResourceResult?.data?.assignWorkCenterToWorkOrderLineM3,
          variables2,
        );

        if (!assignResourceResult?.data?.assignWorkCenterToWorkOrderLineM3) {
          return false;
        }
      }

      const variables3 = {
        facilityId: selectedFacility,
        issuedBy: userData.mail,
        workCenterId: el.workCenterMachineId || "",
        operatorId: el.operatorId || "",
        workOrderLineId: el.workOrderLineId,
        capabilityType: capabilityType || "",
      };
      const assignEmployeeResult = await client.mutate({
        mutation: ASSIGN_EMPLOYEE_TO_WORK_ORDER_LINE,
        variables: variables3,
      });
      mlog(
        "POST-ASSIGN_EMPLOYEE MUTATION: ",
        assignEmployeeResult?.data?.assignEmployeeToWorkOrderLine,
        variables3,
      );

      if (!assignEmployeeResult?.data?.assignEmployeeToWorkOrderLine) {
        return false;
      }
      return true;
    },
    [client, selectedFacility, isPendingAssignmentCheck, userData.mail],
  );

  const handleWorkOrderLineCreated = useCallback(
    (data: any) => {
      // We are notified via Events (WS) that a new line is created, and now we have a proper ID.
      // Is the new line one of the lines we created
      // when merging a team (stored in pendingAssignmentList), and not created via "Add operation line" ?
      // TODO: Make it more robust, save it in localstorage?
      if (
        typeof data?.id === "string" &&
        isArray(pendingAssignmentList?.current) &&
        !alreadyProcessedLines.current.includes(data?.newWorkOrderLineId || "")
      ) {
        const wolPendingAssignment = pendingAssignmentList?.current?.find(
          (item) => item.pendingAssignmentId === data?.id,
        );
        mlog(
          "PENDING LIST: ",
          pendingAssignmentList.current,
          wolPendingAssignment,
        );
        if (wolPendingAssignment) {
          wolPendingAssignment.workOrderLineId = data?.newWorkOrderLineId;
          delete wolPendingAssignment.pendingAssignmentId;
          // Telling mergeLine that we don't need creation of the line for this item. It's ready to be assigned (machine and/or operator)
          wolPendingAssignment.readyToBeAssigned = true;
          // setTimeout because:
          // * new operation lines are not ready to be queried (to obtain the capabilityType)
          // * and also, for teams with 2 repeated capabilityType we don't have a way to differentiate them (id is not unique enough)
          mlog("SetTimeout for 3 seconds to complete assignment", data);
          setTimeout(() => {
            mergeLine(data?.id?.split("|")[0], wolPendingAssignment);
          }, 3000);

          if (data?.newWorkOrderLineId) {
            alreadyProcessedLines.current.push(data.newWorkOrderLineId);
          }
          // Remove the processed item. splice() mutates the array
          pendingAssignmentList.current.splice(
            pendingAssignmentList.current.findIndex(
              (item) => item.pendingAssignmentId !== data?.id,
            ),
            1,
          );
        } else {
          mlog("No matching pending assignment found in the list", data);
        }
      }
    },
    [mergeLine],
  );

  useEffect(() => {
    // Define the action type
    const actionType = "WorkOrderLineCreated";

    // Subscribe to the action on mount
    subscribe(actionType, handleWorkOrderLineCreated);

    // DON't unsubscribe. Modal is unmounted, but still we want this action to get triggered.
    // // Unsubscribe from the action on unmount
    // return () => {
    //   unsubscribe(actionType, handleWorkOrderLineCreated);
    // };
  }, [handleWorkOrderLineCreated, subscribe, unsubscribe]);

  const handleMergeOrderLines = useCallback(
    async (woIndex) => {
      const selectedWorkOrders =
        workOrders.length === 1
          ? workOrders
          : workOrders.filter((wo) =>
              checkedWorkOrders.find((cwo) => cwo.id === wo.id && cwo.checked),
            );

      const teamLinesResponse = await client.query({
        query: GET_TEAM_OPERATION_LINES,
        variables: { teamId, facilityId: selectedFacility },
      });
      const allLinesResponse = await client.query({
        query: GET_WORK_ORDER_LINES,
        variables: { workOrderId: selectedWorkOrders[woIndex]?.id },
      });
      const allLinesRaw = allLinesResponse?.data?.workOrderById?.lines;
      const teamLinesRaw = teamLinesResponse?.data?.getOrderLinesByTeamId;
      if (!teamLinesRaw) return;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const teamLines = teamLinesRaw.map((el: any) => {
        // TODO: use isOperator() function if possible
        const requiresEquipmentOperator = isOperatorDEPRECATED(
          el?.workCenterCategory?.id,
        );

        return {
          id: el.id,
          type: "team",
          teamLineMatching: false,
          description: el.workCenterCategory?.name,
          operator: el.operator
            ? `${el.operator.firstName} ${el.operator.lastName}`
            : "Unassigned",
          operatorId: el.operator?.id || null,
          equipment: el.workCenterMachine?.name || "Unassigned",
          workCenterMachineId: el.workCenterMachine?.id || null,
          workCenterCategoryId: el.workCenterCategory?.workCenterId || null,
          isOperator: requiresEquipmentOperator,
          capabilityType: el.capabilityType,
          workOrderLineId: el.id,
        };
      });

      const allLines = isEmpty(allLinesRaw)
        ? []
        : // eslint-disable-next-line @typescript-eslint/no-explicit-any
          allLinesRaw.map((el: any) => {
            // TODO: is this enough check?

            if (!el.assignment) {
              // TODO: Actually this can happen if the team lines are only non-equip people
              captureMessage(
                "No capabilityRequirements. Skipping normalization allLinesRaw.map()",
              );
            }
            const capabilityRequirements = normalizeAssignment(el.assignment);

            return {
              id: el.id,
              type: "workOrder",
              checked: false,
              teamLineMatching: false,
              description: el.description,
              operator: capabilityRequirements.assigned,
              operatorId: el.assignment?.operator?.id || null,
              equipment: capabilityRequirements.equipment,
              isOperator: capabilityRequirements.requiresEquipmentOperator,
              workCenterMachineId: el?.assignment?.workCenter?.id || null,
              workCenterCategoryId: el.capability,
              capabilityType: el.capabilityRequirements?.[0]?.capabilityType,
              operationElement: capabilityRequirements?.operationElement,
              workOrderLineId: el.id,
            };
          });

      // Extend teamLines objects with extra information. Also overrides workOrderLineId and capabilityType with valid ones
      const alreadyMatchedOperationLines: OrderLabel[] = [];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      teamLines.forEach((operator: any, index: number) => {
        const foundMatch = find(allLines, (operationLine) => {
          return (
            operationLine.operator === "Unassigned" &&
            operationLine.workCenterCategoryId ===
              operator.workCenterCategoryId &&
            alreadyMatchedOperationLines.findIndex(
              (e) => e.id === operationLine.id,
            ) === -1
          );
        });
        if (foundMatch) {
          alreadyMatchedOperationLines.push(foundMatch);
          teamLines[index] = {
            ...operator,
            id: operator.id,
            workOrderLineId: foundMatch.workOrderLineId,
            teamLineMatching: true,
            type: "team",
            capabilityType: foundMatch.capabilityType,
          };
        }
      });

      let failedAssignments = 0;
      mlog(
        "These are the Team Lines fetched",
        { teamLines },
        "and selected work orders",
        selectedWorkOrders,
      );
      if (!isEmpty(teamLines)) {
        // eslint-disable-next-line no-restricted-syntax
        for (const line of teamLines) {
          try {
            // eslint-disable-next-line no-await-in-loop
            const result = await mergeLine(
              selectedWorkOrders[woIndex]?.id,
              line,
            );
            if (!result) {
              failedAssignments = +1;
            }
          } catch (e) {
            captureError(e);
            setSnack(true, "An error occured while merging lines");
          }
        }

        mlog(
          "-- Finished first part of mergingLines. Doing the Assign-Team mutation",
          selectedWorkOrders[woIndex]?.assignedTeam?.id,
        );

        if (selectedWorkOrders[woIndex]?.assignedTeam?.id) {
          const variables = {
            teamId: selectedWorkOrders[woIndex]?.assignedTeam?.id,
            issuedBy: userData.mail,
            workOrderId: selectedWorkOrders[woIndex]?.id,
          };
          mlog(
            "POST-Mutation: DELETE_TEAM_FROM_ORDER_MUTATION.",
            selectedWorkOrders[woIndex]?.assignedTeam?.id,
          );
          const deleteTeamResult = await client.mutate({
            mutation: DELETE_TEAM_FROM_ORDER_MUTATION,
            variables,
          });
          if (!deleteTeamResult.data.deleteTeamFromWorkOrder) {
            captureMessage("previous team was not un-assigned");
          }
        }
        const assignTeamResult = await client.mutate({
          mutation: ASSIGN_TEAM_MUTATION,
          variables: {
            facilityId: selectedFacility,
            teamId,
            issuedBy: userData.mail,
            workOrderId: selectedWorkOrders[woIndex]?.id,
          },
        });
        if (!assignTeamResult.data.assignTeamToWorkOrder) {
          captureMessage("team was not assigned");
        }
        const message = `Team was assigned ${
          failedAssignments > 0
            ? `with ${failedAssignments} failed assignment(s)`
            : " succesfully ✓"
        }`;
        setSnack(true, message);

        if (woIndex + 1 <= selectedWorkOrders.length - 1) {
          handleMergeOrderLines(woIndex + 1);
        }
      }
    },
    [
      workOrders,
      client,
      teamId,
      checkedWorkOrders,
      mergeLine,
      selectedFacility,
      userData.mail,
      setSnack,
    ],
  );

  const hasCheckedElements = () => {
    return checkedWorkOrders.find((el) => el.checked === true) !== undefined;
  };

  const handleCheckWorkOrder = (i: number) => {
    const updatedOrders = [...checkedWorkOrders];
    updatedOrders[i].checked = !updatedOrders[i].checked;
    setCheckedWorkOrders(updatedOrders);
  };

  const handleClose = useCallback(() => {
    disableSuperLinking();
    changeModalState();
  }, [changeModalState, disableSuperLinking]);

  const singleWorkOrder = workOrders && workOrders.length <= 1;
  useEffect(() => {
    // Let's take the shortcut: Auto-Close the modal if there is only 1 work order to pick (before the user even is able to see the modal)
    if (singleWorkOrder && isModalAssign && teamId) {
      setSnack(true, "Assigning Team ...");
      handleMergeOrderLines(0);
      changeModalState();
      disableSuperLinking();
    }
  }, [
    changeModalState,
    disableSuperLinking,
    handleMergeOrderLines,
    isModalAssign,
    setSnack,
    singleWorkOrder,
    teamId,
  ]);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className={`${styles.startVesselModal} ${styles.deleteVesselModal}`}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <h5 className={textStyles.primaryText}>Assign team to order</h5>
      <span className={textStyles.primaryText}>
        Which order(s) would you like to assign this team to?
      </span>
      <div className={styles.orderLinesChecboxes}>
        {!isEmpty(checkedWorkOrders) &&
          checkedWorkOrders.map((el: OrderLabel2, idx: number) => (
            <Checkbox
              key={el.id}
              label={`#${el.id} - ${el.description}`}
              checked={el.checked}
              onChange={() => handleCheckWorkOrder(idx)}
            />
          ))}
      </div>
      <section>
        <button
          className={buttons.modalButton}
          type="button"
          onClick={handleClose}
        >
          Cancel
        </button>
        <button
          className={buttons.modalButton}
          type="button"
          onClick={() => {
            handleMergeOrderLines(0);
            changeModalState();
          }}
          disabled={!hasCheckedElements()}
        >
          Assign
        </button>
      </section>
    </div>
  );
};
export default memo(AssignTeamModal);
