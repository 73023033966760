import { gql, useMutation } from "@apollo/client";

import { useSettingsStore, useUserData } from "@/zustand/useSettingsStore";

const DELETE_TEAM_MUTATION = gql`
  mutation deleteTeam(
    $teamId: UUID!
    $issuedBy: String!
    $facilityId: String!
  ) {
    deleteTeam(teamId: $teamId, issuedBy: $issuedBy, facilityId: $facilityId)
  }
`;

const useDeleteTeam = (): typeof setUpdate => {
  const userData = useUserData();
  const selectedFacility = useSettingsStore((state) => state.selectedFacility);

  const [deleteTeam, { error }] = useMutation(DELETE_TEAM_MUTATION);

  if (error) {
    console.error(error);
  }

  const setUpdate = (teamId: string) => {
    deleteTeam({
      variables: {
        teamId,
        issuedBy: userData.mail,
        facilityId: selectedFacility,
      },
    });
  };

  return setUpdate;
};

export default useDeleteTeam;
