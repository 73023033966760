import { Assignment } from "@/types";

// Based on this information https://trello.com/c/wzN3vD9Z/12-wo-order-of-operations-when-assigning-machines-and-operators
//    Havard:
//      If capability type is lower than 200 = non machine category operation
//      If capability type is higher than 899 = machine category operation
export const isOperator = (capabilityType: number | undefined): boolean => {
  const capType = parseInt(capabilityType || "", 10);
  return capType >= 200; // capabilityType of 1xx don't operate equipment
};

// isOperator is the preferred way to resolve if it's a machine operator.
// Still, we need this for some cases, where capabilityType is not available
// eslint-disable-next-line camelcase
export const isOperatorDEPRECATED = (workcenter: string): boolean => {
  if (!workcenter) {
    return false;
  }

  // This list is based on this spec 2022: https://trello.com/c/OUvHNVOJ/39-work-order-filtering-on-qualified-employees-and-machines
  return !(
    (
      workcenter.includes("BA") || // Base Operator
      workcenter.includes("MF") || // Machine Driver / Machine Operator
      workcenter.includes("CD") || // Crane driver
      workcenter.includes("LE") || // Foreman
      workcenter.includes("BU") || // Bulk Operator
      workcenter.includes("TM") || // Truck Mechanic
      workcenter.includes("TO") || // Technical Operator
      workcenter.includes("TL") || // Technical supervisor
      workcenter.includes("PK") || // Project coordinator
      workcenter.includes("PL") || // Project foreman
      workcenter.includes("LK") || // Logistic coordinator
      workcenter.includes("SLK") || // Senior Logistic coordinator
      workcenter.includes("LO") || // Warehouse operator
      workcenter.includes("TP") || // Technical personel
      workcenter.includes("MO") || // Machine Operator / Crane Operator
      workcenter.includes("FO") || // Foreman
      workcenter.includes("OC")
    ) // OCTG Coordinator
  );
};

const normalizeCapability = (
  capabilityRequirement: any,
): {
  requiresEquipmentOperator: boolean;
  equipment: string;
  assigned: string;
  capabilityType: string;
  operationElement: string;
} => {
  const assigned = !capabilityRequirement?.operator
    ? "Unassigned"
    : `${capabilityRequirement?.operator?.firstName} ${capabilityRequirement?.operator?.middleName} ${capabilityRequirement?.operator?.lastName}`;
  const equipment = capabilityRequirement?.workcenter
    ? `${capabilityRequirement?.workcenter.name}`
    : "Unassigned";

  const capabilityType = capabilityRequirement?.capabilityType;
  return {
    capabilityType,
    requiresEquipmentOperator: isOperator(capabilityType),
    operationElement: capabilityRequirement?.operationElement,
    equipment,
    assigned,
  };
};

export const normalizeAssignment = (
  assignment: Assignment,
): {
  requiresEquipmentOperator: boolean;
  equipment: string;
  assigned: string;
  capabilityType: string;
} => {
  const assigned = !assignment?.operator
    ? "Unassigned"
    : `${assignment?.operator?.firstName} ${assignment?.operator?.middleName} ${assignment?.operator?.lastName}`;
  const equipment = assignment?.workCenter
    ? `${assignment?.workCenter.name}`
    : "Unassigned";

  const capabilityType = assignment?.capabilityType;
  return {
    capabilityType,
    requiresEquipmentOperator: capabilityType === "WORK_CENTER",
    equipment,
    assigned,
  };
};

export default normalizeCapability;
