import moment, { Moment } from "moment-timezone";
import React, { FC, memo, useMemo, useState } from "react";

import DateAndTimeField from "@/components/common/formElements/dateAndTimeField";
import { validateStartBeforeStop } from "@/helpers/dateTimeValidator";
import { useVesselMooring } from "@/hooks/mutation/useVesselMooring";
import { useSnackbar } from "@/zustand/useSnackbar";

import styles from "../../index.module.scss";
import buttons from "@/style/buttons.module.scss";
import textStyles from "@/style/textStyles.module.scss";

type Props = {
  orderId: string;
  initialStartDate: string;
  initialStopDate: string;
  changeModalState: () => void;
  switchToStartModal: () => void;
};
const ChangeStartStopModal: FC<Props> = ({
  orderId,
  initialStartDate,
  initialStopDate,
  changeModalState,
  switchToStartModal,
}) => {
  const initialStart = initialStartDate ? moment(initialStartDate) : null;
  const initialStop = initialStopDate ? moment(initialStopDate) : null;
  const [stopDate, setStopDate] = useState<Moment | null>(initialStop);

  const [dirtyStopDate, setDirtyStopDate] = useState<boolean>(false);

  const { setStop: stopVesselMooring } = useVesselMooring();

  const setSnack = useSnackbar((state) => state.setSnack);

  const isValidRange = useMemo(() => {
    if (initialStart && stopDate) {
      return validateStartBeforeStop(initialStart, stopDate);
    }
    return false;
  }, [initialStart, stopDate]);

  const showConfirmButton = dirtyStopDate && isValidRange;

  return (
    <div className={styles.startVesselModal}>
      <h5 className={textStyles.primaryText}>Stop Vessel</h5>
      <span className={textStyles.primaryText}>
        Confirm or change actual stop time.
      </span>

      {initialStart ? (
        <div>
          <span className={textStyles.primaryText}>Actual start:</span>
          <DateAndTimeField
            dateValue={initialStart}
            disableOpenPicker
            readOnly
            customStyle={{
              input: {
                background: "white",
              },
              ".MuiOutlinedInput-root": {
                fieldset: {
                  border: "none !important",
                },
              },
            }}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            handleChange={() => {}}
          />
        </div>
      ) : (
        <div>
          <span className={textStyles.primaryText}>Actual start:</span>

          <button
            className={buttons.modalButton}
            type="button"
            onClick={() => {
              switchToStartModal();
            }}
          >
            Start Vessel
          </button>
        </div>
      )}
      <div>
        <span className={textStyles.primaryText}>Actual stop:</span>
        <DateAndTimeField
          autoFocus={!!initialStartDate}
          dateValue={stopDate}
          readOnly={!initialStartDate}
          handleChange={(newValue) => {
            setDirtyStopDate(true);
            setStopDate(newValue);
          }}
        />
      </div>
      {initialStartDate && stopDate && !isValidRange && (
        <p className="error-message">
          Check that the start date is later than the end date. Give it another
          try!
        </p>
      )}
      {!initialStartDate && (
        <p className="error-message">
          If you want to stop the vessel, you first need to start it
        </p>
      )}
      <section>
        <button
          className={buttons.modalButton}
          type="button"
          onClick={changeModalState}
        >
          Cancel
        </button>
        <button
          className={buttons.modalButton}
          type="button"
          onClick={() => {
            if (!orderId) return;

            if (stopDate && dirtyStopDate) {
              stopVesselMooring({
                id: orderId,
                plannedStopDate: stopDate?.toISOString(),
              });
            }
            setSnack(true, "Vessel mooring stopped.");
            changeModalState();
          }}
          disabled={!showConfirmButton}
        >
          Confirm changes
        </button>
      </section>
    </div>
  );
};
export default memo(ChangeStartStopModal);
