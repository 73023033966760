import { create } from "zustand";
import { devtools, subscribeWithSelector } from "zustand/middleware";

type InProgressState = {
  poChangeInProgress: any[];
  materialItemInProgress: any[];
  vesselChangeInProgress: any[];
  woChangeInProgress: any[];
  woLineChangeInProgress: any[];
  inProgressSnackMessage: string;
};

export const useInProgressStore = create<InProgressState>()(
  // log(
  devtools(
    subscribeWithSelector(() => ({
      poChangeInProgress: [],
      materialItemInProgress: [],
      vesselChangeInProgress: [],
      woChangeInProgress: [],
      woLineChangeInProgress: [],
      inProgressSnackMessage: "",
    })),
  ),
);

export const addIdToMaterialItemInProgressList = (id) => {
  useInProgressStore.setState(({ materialItemInProgress }) => {
    // to avoid duplicates
    const cleanList = materialItemInProgress.filter(
      (eachItem) => eachItem?.id !== id,
    );
    return {
      materialItemInProgress: [...cleanList, { id, created: Date.now() }],
    };
  });
};

export const removeIdFromMaterialItemInProgressList = (id) => {
  useInProgressStore.setState(({ materialItemInProgress }) => {
    // to avoid duplicates
    const cleanList = materialItemInProgress.filter(
      (eachItem) => eachItem?.id !== id,
    );
    return {
      materialItemInProgress: cleanList,
    };
  });
};

export const addIdToPoInPogressList = (id) => {
  useInProgressStore.setState(({ poChangeInProgress }) => {
    // to avoid duplicates
    const cleanList = poChangeInProgress.filter(
      (eachItem) => eachItem?.id !== id,
    );
    return {
      poChangeInProgress: [...cleanList, { id, created: Date.now() }],
    };
  });
};

export const removeIdFromPoInPogressList = (id) => {
  useInProgressStore.setState(({ poChangeInProgress }) => {
    const updatedIds = poChangeInProgress.filter(
      (eachItem) => eachItem?.id !== id,
    );
    return {
      poChangeInProgress: updatedIds,
    };
  });
};

export const removeIdFromVesselInPogressList = (id) => {
  useInProgressStore.setState(({ vesselChangeInProgress }) => {
    const updatedIds = vesselChangeInProgress.filter(
      (eachItem) => eachItem?.id !== id,
    );
    return {
      vesselChangeInProgress: updatedIds,
    };
  });
};

export const removeIdFromWoInPogressList = (id) => {
  useInProgressStore.setState(({ woChangeInProgress }) => {
    const updatedIds = woChangeInProgress.filter(
      (eachItem) => eachItem?.id !== id,
    );
    return {
      woChangeInProgress: updatedIds,
    };
  });
};

export const removeIdFromWoLineInPogressList = (id) => {
  useInProgressStore.setState(({ woLineChangeInProgress }) => {
    const updatedIds = woLineChangeInProgress.filter(
      (eachItem) => eachItem?.id !== id,
    );
    return {
      woLineChangeInProgress: updatedIds,
    };
  });
};

export const addWoLineIdToInPogressList = (id) => {
  useInProgressStore.setState(({ woLineChangeInProgress }) => {
    return {
      woLineChangeInProgress: [
        ...woLineChangeInProgress,
        { id, created: Date.now() },
      ],
    };
  }); // Remove it after 30 seconds (timeout)
  setTimeout(() => {
    removeIdFromWoLineInPogressList(id);
  }, 30000);
};

useInProgressStore.subscribe(
  (state) => state.vesselChangeInProgress,
  async () => {
    useInProgressStore.setState({
      inProgressSnackMessage: `Something might have gone wrong with saving`,
    });
  },
);
