import React, { FC, memo } from "react";

import st from "@/components/common/formElements/customFormStyles.module.scss";

type Props = {
  value: string;
  className?: string;
  setValue: (e: string) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  placeholder: string;
  disabled?: boolean;
  error: boolean;
  errorMessage: string;
};

const InputText: FC<Props> = ({
  placeholder,
  className = "",
  value,
  setValue,
  disabled = false,
  errorMessage,
  error,
  onBlur = () => null,
  onFocus = () => null,
  onKeyPress = () => null,
}) => {
  return (
    <div className={`${st.inputBlock} ${className}`}>
      <input
        onBlur={onBlur}
        onFocus={onFocus}
        type="text"
        placeholder={placeholder}
        value={value ?? ""}
        disabled={disabled}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setValue(e.target.value)
        }
        onKeyPress={onKeyPress}
        className={st.customInput}
        style={error ? { border: "1px solid #BB0000" } : {}}
      />
      {error && <p className="error-message">{errorMessage}</p>}
    </div>
  );
};

export default memo(InputText);
