import React, { memo, useCallback, useEffect } from "react";

import useWindowDimensions from "@/helpers/useWindowDimensions";
import { useSettingsStore } from "@/zustand/useSettingsStore";

type Props = {
  arrayToRender: any[];
};

const TimelineFloatingLabels: React.FC<Props> = ({ arrayToRender }) => {
  const { width } = useWindowDimensions();
  const inDetailedView = useSettingsStore((state) => state.inDetailedView);

  const handleScroll = useCallback(() => {
    const timelineDOM = document.getElementById("timelineTable");

    const onScroll = () => {
      const timelineScrollLeft = timelineDOM?.scrollLeft;
      const timelineWidth = timelineDOM?.clientWidth;
      const currents = [];

      for (let i = 0; i < arrayToRender.length; i += 1) {
        if (arrayToRender[i]?.orders) {
          for (let j = 0; j < arrayToRender[i].orders.length; j += 1) {
            if (arrayToRender[i].orders[j]?.theRef?.current) {
              currents.push(arrayToRender[i].orders[j].theRef.current);
              for (
                let k = 0;
                k < arrayToRender[i].orders[j].workOrders?.length;
                k += 1
              ) {
                if (arrayToRender[i].orders[j].workOrders[k]?.theRef?.current) {
                  currents.push(
                    arrayToRender[i].orders[j].workOrders[k].theRef.current,
                  );
                }
              }
            }
          }
        }
      }
      if (currents.length === 0) {
        return;
      }

      if (!timelineWidth) {
        return;
      }

      for (let k = 0; k < currents.length; k += 1) {
        const item = currents[k];

        const allocationWidth = parseInt(
          item.getAttribute("data-allocation-width"),
          10,
        );
        const originalOffsetLeft = parseInt(
          item.getAttribute("data-allocation-offsetleft"),
          10,
        );

        const itemLeftEdgeRelativeToScroll =
          originalOffsetLeft - timelineScrollLeft;

        const itemRightEdgeRelativeToScroll =
          originalOffsetLeft - timelineScrollLeft + allocationWidth;
        const textWidth = item.children[0].clientWidth;
        const itemClasses = item.classList;

        if (
          allocationWidth > textWidth + 40 && // this line is to prevent pinning labels with very little room to "floatm" inside the container.
          itemLeftEdgeRelativeToScroll + 5 < 0 &&
          itemRightEdgeRelativeToScroll -
            (inDetailedView ? 0 : textWidth + 10) >
            20
        ) {
          if (!item.classList.contains("tableOrderContainerPinned")) {
            itemClasses.add("tableOrderContainerPinned");
            itemClasses.remove("withShadow");
            item.position = "sticky";
            item.style.left = "15px";
            item.style.width = `${textWidth}px`;
          }
        } else if (
          item.position !== "absolute" ||
          !itemClasses.contains("absolute-initial-processed") // The CSS of the element already has "absolute". We have to process once to calculate shadow.
        ) {
          itemClasses.add("absolute-initial-processed");
          item.position = "absolute";
          itemClasses.remove("tableOrderContainerPinned");
          // Easy way to debug: Check thea data-allocation-width in HTML.
          // if (allocationWidth === 1074) {
          //   console.log(
          //     "--",
          //     itemLeftEdgeRelativeToScroll,
          //     originalOffsetLeft + allocationWidth - textWidth - 5
          //   );
          // }
          if (itemLeftEdgeRelativeToScroll >= -120) {
            item.style.left = `${originalOffsetLeft + 20}px`;
            itemClasses.add("withShadow");
          } else {
            itemClasses.remove("withShadow");
            item.style.left = `${
              originalOffsetLeft + Math.max(allocationWidth - textWidth, 30) - 5
            }px`;
          }
          if (!itemClasses.contains("vesselLabel")) {
            // why this complex? Because allocations taking 1.5 days inside a 2 days view, were pushing and breaking the layout when totally scrolled to the right
            item.style.width = `${Math.max(Math.min(allocationWidth, Math.max(allocationWidth, 2 * timelineWidth) - timelineScrollLeft), 0)}px`;
          } else {
            item.style.width = `${Math.max(Math.min(allocationWidth, Math.max(allocationWidth, 2 * timelineWidth) - timelineScrollLeft) - 18, 0)}px`;
          }
        }
      }
    };

    requestAnimationFrame(() => {
      onScroll();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrayToRender, inDetailedView, width]);

  useEffect(() => {
    const timelineDOM = document.getElementById("timelineTable");

    timelineDOM?.addEventListener("scroll", handleScroll, { passive: true });
    // Trigger it already, to float sticky labels from the begining
    const scrollEvent = new Event("scroll");
    timelineDOM.dispatchEvent(scrollEvent);
    return () => timelineDOM?.removeEventListener("scroll", handleScroll);
  }, [arrayToRender, handleScroll, inDetailedView, width]);

  return null;
};

export default memo(TimelineFloatingLabels);
