import { gql } from "@apollo/client";

export const GET_OPERATORS = gql`
  query getOperatorsForFacility($facilityId: String!) {
    operators(facilityId: $facilityId) {
      lastName
      firstName
      id
      planningAreaId
      facilityId
    }
  }
`;

export const GET_CATEGORIES = gql`
  query getCategoriesForFacility($facilityId: String!) {
    workCentersCategories(facilityId: $facilityId) {
      id
      name
      workCenterId
      workCenterType
      planningArea
      operationElementId
    }
  }
`;

export const GET_EQUIPMENT = gql`
  query getEquipmentForFacility($facilityId: String!) {
    workCentersMachines(facilityId: $facilityId) {
      id
      name
      workCenterId
      workCenterType
      parentId
      planningArea
      facilityId
      operationElementId
    }
  }
`;

export const GET_EQUIPMENT_FOR_CATEGORY = gql`
  query getEquipmentForCategory(
    $facilityId: String!
    $workCenterType: String!
  ) {
    workCentersMachinesByType(
      facilityId: $facilityId
      workCenterType: $workCenterType
    ) {
      workCenterId
      workCenterType
      name
      planningArea
      facilityId
    }
  }
`;

export const GET_OPERATORS_FOR_WORKCENTER = gql`
  query getOperatorsForWorkCenter(
    $facilityId: String!
    $operationElementId: String!
  ) {
    certifiedOperators(
      operationElementId: $operationElementId
      facilityId: $facilityId
    ) {
      id
      firstName
      lastName
      planningAreaId
    }
  }
`;
