import { gql, useQuery } from "@apollo/client";
import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import sortBy from "lodash/sortBy";
import React, { FC, memo, useEffect, useState } from "react";

import ReactLoader from "@/components/common/formElements/loader";
import arrow from "@/style/img/arrow.svg";
import { SelectSingleProps, Team } from "@/types";
import { useSettingsStore } from "@/zustand/useSettingsStore";

import ConfigureTeam from "./createNewTeam/createNewTeam";
import TeamBuilderFilters from "./filters";
import TeamsList from "./teamsList";

import styles from "./index.module.scss";
import buttons from "@/style/buttons.module.scss";
import textStyles from "@/style/textStyles.module.scss";

const GET_TEAMS_QUERY = gql`
  query getTeamsByFacilityID($facilityId: String!) {
    facilityById(facilityId: $facilityId) {
      teams {
        startDate
        baseOperatorId
        id
        isSynchronized
        lastModifiedBy
        lastModifiedDate
        tags
        name
        startDate
        operationLines {
          workCenterCategory(facilityId: $facilityId) {
            id
            workCenterId
            workCenterType
            name
          }
          workCenterMachine(facilityId: $facilityId) {
            id
            workCenterId
            workCenterType
            name
          }
          id
          operatorId
          issuedBy
          operator {
            lastName
            firstName
            id
          }
        }
      }
    }
  }
`;

const TeamBuilder: FC = () => {
  const selectedFacility = useSettingsStore((state) => state.selectedFacility);
  const [searchValue, setSearchValue] = useState("");
  const [tagFilterValues, setTagFilterValues] = useState<string[]>([]);
  const [sortFilter, setSortFilterValue] = useState<SelectSingleProps>();
  const [configureMode, setConfigureMode] = useState(false);
  const [teams, setTeams] = useState<Team[]>([]);

  const { data, refetch, loading } = useQuery(GET_TEAMS_QUERY, {
    variables: { facilityId: selectedFacility },
    skip: !selectedFacility,
    onCompleted: (res) => {
      setTeams(res.facilityById?.teams || []);
    },
  });
  const [editTeamData, seteditTeamData] = useState<Team | undefined>();
  // eslint-disable-next-line prettier/prettier
  const [duplicateTeamData, setDuplicateTeamData] = useState<
    Team | undefined
  >();

  const closeSidebarTab = useSettingsStore((state) => state.closeSidebarTab);

  const setSearchFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    if (!isEmpty(teams) && !isEmpty(tagFilterValues)) {
      const filteredTeams = data?.facilityById?.teams.filter((team: Team) =>
        find(tagFilterValues, (tag) => team.tags.includes(tag)),
      );
      setTeams(filteredTeams);
    }
    if (isEmpty(tagFilterValues)) {
      setTeams(data?.facilityById?.teams || []);
    }
    if (!isEmpty(teams) && searchValue !== "") {
      const filteredTeams = data?.facilityById?.teams.filter((team: Team) =>
        team.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()),
      );
      setTeams(filteredTeams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.facilityById?.teams, tagFilterValues, searchValue]);

  const addNewTagToArray = (value: string) => {
    if (value !== "") {
      setTagFilterValues([...tagFilterValues, value]);
    }
  };

  const removeTag = (tag: string) => {
    setTagFilterValues(tagFilterValues.filter((e: string) => e !== tag));
  };

  const toggleConfigureTeamMode = () => {
    setConfigureMode(!configureMode);
  };

  const setEditData = (value: Team | undefined) => {
    seteditTeamData(value);
  };

  const handleSetTeams = (newState: Team) => {
    const existingPosition = teams.findIndex((t) => t.id === newState.id);

    if (existingPosition !== -1) {
      const allTeams = [...teams];
      allTeams[existingPosition] = newState;
      setTeams(allTeams);
    } else {
      setTeams([...teams, newState]);
    }

    // Wait a bit, so there is enough time for the new Team to be saved. (race condition)
    setTimeout(() => {
      refetch({ facilityId: selectedFacility });
    }, 2500);
  };

  const sortTeamsByKey = (key: string) => {
    switch (key) {
      case "a-z":
        setTeams(sortBy(teams, ["name"]));
        break;
      case "z-a":
        setTeams(sortBy(teams, ["name"]).reverse());
        break;
      case "LAST CREATED":
        setTeams(
          sortBy(teams, (e) => {
            return new Date(e.lastModifiedDate);
          }).reverse(),
        );
        break;
      case "LAST USED":
        setTeams(
          sortBy(teams, (e) => {
            return new Date(e.lastModifiedDate);
          }).reverse(),
        );
        break;
      case "PLANNED FOR":
        setTeams(
          sortBy(teams, (e) => {
            return new Date(e.startDate);
          }).reverse(),
        );
        break;
      default:
        setTeams(sortBy(teams, ["name"]));
    }
  };

  const setSortFilter = (filterValue: SelectSingleProps) => {
    setSortFilterValue(filterValue);
    sortTeamsByKey(filterValue.value);
  };

  const deleteTeam = (id: string) => {
    const newArr: typeof teams = [...teams];
    if (newArr.find((el: Team) => id === el.id)) {
      newArr.splice(
        newArr.findIndex((i: Team) => i.id === id),
        1,
      );
      setTeams(newArr);
    }
  };

  return (
    <div className={styles.teamBuilderWrapper}>
      {configureMode ? (
        <ConfigureTeam
          setTeams={handleSetTeams}
          toggleComponent={toggleConfigureTeamMode}
          teamsCount={teams.length}
          editTeamData={editTeamData}
          duplicateTeamData={duplicateTeamData}
          deleteTeam={deleteTeam}
          setEditData={setEditData}
          setDuplicateTeamData={setDuplicateTeamData}
        />
      ) : (
        <>
          <div className={styles.teamBuilderHeader}>
            <button type="button" onClick={() => closeSidebarTab()}>
              <img src={arrow} alt="arrow" />
              TEAM BUILDER
            </button>
            <button
              className={buttons.modalButton}
              type="button"
              onClick={() => {
                setEditData(undefined);
                setDuplicateTeamData(undefined);
                toggleConfigureTeamMode();
              }}
            >
              CREATE NEW TEAM
            </button>
          </div>
          <div className={styles.teamBuilderFilters}>
            <span className={textStyles.secondaryText}>Search for team</span>
            <TeamBuilderFilters
              searchValue={searchValue}
              tagFilterValues={tagFilterValues}
              setSearchFilter={setSearchFilter}
              setSortFilter={setSortFilter}
              setTagFilter={addNewTagToArray}
              sortFilter={sortFilter}
              removeTag={removeTag}
            />
          </div>
          {loading ? (
            <div className={styles.loader}>
              <ReactLoader />
            </div>
          ) : (
            <TeamsList
              duplicateTeam={setDuplicateTeamData}
              teams={teams}
              setEditData={setEditData}
              toggleComponent={toggleConfigureTeamMode}
              deleteTeam={deleteTeam}
            />
          )}
        </>
      )}
    </div>
  );
};
export default memo(TeamBuilder);
