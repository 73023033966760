/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Sentry from "@sentry/react";
import debounce from "lodash/debounce";
import isEqual from "lodash/isEqual";
import moment from "moment-timezone";
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import SentryFallback from "@/components/common/sentryFallback";
import Header from "@/components/header";
import SideBar from "@/components/sidebar";
import { Nullable } from "@/types";
import { useSettingsStore, useVisibleDays } from "@/zustand/useSettingsStore";

import { OnDraggableIntersectsBordersFn } from "./dragLayer";
import TimelineHeader from "./timelineHeader";
import TimelineTable from "./timelineTable";

import styles from "./index.module.scss";

const sidebarsWidth = 337;

const ELEMENTS_PER_PAGE = 16; // TODO: Make this state a setting in the UI

const TimelineComponent: FC = () => {
  const [elementsPerPage, setElementsPerPage] = useState(ELEMENTS_PER_PAGE);
  const [screenSize, setScreenSize] = useState(0);
  const [timeLine, setTimeline] = useState<string[]>([]);
  const elementWidth = useMemo(
    () => Math.floor((screenSize - sidebarsWidth) / elementsPerPage),
    [elementsPerPage, screenSize],
  );
  const selectedDate = useSettingsStore((state) => state.selectedDate);
  const visibleDays = useVisibleDays();
  const headerRef = useRef<{ element: Nullable<HTMLDivElement> }>({
    element: null,
  });
  const tableRef = useRef<{ element: Nullable<HTMLDivElement> }>({
    element: null,
  });

  const [tableBorders, setTableBorders] = useState({
    leftBorder: 0,
    rightBorder: 0,
    topBorder: 0,
    bottomBorder: 0,
  });

  const calculateTableSize = useCallback((rect: DOMRect, node: HTMLElement) => {
    const parent = node.parentNode as HTMLElement;
    const parentRect = parent.getBoundingClientRect();

    const leftBorder = Math.max(rect.left, parentRect.left);
    const rightBorder = leftBorder + Math.min(rect.width, parentRect.width);
    const topBorder = Math.max(rect.top, parentRect.top);
    const bottomBorder = Math.min(
      rect.top + rect.height,
      parentRect.top + parentRect.height,
    );

    const measurements = {
      leftBorder,
      rightBorder,
      topBorder,
      bottomBorder,
    };

    setTableBorders(measurements);
  }, []);

  const onResizing = (entries) => {
    const element = Array.isArray(entries) ? entries[0] : entries;

    calculateTableSize(
      element.target.getBoundingClientRect(),
      element.target as HTMLElement,
    );
  };
  // Debouncing fixes Chrome error "ResizeObserver - loop limit exceeded"
  const debounceOnResizing = debounce(onResizing, 40);
  const tableResizeObserver = useRef<{
    observer: ResizeObserver;
    cleanupFn: Nullable<() => void>;
  }>({
    observer: new ResizeObserver(debounceOnResizing),
    cleanupFn: null,
  });

  const changeTimelineWidth = useCallback((value: number) => {
    setScreenSize(value);
  }, []);

  useEffect(() => {
    if (headerRef.current.element && screenSize === 0) {
      setScreenSize(headerRef.current.element.offsetWidth);
    }

    const handler = () => {
      if (headerRef.current.element) {
        changeTimelineWidth(headerRef.current.element.offsetWidth || 0);
      }
    };

    window.addEventListener("resize", handler);

    return () => window.removeEventListener("resize", handler);
  }, [changeTimelineWidth, screenSize]);

  // test wheel horisontal scroll
  useEffect(() => {
    const el = headerRef.current.element;

    const onWheel = (e: any) => {
      if (e.deltaY === 0) {
        return;
      }
      el?.scrollTo({
        left: el.scrollLeft + e.deltaY,
      });
    };
    // Chrome gives me an error "Added non-passive event listener to a scroll-blocking 'wheel' event."
    // Making it passive. https://github.com/WICG/EventListenerOptions/blob/gh-pages/explainer.md
    el?.addEventListener("wheel", onWheel, { passive: true });
    return () => el?.removeEventListener("wheel", onWheel);
  }, [elementWidth]);

  // Initial horizontal scroll to shift the user to aprox the middle of the timeline.
  // TODO: This could be improved to actually put "now" in the middle of the screen
  useEffect(() => {
    const header = headerRef.current.element;
    const table = tableRef.current.element;

    header?.scrollTo({
      left: elementWidth * 8, //  + visibleDays * 24 * elementWidth,
    });
    table?.scrollTo({
      left: elementWidth * 8, // + visibleDays * 24 * elementWidth,
    });
  }, [elementWidth, selectedDate, tableBorders]);

  // Create time array for header
  useEffect(() => {
    const count = visibleDays * 48; // number of 30min columns visible in the timeline
    const time: string[] = [];
    const dateRangeStart = moment(selectedDate)
      // .subtract(visibleDays, "day")
      .startOf("day")
      .toDate();
    for (let i = 0; i < count; i += 1) {
      const dayIndex = Math.floor(i / 48);
      time.push(
        `${dayIndex}-${moment(dateRangeStart)
          .add(30 * i, "minutes")
          .format("HH:mm")}`,
      );
    }

    if (!isEqual(time, timeLine)) {
      setTimeline(time);
      setElementsPerPage(ELEMENTS_PER_PAGE);
    }
  }, [timeLine, selectedDate, visibleDays]);

  const scrollTable = useCallback((type: "table" | "header") => {
    if (tableRef.current.element && headerRef.current.element) {
      if (type !== "header") {
        headerRef.current.element.scrollLeft =
          tableRef.current.element.scrollLeft;
      } else {
        tableRef.current.element.scrollLeft =
          headerRef.current.element.scrollLeft;
      }
    }
  }, []);

  const headerRefFn = useCallback(
    (header) => {
      const headerScrollHandler = () => {
        scrollTable("header");
      };

      if (headerRef.current.element) {
        headerRef.current.element.removeEventListener(
          "scroll",
          headerScrollHandler,
        );
      }

      if (!header) {
        return;
      }

      headerRef.current.element = header;
      header.addEventListener("scroll", headerScrollHandler, { passive: true });
      // eslint-disable-next-line consistent-return
      return headerRef;
    },
    [scrollTable],
  );

  const tableRefFn: React.RefCallback<HTMLDivElement> = useCallback(
    (table) => {
      if (tableResizeObserver.current.cleanupFn) {
        tableResizeObserver.current.cleanupFn();
        tableResizeObserver.current.cleanupFn = null;
      }

      if (!table) {
        return;
      }

      const scrollHandler = () => {
        scrollTable("table");
      };

      // This is what allows to scroll the whole timeline, when scrolling the top timeline header (time axis is)
      table.addEventListener("scroll", scrollHandler, { passive: true });

      // David: I don't think this is needed, since resizing triggers scroll. Commenting out both, observe(), and unobserve()
      // tableResizeObserver.current.observer.observe(table);
      tableResizeObserver.current.cleanupFn = () => {
        // tableResizeObserver.current.observer.unobserve(table);
        table.removeEventListener("scroll", scrollHandler);
      };

      calculateTableSize(table.getBoundingClientRect(), table);
      tableRef.current.element = table;
    },
    [calculateTableSize, scrollTable],
  );

  const scrollTableDuringDnD: OnDraggableIntersectsBordersFn = useCallback(
    ({ horizontally, vertically }) => {
      if (!tableRef.current.element) {
        return;
      }

      if (horizontally) {
        tableRef.current.element.scrollBy({ left: horizontally });
      }

      if (vertically) {
        (tableRef.current.element.parentNode as HTMLElement).scrollBy({
          top: vertically,
        });
      }
    },
    [],
  );

  const dragLayerProps = useMemo(
    () => ({
      onDraggableIntersectsBorders: scrollTableDuringDnD,
      ...tableBorders,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [scrollTableDuringDnD, JSON.stringify(tableBorders)],
  );
  return (
    <>
      <Sentry.ErrorBoundary fallback={SentryFallback}>
        <Header />
      </Sentry.ErrorBoundary>
      <SideBar />
      <Sentry.ErrorBoundary fallback={SentryFallback}>
        <div className={styles.timelineWrapper}>
          <TimelineHeader
            elementWidth={elementWidth}
            timeLine={timeLine}
            ref={headerRefFn}
            headerRef={headerRef}
            visibleDays={visibleDays}
            selectedDate={selectedDate}
          />
          <DndProvider backend={HTML5Backend}>
            <TimelineTable
              elementWidth={elementWidth}
              timeLine={timeLine}
              ref={tableRefFn}
              dragLayerProps={dragLayerProps}
            />
          </DndProvider>
        </div>
      </Sentry.ErrorBoundary>
    </>
  );
};
export default TimelineComponent;
