/* eslint-disable @typescript-eslint/no-non-null-assertion */
import moment, { Moment } from "moment-timezone";
import React, { FC, memo, useState } from "react";

import DateAndTimeField from "@/components/common/formElements/dateAndTimeField";
import InputText from "@/components/common/formElements/inputText";
import DropZone from "@/components/timeline/sidePanel/dropzone";
import { validateStartBeforeStop } from "@/helpers/dateTimeValidator";
import shorePowerOffIcon from "@/style/img/icons/shorepower_off.svg";
import shorePowerOnIcon from "@/style/img/icons/shorepower_on.svg";
import { Vessel } from "@/types";
import {
  mutateVesselOrderedBy,
  mutateVesselPONumber,
} from "@/zustand/useOrderStore";
import { useUserData } from "@/zustand/useSettingsStore";

import {
  CREATE_VESSEL_NOTE,
  GET_VESSEL_NOTES,
  UPDATE_VESSEL_NOTE,
} from "./notes/notesQueriesAndMutations";
import OrderNotes, {
  NotesMutationParams,
  NotesQueryParams,
  NotesQueryPayloadIdentifier,
} from "./notes/orderNotes";

import styles from "./sidePanel.module.scss";
import formStyles from "@/components/common/formElements/customFormStyles.module.scss";
import textStyles from "@/style/textStyles.module.scss";

export type AllocatedVesselData = {
  plannedStartDate: string;
  plannedStopDate: string;
  poNumber: string;
  orderedBy: string;
};

type Props = {
  data: Vessel;
  setUpdatedVessel: (data: Partial<AllocatedVesselData>) => void;
};

const VesselSidePanel: FC<Props> = ({ data, setUpdatedVessel }) => {
  // Notice: We use the props' start and stop date as initial value for this local useState.
  // BUT if new updates come through props (other users doing changes to this vessel), we don't refresh them.
  const [startDate, setStartDate] = useState<Moment | null>(
    data.plannedStartDate ? moment(data.plannedStartDate) : null,
  );

  const [stopDate, setStopDate] = useState<Moment | null>(
    data.plannedStopDate ? moment(data.plannedStopDate) : null,
  );

  const [poNumber, setPoNumber] = useState<string>(data?.poNumber);
  const [orderedBy, setOrderedBy] = useState<string>(data?.orderedBy);

  const userData = useUserData();

  const handleSetPoNumber = (newValue: string) => {
    setPoNumber(newValue);
    setUpdatedVessel({ poNumber: newValue });
  };

  const savePoNumber = (e: any) => {
    const newPoNumber = e.target?.value ?? "";
    mutateVesselPONumber({
      id: data?.id,
      poNumber: newPoNumber,
      rentalAgreementNumber: data?.rentalAgreementNumber,
      rentalAgreementLine: data?.rentalAgreementLine,
    });
  };

  const handleSetOrderedBy = (newValue: string) => {
    setOrderedBy(newValue);
    setUpdatedVessel({ orderedBy: newValue });
  };

  const saveOrderedBy = (e: any) => {
    const newOrderedBy = e.target?.value ?? "";
    mutateVesselOrderedBy({
      issuedBy: userData.mail,
      orderedBy: newOrderedBy,
      rentalAgreementId: data?.rentalAgreementNumber,
      rentalAgreementLineId: data?.rentalAgreementLine,
    });
  };

  const handleSetStartDate = (newValue: Moment | null) => {
    setStartDate(newValue);
    // We don't validate here, because "valid" is a shared concept between 2 fields
    // Maybe the just-edited start date is invalid, but user might change end date after that. Now start is valid
    // const valid = validateStartBeforeStop(newValue, stopDate);
    setUpdatedVessel({ plannedStartDate: newValue?.toISOString() || "" });
  };

  const handleSetStopDate = (newValue: Moment | null) => {
    setStopDate(newValue);
    setUpdatedVessel({ plannedStopDate: newValue?.toISOString() || "" });
  };

  const isStartBeforeStop = validateStartBeforeStop(startDate, stopDate);
  return (
    <div>
      <div className={styles.infoBlock}>
        <div className={styles.fieldNames}>
          <span className={textStyles.secondaryText}>Agreement:</span>
          <span className={textStyles.secondaryText}>IMO:</span>
          <span className={textStyles.secondaryText}>Purchase Order:</span>
          <span className={textStyles.secondaryText}>Ordered by:</span>
          <span className={textStyles.secondaryText}>Order date:</span>
          <span className={`${textStyles.secondaryText} ${styles.taller}`}>
            Planned start:
          </span>
          <span className={`${textStyles.secondaryText} ${styles.taller}`}>
            Planned stop:
          </span>
          <span className={textStyles.secondaryText}>Actual start:</span>
          <span className={textStyles.secondaryText}>Actual stop:</span>
          <span className={textStyles.secondaryText}>Shore Power:</span>
        </div>
        <div className={styles.fieldInfo}>
          <span className={textStyles.primaryText}>
            {data.rentalAgreementNumber && data.rentalAgreementNumber
              ? `${data.rentalAgreementNumber} / ${data.rentalAgreementLine}`
              : "N/A"}
          </span>
          <span className={textStyles.primaryText}>
            {data.vesselImo || "N/A"}
          </span>
          <span
            className={`${textStyles.primaryText} ${formStyles.compactInput}`}
          >
            <InputText
              value={poNumber}
              setValue={handleSetPoNumber}
              onBlur={savePoNumber}
              placeholder="N/A"
              error={false}
              errorMessage=""
            />
          </span>
          <span className={textStyles.primaryText}>
            <InputText
              value={orderedBy}
              setValue={handleSetOrderedBy}
              onBlur={saveOrderedBy}
              placeholder="N/A"
              error={false}
              errorMessage=""
            />
          </span>
          <span className={textStyles.primaryText}>
            {moment(data.plannedStartDate).format("DD.MM.YYYY")}
          </span>

          <DateAndTimeField
            className={styles.nsgDatetimePicker}
            dateValue={startDate}
            handleChange={handleSetStartDate}
            errorJSX={isStartBeforeStop ? undefined : ""}
          />
          <DateAndTimeField
            className={styles.nsgDatetimePicker}
            dateValue={stopDate}
            handleChange={handleSetStopDate}
            errorJSX={isStartBeforeStop ? undefined : ""}
          />

          <span className={textStyles.primaryText}>
            {data.startDate
              ? moment(data.startDate).format("DD.MM.YYYY, HH:mm")
              : "N/A"}
          </span>
          <span className={textStyles.primaryText}>
            {data.stopDate
              ? moment(data.stopDate).format("DD.MM.YYYY, HH:mm")
              : "N/A"}
          </span>
          <span className={textStyles.primaryText}>
            <img
              className="collapse-toggle"
              style={{
                verticalAlign: "middle",
                marginRight: "4px",
                width: "20px",
                height: "20px",
              }}
              src={data?.shorePowerUsage ? shorePowerOnIcon : shorePowerOffIcon}
              alt="ShorePower status"
              title={
                data?.shorePowerUsage
                  ? "Connected to ShorePower"
                  : "Not connected to ShorePower"
              }
            />
            {data.shorePowerUsage ? "connected" : "not connected"}
          </span>
        </div>
      </div>
      <div className={styles.infoBlock}>
        {!isStartBeforeStop && (
          <p className="error-message">
            Check that the start date is later than the end date.
            <br /> Give it another try!
          </p>
        )}
      </div>
      <OrderNotes
        title="VESSEL Notes"
        noteParentId={data.id}
        mutationParams={
          {
            create: CREATE_VESSEL_NOTE,
            update: UPDATE_VESSEL_NOTE,
            createParam: "vesselId",
            updateParam: "vesselNoteId",
          } as NotesMutationParams
        }
        queryParams={
          {
            query: GET_VESSEL_NOTES,
            param: "vesselId",
            payloadIdentifier: {
              identifier1: "vesselActivity",
              identifier2: "vesselNotes",
            } as NotesQueryPayloadIdentifier,
          } as NotesQueryParams
        }
      />
      <div className={styles.attachments}>
        <h3 className={`${textStyles.secondaryText} ${styles.sectionHeader}`}>
          Attachments:
        </h3>
        <DropZone
          attachments={data?.attachments}
          orderType="vessel"
          rentLine={data?.rentalAgreementLine}
          rentLineNumber={data?.rentalAgreementNumber}
        />
      </div>
    </div>
  );
};
export default memo(VesselSidePanel);
