/* eslint-disable @typescript-eslint/no-unused-vars */
import { gql, useMutation, useQuery } from "@apollo/client";
import moment, { Moment } from "moment-timezone";
import React, { FC, memo, useMemo, useState } from "react";

import AutoCompleteInput from "@/components/common/formElements/autocompleteInput";
import DropZone, { uploadFile } from "@/components/timeline/sidePanel/dropzone";
import LocalNotes, {
  GhostActivityNote,
} from "@/components/timeline/sidePanel/notes/ghostNotes";
import { captureError } from "@/helpers/captureError";
import { REGISTER_GHOST_ACTIVITY_ATTACHMENT } from "@/hooks/mutation/useRegisterAttachment";
import useUpsertGhostActivityWithNotes from "@/hooks/mutation/useUpsertGhostActivityWithNotes";
import { SelectSingleProps } from "@/types";
import { useSettingsStore, useUserData } from "@/zustand/useSettingsStore";
import { useSnackbar } from "@/zustand/useSnackbar";

import DateAndTimeField from "../formElements/dateAndTimeField";
import CustomSelect from "../formElements/reactSelect";
import { SEARCH_CUSTOMERS, SEARCH_NAMES } from "./ghostQueries";

import styles from "./index.module.scss";
import buttons from "@/style/buttons.module.scss";
import textStyles from "@/style/textStyles.module.scss";

const REQUIRED_ERROR_MESSAGE = "We need your input here.";

const GhostActivityForm: FC = () => {
  const selectedFacility = useSettingsStore((state) => state.selectedFacility);
  const allRows = useSettingsStore((state) => state.allRows);
  const { setUpdate: saveGhostActivity } = useUpsertGhostActivityWithNotes();

  const setSnack = useSnackbar((state) => state.setSnack);
  const userData = useUserData();
  const closeSidebarTab = useSettingsStore((state) => state.closeSidebarTab);
  const [registerGhostActivityAttachment] = useMutation(
    REGISTER_GHOST_ACTIVITY_ATTACHMENT,
  );

  const [customerName, setCustomerName] = useState("");
  const [vesselName, setVesselName] = useState("");
  const [notes, setNotes] = useState<GhostActivityNote[]>([]);
  const [tempFiles, setTempFiles] = useState<File[]>([]);

  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [stopDate, setStopDate] = useState<Moment | null>(null);

  const { data } = useQuery(SEARCH_CUSTOMERS, {
    variables: { searchTerm: customerName, facilityId: selectedFacility },
    skip: customerName === "",
  });

  const autocompleteNames = useQuery(SEARCH_NAMES, {
    variables: { searchTerm: vesselName },
    skip: vesselName === "",
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [errors, setErrors] = useState<any>({});

  const options = allRows.map((el) => {
    return { value: el.id, label: el.name };
  });

  const [selectedActivity, setSelectedActivity] = useState<SelectSingleProps>();

  const isCorrectDate = moment(startDate).isBefore(moment(stopDate));

  const isButtonDisabled =
    customerName === "" ||
    vesselName === "" ||
    !startDate ||
    !stopDate ||
    !selectedActivity ||
    !isCorrectDate;

  const handleFormErrors = (e: React.FocusEvent | FocusEvent) => {
    e.preventDefault();
    const eventTargetId = e.target ? (e.target as HTMLFormElement).id : e.type;
    if (eventTargetId === "Customer Name" && customerName === "") {
      setErrors({ ...errors, customerName: true });
    }
    if (eventTargetId === "Vessel/Order Name" && vesselName === "") {
      setErrors({ ...errors, vesselName: true });
    }
    if (eventTargetId === "Planned Start" && !startDate) {
      setErrors({ ...errors, startDate: true });
    }
    if (eventTargetId === "Planned Stop" && !stopDate) {
      setErrors({ ...errors, endDate: true });
    }
    if (eventTargetId === "SELECT ACTIVITY" && !selectedActivity) {
      setErrors({ ...errors, selectedActivity: true });
    }
  };

  const handleInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    const id =
      e.type === "change"
        ? (e.target as HTMLElement).id
        : (e.target as HTMLButtonElement).parentElement?.id;

    const value =
      e.type === "change"
        ? (e.target as HTMLInputElement).value
        : (e.target as HTMLButtonElement).innerHTML;

    if (id === "Customer Name") {
      setErrors({ ...errors, customerName: false });
      setCustomerName(value);
    }
    if (id === "Vessel/Order Name") {
      setErrors({ ...errors, vesselName: false });
      setVesselName(value);
    }
  };

  const handleDropdownChange = (newValue: SelectSingleProps) => {
    setSelectedActivity(newValue);
    setErrors({ ...errors, selectedActivity: false });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const registerAttachment = async (orderType: any, variables: any) => {
    await registerGhostActivityAttachment({ variables });
  };

  const handleSumbit = async () => {
    try {
      const ghostResponse = await saveGhostActivity({
        customerName,
        name: vesselName,
        plannedStart: startDate?.toISOString() ?? "",
        plannedStop: stopDate?.toISOString() ?? "",
        reserve: selectedActivity?.value ?? "",
        ghostActivityNotes: notes,
      });

      // eslint-disable-next-line no-restricted-syntax
      for (const file of tempFiles) {
        try {
          // eslint-disable-next-line no-await-in-loop
          await uploadFile(file, {
            orderType: "ghostactivity",
            ghostActivityId: ghostResponse.data.upsertGhostActivityWithNotes,
            facilityId: selectedFacility,
            issuedBy: userData.mail,
            registerAttachment,
          });
        } catch (e) {
          setSnack(
            true,
            "Something went wrong uploading the file. Please try again!",
            true,
            "error",
          );
        }
      }
      setSnack(true, "Ghost activity successfully created!");
      closeSidebarTab();
    } catch (error) {
      setSnack(
        true,
        "Something went wrong creating the ghost order.",
        true,
        "error",
      );
      closeSidebarTab();
      captureError(error);

      console.error("attachments->", error);
    }
  };

  const uniqueCustomerNames = new Map();
  const filteredCustomerNames = (data?.searchRentalAgreements || []).filter(
    (item) => {
      if (uniqueCustomerNames.get(item.agreementCustomer)) {
        return false;
      }
      uniqueCustomerNames.set(item.agreementCustomer, true);
      return true;
    },
  );

  return (
    <form className={styles.ghostActivityForm}>
      <div className={styles.textInputBlock}>
        <span className={textStyles.secondaryText}>
          <span className="error-message">*</span> Customer name:
        </span>
        <AutoCompleteInput
          value={customerName}
          setValue={(e) => handleInputChange(e)}
          placeholder="Customer Name"
          error={!!errors.customerName}
          errorMessage={REQUIRED_ERROR_MESSAGE}
          onBlur={(e) => handleFormErrors(e)}
          autoCompleteValues={filteredCustomerNames || []}
        />
      </div>
      <div className={styles.textInputBlock}>
        <span className={textStyles.secondaryText}>
          <span className="error-message">*</span> Vessel/Work Order Name:
        </span>
        <AutoCompleteInput
          value={vesselName}
          setValue={(e) => handleInputChange(e)}
          placeholder="Vessel/Order Name"
          error={!!errors.vesselName}
          errorMessage={REQUIRED_ERROR_MESSAGE}
          onBlur={(e) => handleFormErrors(e)}
          autoCompleteValues={autocompleteNames.data?.searchVessels || []}
        />
      </div>
      <div className={styles.dateInputBlock}>
        <span className={textStyles.secondaryText}>
          <span className="error-message">*</span> Planned Start:
        </span>
        <div>
          <DateAndTimeField
            dateValue={startDate}
            handleChange={(newValue: Moment | null) => {
              if (!newValue) {
                setErrors({ ...errors, startDate: true });
              } else {
                setErrors({ ...errors, startDate: false });
              }
              setStartDate(newValue);
            }}
            errorJSX={
              // eslint-disable-next-line no-nested-ternary
              errors?.startDate ? (
                <p className="error-message">{REQUIRED_ERROR_MESSAGE}</p>
              ) : !startDate || !stopDate || isCorrectDate ? undefined : (
                ""
              )
            }
          />
        </div>
      </div>
      <div className={styles.dateInputBlock}>
        <span className={textStyles.secondaryText}>
          <span className="error-message">*</span> Planned Stop:
        </span>
        <div>
          <DateAndTimeField
            dateValue={stopDate}
            handleChange={(newValue: Moment | null) => {
              if (!newValue) {
                setErrors({ ...errors, endDate: true });
              } else {
                setErrors({ ...errors, endDate: false });
              }
              setStopDate(newValue);
            }}
            errorJSX={
              // eslint-disable-next-line no-nested-ternary
              errors?.endDate ? (
                <p className="error-message">{REQUIRED_ERROR_MESSAGE}</p>
              ) : !startDate || !stopDate || isCorrectDate ? undefined : (
                ""
              )
            }
          />
        </div>
      </div>
      <div className={styles.textInputBlock}>
        <span className={textStyles.secondaryText}>
          <span className="error-message">*</span> Reserve:
        </span>
        <div style={{ minHeight: "51px" }}>
          <CustomSelect
            value={selectedActivity}
            onChange={(value) =>
              handleDropdownChange(value as SelectSingleProps)
            }
            placeholder="SELECT ACTIVITY"
            options={options}
            onBlur={(e) => handleFormErrors(e)}
            error={!!errors.selectedActivity}
            errorMessage={REQUIRED_ERROR_MESSAGE}
          />
        </div>
      </div>
      {!isCorrectDate &&
        startDate &&
        stopDate &&
        !errors?.startDate &&
        !errors?.stopDate && (
          <p className="error-message">
            Check that the start date is later than the end date.
            <br /> Give it another try!
          </p>
        )}
      <div className={styles.notes}>
        <LocalNotes
          title="Handover notes:"
          notes={notes}
          setNotes={(n: typeof notes) => setNotes(n)}
        />
      </div>
      <span className={textStyles.secondaryText}>Attachments:</span>
      <DropZone
        orderType="ghostactivity"
        setTempFiles={(att: File[]) => setTempFiles(att)}
      />
      <button
        className={buttons.modalButton}
        type="button"
        title={
          isButtonDisabled
            ? "Add content to all fields marked with * to add this order to the Planning board"
            : "This will add a ghost order to the Planning Board"
        }
        disabled={isButtonDisabled}
        onClick={handleSumbit}
        style={{ cursor: isButtonDisabled ? "not-allowed" : "pointer" }}
      >
        Add to planning board
      </button>
    </form>
  );
};
export default memo(GhostActivityForm);
