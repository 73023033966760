import { LinearProgress } from "@mui/material";
import React, { memo } from "react";

import { useSettingsStore } from "@/zustand/useSettingsStore";

const TimelineLoadingProgress: React.FC = () => {
  const isFetching = useSettingsStore((state) => state.isFetching);
  return isFetching ? <LinearProgress /> : null;
};

export default memo(TimelineLoadingProgress);
