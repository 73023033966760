import { Moment } from "moment-timezone";
import React, { FC, memo, useState } from "react";

import DateAndTimeField from "@/components/common/formElements/dateAndTimeField";

import styles from "./index.module.scss";
import otherStyles from "@/components/timeline/sidePanel/sidePanel.module.scss";
import textStyles from "@/style/textStyles.module.scss";

type Props = {
  handleStartWorkOrderLine: (startDate: string) => void;
  handleStopWorkOrderLine: (startDate: string) => void;
};

const OperationLineTime: FC<Props> = ({
  handleStartWorkOrderLine,
  handleStopWorkOrderLine,
}) => {
  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [stopDate, setStopDate] = useState<Moment | null>(null);

  const handleSetStartDate = (date: Moment | null) => {
    setStartDate(date);
  };
  const handleSetStopDate = (date: Moment | null) => {
    setStopDate(date);
  };

  // There is no reason to validate it this way, because you might be editing previous timestamps (One line can have multiple start/stop times)
  // const isCorrectDate = useMemo(() => {
  //   if (startDate && stopDate) {
  //     return validateStartBeforeStop(startDate, stopDate);
  //   }
  //   if (!startDate || !stopDate) {
  //     return true;
  //   }
  //   return false;
  // }, [startDate, stopDate]);

  const handleStart = () => {
    if (startDate) {
      handleStartWorkOrderLine(startDate.toISOString());
    }
  };

  const handleStop = () => {
    if (stopDate) {
      handleStopWorkOrderLine(stopDate.toISOString());
    }
  };

  /* {!isCorrectDate && (
        <p className="error-message">
          The start date cannot be later than the end date
        </p>
      )} */

  return (
    <div className={`${styles.operationLineTime} flex-start`}>
      <div className="flex-center">
        <DateAndTimeField
          className={otherStyles.nsgDatetimePicker}
          dateValue={startDate}
          handleChange={handleSetStartDate}
        />
        <button
          type="button"
          className={`${styles.timeLabel} ${styles.timeLabelStart}`}
          title="Start selected"
          onClick={handleStart}
        >
          <span className={textStyles.secondaryText}>START</span>
        </button>
      </div>
      <div className="flex-center">
        <DateAndTimeField
          className={otherStyles.nsgDatetimePicker}
          dateValue={stopDate}
          handleChange={handleSetStopDate}
        />
        <button
          type="button"
          className={`${styles.timeLabel} ${styles.timeLabelStop}`}
          title="Stop selected"
          onClick={handleStop}
        >
          <span className={textStyles.secondaryText}>STOP</span>
        </button>
      </div>
    </div>
  );
};

export default memo(OperationLineTime);
