import { gql, useMutation } from "@apollo/client";
import React, { FC, memo, useState } from "react";

import PopUp from "@/components/common/formElements/popUp";
import dots from "@/style/img/icons/dots.svg";
import { WorkOrder } from "@/types";
import { useSettingsStore, useUserData } from "@/zustand/useSettingsStore";

import styles from "../index.module.scss";

const RELEASE_WO = gql`
  mutation releaseWorkOrder(
    $facilityId: String!
    $issuedBy: String!
    $workOrderId: String!
  ) {
    releaseWorkOrder(
      facilityId: $facilityId
      issuedBy: $issuedBy
      workOrderId: $workOrderId
    )
  }
`;

type Props = {
  order: WorkOrder;
  withGhostLink?: boolean;
};

const WorkOrderActionsPopUp: FC<Props> = ({ order, withGhostLink = false }) => {
  const userData = useUserData();
  const selectedFacility = useSettingsStore((state) => state.selectedFacility);

  const [releaseWorkOrder] = useMutation(RELEASE_WO);
  const [isActionsOpen, setActionsOpen] = useState(false);

  const setSuperLinking = useSettingsStore((state) => state.setSuperLinking);

  const handleRelease = async () => {
    await releaseWorkOrder({
      variables: {
        facilityId: selectedFacility,
        issuedBy: userData.mail,
        workOrderId: order.id,
      },
    });
  };

  return (
    <PopUp
      trigger={
        <section>
          <img src={dots} alt="settings" />
        </section>
      }
      onClose={() => setActionsOpen(false)}
      onOpen={() => setActionsOpen(true)}
      isOpen={isActionsOpen}
      content={
        <div className={`${styles.actionsPopUp} popUp-wrapper`}>
          {withGhostLink && (
            <button
              type="button"
              className={styles.optionButton}
              onClick={() => {
                setActionsOpen(false);
                setSuperLinking({
                  tooltipText:
                    "Click on the ghost activity to link this work order.",
                  orderId: order.id,
                  type: "wo",
                });
              }}
            >
              LINK ORDER TO GHOST ACTIVITY
            </button>
          )}
          <button
            type="button"
            className={styles.optionButton}
            onClick={() => {
              setActionsOpen(false);
              handleRelease();
            }}
            disabled={parseInt(order.status, 10) !== 10}
          >
            RELEASE WORK ORDER
          </button>
        </div>
      }
    />
  );
};
export default memo(WorkOrderActionsPopUp);
