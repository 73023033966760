/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import moment, { Moment } from "moment-timezone";

import { Vessel } from "@/types";

moment?.tz?.setDefault("Europe/Oslo");

export const normalizeTimeSlotDef = (timeSlotDef: string) => {
  const [hours, minutes] = timeSlotDef
    .trim()
    .split(":")
    .map((value) => parseInt(value, 10));

  const createTimeSlotDef = (
    hoursValue: number | string,
    minutesValue: number | string,
  ) => {
    const normalizedHours = String(hoursValue).padStart(2, "0");
    const normalizedMinutes = String(minutesValue).padStart(2, "0");

    return `${normalizedHours}:${normalizedMinutes}`;
  };

  if (minutes < 30) {
    // start on the current hour
    return createTimeSlotDef(hours, 0);
  }

  // more than 30 minutes but less than 60 minutes
  if (minutes < 60) {
    return createTimeSlotDef(hours, 30);
  }

  // start of the next hour
  return createTimeSlotDef(hours + 1, 0);
};

// Relative to the range of days user is seeing (depends on visibleDays)
// It can return 0 (yesterday),1 (today), 2 (tomorrow) for visibleDays set to 1, for example
export const getDiffInDays = (
  selectedDate: Date,
  plannedStartDate: string | undefined,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  visibleDays: number,
) => {
  const vesselStart = moment(plannedStartDate);

  const dateRangeStart = moment(selectedDate).startOf("day");

  const duration = moment.duration(vesselStart.diff(moment(dateRangeStart)));

  return duration.asDays();
};

// This function seems to exists because we can't trust the plannedStopDate.
// why? because normalized orders are artificially trimmed to end at midnight (so it fits in the timeline)
// This is a clever way of calculating the new StopDate
export const calculateNewTimeBoundsForOrderBasedOnStartTime = (params: {
  newStart: Moment;
  timeBoundOrder: Pick<Vessel, "plannedStartDate" | "plannedStopDate">;
}) => {
  const orderDurationInMinutes = Math.abs(
    moment(params.timeBoundOrder.plannedStartDate).diff(
      moment(params.timeBoundOrder.plannedStopDate),
      "milliseconds",
    ),
  );
  params.newStart.set({
    second: 0,
  });
  const newStartDate = params.newStart.toISOString();
  const newStopDate = params.newStart
    .clone()
    .add(orderDurationInMinutes, "milliseconds")
    .toISOString();

  return { newStartDate, newStopDate };
};
