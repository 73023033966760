import React, { FC, memo, useEffect, useState } from "react";

import st from "./customFormStyles.module.scss";

type Props = {
  value: string;
  setValue: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item?: any,
  ) => void;
  placeholder: string;
  error: boolean;
  errorMessage: string;
  onBlur?: (e: React.FocusEvent) => void;
  onClick?: () => void;
  autoCompleteValues: {
    id: string;
    name: string;
    __typename: string;
  }[];
};
const AutoCompleteInput: FC<Props> = ({
  placeholder,
  value,
  setValue,
  errorMessage,
  error,
  onBlur = () => null,
  onClick = () => null,
  autoCompleteValues,
}) => {
  const [isElementsVisible, setElementsVisible] = useState(false);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      const target = event?.currentTarget as HTMLElement;
      if (
        typeof target?.className === "string" &&
        typeof target?.className?.includes === "function" &&
        !target.className?.includes("autocomplete-element")
      ) {
        setElementsVisible(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={`${st.inputBlock} ${st.autocompleteInput}`}>
      <input
        type="text"
        id={placeholder}
        autoComplete="off"
        placeholder={placeholder}
        value={value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue(e)}
        onFocus={() => {
          onClick();
          setElementsVisible(true);
        }}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onBlur={onBlur ? (e) => onBlur(e) : () => {}}
        className={st.customInput}
        style={error ? { border: "1px solid #BB0000" } : {}}
      />
      {autoCompleteValues.length && value !== "" ? (
        <div
          style={isElementsVisible ? { display: "block" } : {}}
          className={st.autoCompleteValues}
          id={placeholder}
        >
          {autoCompleteValues.map((el) => (
            <button
              className="autocomplete-element"
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                setValue(e, el);
                setElementsVisible(false);
              }}
              type="button"
              key={el.id}
            >
              {el.name}
            </button>
          ))}
        </div>
      ) : null}
      {error && <p className="error-message">{errorMessage}</p>}
    </div>
  );
};
export default memo(AutoCompleteInput);
