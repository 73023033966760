import { ApolloProvider } from "@apollo/client";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import * as Sentry from "@sentry/browser";
import { excludeGraphQLFetch } from "apollo-link-sentry";
import moment from "moment-timezone"; // Importing this way solves a typescript issue https://github.com/moment/moment-timezone/issues/906
import React, { FC, useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import AppVersionCheck from "@/components/common/appVersionCheck";
import { captureMessage } from "@/helpers/captureError";
import { redirectToLogin } from "@/utils/auth/authUtils";
import useKeepSessionAlive from "@/utils/auth/useKeepSessionAlive";
import { createApolloClient } from "@/utils/createApolloClient";
import { useSettingsStore } from "@/zustand/useSettingsStore";

import MainRouter from "../routes";
import SignIn from "./signIn";

const env = window.DOLITTLE_ENVIRONMENT;

if (env === "test" || env === "prod") {
  // See documentation/Sentry.md for more info
  Sentry.init({
    environment: env, // "local", "test" or "prod". (DEV doesn't have setup this var still)
    release: `nsg-react-${window.PB_RELEASE_SHA}`,
    dsn: "https://836b14c5fb154700a56dde871b4f2fad@o4504790414065664.ingest.sentry.io/4504790415310848",
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 1.0, // default 0.1,
    replaysOnErrorSampleRate: 1.0,
    beforeBreadcrumb: excludeGraphQLFetch,
  });
}

declare global {
  interface Window {
    PB_GATEWAY_URL: string;
    BEARER: string;
    DEBUG_WS: boolean;
    DEBUG_MERGE: boolean;
    DEBUG_AUTH: number;
    CURRENT_USER_EMAIL: string | undefined;
    BYPASS_MUTATION_PROTECTION: boolean;
    DOLITTLE_ENVIRONMENT: string;
    PB_RELEASE_SHA: string;
    PB_BACKEND_URL: string;
  }
}
// Set the default Time zone for the app. See README
moment?.tz?.setDefault("Europe/Oslo");

// Set Monday as first day of the week, not Sunday. (This is visible in theMUI date picker)
moment.updateLocale("en", {
  week: {
    dow: 1,
  },
});

// Root component
const App: FC = () => {
  const gatewayUrl = window.PB_GATEWAY_URL;
  const { instance, inProgress, accounts } = useMsal();
  const setUserData = useSettingsStore((state) => state.setUserData);
  const [activeToken, setActiveToken] = useState<string>("");

  useKeepSessionAlive(instance, activeToken, setActiveToken, accounts);
  const account = accounts[0];

  // It gets executed for *every* GraphQL query
  const tokenValidityCheck = async () => {
    // If user is authenticated, get a token:

    if (account && inProgress === InteractionStatus.None) {
      try {
        // acquireTokenSilent reads token from cache
        const response = await instance.acquireTokenSilent({
          scopes: ["User.Read"],
          account,
        });
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        setUserData({
          displayName: account?.name ?? "",
          mail: account?.username ?? "",
        });

        window.CURRENT_USER_EMAIL = account?.username ?? "";
        window.BEARER = response.idToken;
        setActiveToken(response.idToken);
      } catch (err) {
        if (err instanceof InteractionRequiredAuthError) {
          captureMessage(
            "Acquire token silent failure (tokenValidityCheck), redirecting to login",
          ); // Expected behavior, but we want to see how often happens
          redirectToLogin(instance);
        }
      }
    } else if (!account && inProgress === InteractionStatus.None) {
      redirectToLogin(instance);
    }
  };

  let apolloClient;
  if (account && inProgress === InteractionStatus.None) {
    if (!activeToken) {
      tokenValidityCheck();
    } else {
      apolloClient = createApolloClient(gatewayUrl, tokenValidityCheck);
    }
  }

  const highContrastMode = useSettingsStore((state) => state.highContrastMode);
  useEffect(() => {
    document.body.className = `theme-${
      highContrastMode ? "high-contrast-colors" : "normal-colors"
    }`;
  }, [highContrastMode]);

  // We make sure to initialize apollo client only when ready for performance (300ms faster), and also
  // because multiple re-renders of ApolloProvider breaks the Apollo Devtools extension: https://github.com/apollographql/apollo-client-devtools/issues/822#issuecomment-1315765497
  if (apolloClient) {
    return (
      <ApolloProvider client={apolloClient}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <AppVersionCheck />
          <Router>
            <MainRouter />
          </Router>
        </LocalizationProvider>
      </ApolloProvider>
    );
  }
  // This check is basically the same than msal's isAuthenticated.
  if (account) {
    return <div>Authenticating ...</div>;
  }

  return <SignIn />;
};

export default App;
