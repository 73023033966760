/* eslint-disable @typescript-eslint/no-explicit-any */
import { gql, useMutation } from "@apollo/client";
import omit from "lodash/omit";
import React, {
  CSSProperties,
  forwardRef,
  memo,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";

import DialogModal from "@/components/common/dialogModal";
import GhostSVG from "@/components/sidebar/svgElements/ghost";
import DeleteVesselModal from "@/components/timeline/allocations/modalsContent/deleteVesselModal";
import OrderActions from "@/components/timeline/allocations/vesselActionsPopUp";
import { LOADING_STYLE } from "@/helpers/constants";
import { useDraggableItem } from "@/hooks/ui/timeline/useDraggableItem";
import { useNeedToMakeItemSecondary } from "@/hooks/ui/timeline/useNeedToMakeItemSecondary";
import { useOrderSize } from "@/hooks/ui/timeline/useOrderSize";
import {
  DraggableItemProps,
  DraggableItemType,
  OnDraggableSideDropFn,
} from "@/types";
import { useInProgressStore } from "@/zustand/useInProgressStore";
import {
  DEFAULT_ALLOCATION_HEIGHT,
  setSidePanelOrder,
  useOrderStore,
} from "@/zustand/useOrderStore";
import { useSettingsStore, useUserData } from "@/zustand/useSettingsStore";
import { SuperLinkOrder, SuperLinkTeam } from "@/zustand/zustand-types";

import CircularLoading from "../../common/circularLoading";
import OrderCardDraggableSides from "../draggableSide/orderCardDraggableSides";
import { isInsideModal } from "../helpers/isInsideModal";

import globalStyles from "../index.module.scss";
import styles from "./ghostAllocation.module.scss";

const LINK_VESSEL_TO_GHOST = gql`
  mutation linkVesselToGhostActivity(
    $facilityId: String!
    $issuedBy: String!
    $vesselId: String!
    $ghostVesselId: UUID!
  ) {
    linkVesselToGhostActivity(
      facilityId: $facilityId
      issuedBy: $issuedBy
      vesselId: $vesselId
      ghostVesselId: $ghostVesselId
    )
  }
`;
const LINK_WORK_ORDER_TO_GHOST = gql`
  mutation linkWorkOrderToGhostActivity(
    $facilityId: String!
    $issuedBy: String!
    $workOrderId: String!
    $ghostWorkOrderId: UUID!
  ) {
    linkWorkOrderToGhostActivity(
      facilityId: $facilityId
      issuedBy: $issuedBy
      workOrderId: $workOrderId
      ghostWorkOrderId: $ghostWorkOrderId
    )
  }
`;

type Props = {
  activity: any;
  elementWidth: number;
  position: number;
  selectedDate: Date;
  visibleDays: number;
  timeLine: string[];
  onPullEnd: OnDraggableSideDropFn;
};

const draggableItemType = DraggableItemType.GhostOrder;

const GhostAllocation = forwardRef<HTMLInputElement, Props>(
  (
    {
      activity,
      elementWidth,
      position,
      selectedDate,
      visibleDays,
      timeLine,
      onPullEnd,
    },
    ref,
  ) => {
    const vesselChangeInProgress = useInProgressStore(
      (state) => state.vesselChangeInProgress,
    );
    const [isModalDeleteVessel, setIsModalDeleteVessel] = useState(false);
    const orderInfo = useRef<any>(null);
    const [linkVesselToGhostActivity] = useMutation(LINK_VESSEL_TO_GHOST);
    const [linkWorkOrderToGhostActivity] = useMutation(
      LINK_WORK_ORDER_TO_GHOST,
    );

    const sidePanelOrder = useOrderStore((state) => state.sidePanelOrder);
    const superLinking = useSettingsStore((state) => state.superLinking);
    const disableSuperLinking = useSettingsStore(
      (state) => state.disableSuperLinking,
    );

    const { visibleStartDate } = useSettingsStore(
      (state) => state.visibleDateRange,
    );

    const inDetailedView = useSettingsStore((state) => state.inDetailedView);

    const userData = useUserData();
    const selectedFacility = useSettingsStore(
      (state) => state.selectedFacility,
    );

    const needToMakeItemSecondary = useNeedToMakeItemSecondary({
      order: activity,
    });

    const isLoading = vesselChangeInProgress.some(
      (each) => each.id === activity.id,
    );

    const draggableProps: DraggableItemProps = {
      canDrag: true,
      quayId: activity.reserve,
      excludeDragScopes: ["not allocated"],
    };

    const [{ isDragging }, drag] = useDraggableItem({
      type: draggableItemType,
      timeLine,
      orderId: activity.id,
      ...draggableProps,
    });

    const { width } = useOrderSize({
      order: activity,
      selectedDate,
      visibleDays,
      elementWidth,
    });

    const orderStyles = useMemo(() => {
      let finalWidth = Math.max(width, elementWidth * 3); // elementWidth*3, why 3 (3 hours)? Because it's min width of 3 hours we declared in intervalGraphColoring.
      // Calculations in minutes
      const startDiff = -visibleStartDate.diff(
        activity.plannedStartDate,
        "minutes",
      );
      const shift = startDiff > 0 ? startDiff : 0;
      // To pixels:
      const left = shift * (elementWidth / 60);
      const timelineContainerWidth = (elementWidth / 2) * 48 * 2; // TODO: don't hardcode
      if (left + width > timelineContainerWidth) {
        finalWidth = timelineContainerWidth - left; // To prevent DetailedView allocations to overflow the right edge of the timeline
      }
      return {
        width: finalWidth || 0,
        height: activity?.allocHeight,
        top: activity?.visualTop,
        left,
        borderWidth: sidePanelOrder?.id === activity.id ? "4px" : "2px",
        boxShadow:
          sidePanelOrder?.id === activity.id
            ? "0px 8px 10px rgba(90, 90, 90, 0.14), 0px 3px 14px rgba(90, 90, 90, 0.12), 0px 4px 5px rgba(90, 90, 90, 0.2)"
            : "none",
        pointerEvents:
          isDragging || needToMakeItemSecondary
            ? "none"
            : ("inherit" as React.CSSProperties["pointerEvents"]),
        opacity: isDragging ? 0.3 : 1,
      };
    }, [
      width,
      elementWidth,
      visibleStartDate,
      activity.plannedStartDate,
      activity?.allocHeight,
      activity?.visualTop,
      activity.id,
      sidePanelOrder?.id,
      isDragging,
      needToMakeItemSecondary,
    ]);

    const getBackgroundColor = useCallback((): string => {
      return isLoading ? LOADING_STYLE.background : "inherit";
    }, [isLoading]);

    useMemo(() => {
      if (superLinking) {
        if ((superLinking as SuperLinkTeam)?.team) {
          orderStyles.opacity = 0.34; // Team is not assignable to a ghost order
        } else {
          orderStyles.boxShadow = "0px 0px 6px 1px rgba(100, 100, 100, 1)";
        }
      } else if (!sidePanelOrder?.id) {
        orderStyles.boxShadow = "none";
        orderStyles.opacity = 1; // Team is not assignable to a ghost order
        orderStyles.boxShadow = "none";
      }
    }, [orderStyles, sidePanelOrder?.id, superLinking]);

    const { orderContentWrapperStyles, contentRootStyles } = useMemo(() => {
      return {
        orderContentWrapperStyles: {
          backgroundColor: isDragging ? "transparent" : getBackgroundColor(),
          width: Math.max(width - 3, 0),
        },
        contentRootStyles: {
          pointerEvents:
            isDragging || needToMakeItemSecondary
              ? "yes"
              : ("inherit" as React.CSSProperties["pointerEvents"]),
        } as CSSProperties,
      };
    }, [isDragging, getBackgroundColor, width, needToMakeItemSecondary]);

    const wrapperStyles = useMemo(() => {
      const widthCSS = "fit-content";
      let flexDirection = "column";
      let alignItems = "";
      if (orderStyles.height < DEFAULT_ALLOCATION_HEIGHT) {
        flexDirection = "row";
        alignItems = "center";
      }
      return {
        widthCSS,
        flexDirection,
        alignItems,
        display: "flex",
      };
    }, [orderStyles.height]);

    const handleChangeModalDeleteState = () => {
      setIsModalDeleteVessel(!isModalDeleteVessel);
    };

    const handleClickGhostActivity = (e) => {
      // Handle edge case. "onAllocationClick" gets triggered also when clicking away from the 3-dots menu, or click modal buttons
      if (
        e.target?.classList?.contains("popup-overlay") ||
        e.target?.classList?.value?.includes("modalButton") ||
        isInsideModal(
          e.target,
          "MuiDialog-root",
          "MuiModal-root",
          "MuiPopper-root",
        )
      ) {
        return;
      }

      if (!superLinking) {
        // setTimeout fixes the race condition in the click event handling that
        // It allows the previous active sidepanel to have time detect clickaway and close itself + saving data before opening this one
        setTimeout(() => {
          setSidePanelOrder({ id: activity.id });
        }, 5);
      } else {
        const payload = superLinking as SuperLinkOrder;
        if (payload?.orderId) {
          if (payload?.type === "wo") {
            linkWorkOrderToGhostActivity({
              variables: {
                facilityId: selectedFacility,
                issuedBy: userData.mail,
                workOrderId: payload.orderId,
                ghostWorkOrderId: activity.id,
              },
            });
          } else if (payload?.type === "vessel") {
            linkVesselToGhostActivity({
              variables: {
                facilityId: selectedFacility,
                issuedBy: userData.mail,
                vesselId: payload.orderId,
                ghostVesselId: activity.id,
              },
            });
          }
          // reset
          disableSuperLinking();
        }
      }
    };

    return (
      <>
        <div
          className="floatingLabel"
          ref={ref}
          data-allocation-width={orderStyles.width - 30}
          data-allocation-offsetleft={orderStyles.left}
          style={{
            ...orderContentWrapperStyles,
            ...{
              position: "absolute",
              top:
                // eslint-disable-next-line no-nested-ternary
                activity?.allocHeight <= DEFAULT_ALLOCATION_HEIGHT / 2
                  ? orderStyles.top + 2
                  : orderStyles.top + 19,

              left: orderStyles.left + 20,
              // why? right: orderStyles.left + 30,
              width: Math.max(orderStyles.width - 60, 0),
              backgroundColor: "transparent",
              zIndex: 2,
              overflow: "hidden",
            },
          }}
        >
          <div ref={orderInfo} style={wrapperStyles as CSSProperties}>
            <div className={styles.activityInfo}>
              <GhostSVG />
              <span>{`${activity.name.toUpperCase()} - ${activity.customerName.toUpperCase()}`}</span>
            </div>
          </div>
        </div>

        <div
          className={globalStyles.tableOrderContainer}
          style={{
            marginLeft: position,
            height: orderStyles.height,
            top: orderStyles.top,
            left: orderStyles.left, // This "left" value will be copied to "floatingLabel" component when scrolling.
          }}
          onClick={handleClickGhostActivity}
          tabIndex={0}
          role="button"
          onKeyDown={() => null}
        >
          <DialogModal
            isOpen={isModalDeleteVessel}
            onClose={handleChangeModalDeleteState}
          >
            <DeleteVesselModal
              changeModalState={handleChangeModalDeleteState}
              orderId={activity.id}
              isGhostActivity
            />
          </DialogModal>

          <OrderCardDraggableSides
            {...draggableProps}
            parentItemType={draggableItemType}
            orderId={activity.id}
            onDrop={onPullEnd}
            height={`${orderStyles.height}px`}
            orderRegularWidth={width}
            orderExpandedWidth={orderStyles.width as number}
            inDetailedView={inDetailedView}
          />
          <div className={styles.dragableElement} style={contentRootStyles}>
            <div
              className={styles.tableEvent}
              style={{
                ...omit({ ...orderStyles }, ["top", "left"]),
                position: "absolute",
                cursor: isLoading ? "progress" : "",
              }}
              ref={drag}
            >
              <div style={{ ...orderContentWrapperStyles }}>
                {isLoading && <CircularLoading />}
                <OrderActions
                  vessel={activity}
                  changeStart={() => null}
                  changeStop={() => null}
                  deleteVessel={handleChangeModalDeleteState}
                  isGhostActivity
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  },
);

export default memo(GhostAllocation);
