import { useIsAuthenticated } from "@azure/msal-react";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import Dashboard from "../pages/dashboard/index";
import SignIn from "../pages/signIn";

const MainRouter: React.FC = () => {
  // Pattern (and alternatives) documented in https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md#useisauthenticated-hook
  const isAuthenticated = useIsAuthenticated();

  // All children of a <Switch> should be <Route> or <Redirect> elements.
  // Only the first child to match the current location will be rendered.
  // ---> via https://v5.reactrouter.com/web/api/Switch/children-node
  return (
    <Routes>
      {isAuthenticated && <Route path="/dashboard" element={<Dashboard />} />}
      {!isAuthenticated && <Route path="/login" element={<SignIn />} />}
      <Route
        path="*"
        element={
          <Navigate to={isAuthenticated ? "/dashboard" : "/login"} replace />
        }
      />
    </Routes>
  );
};

export default MainRouter;
