import { gql, useMutation } from "@apollo/client";
import isEmpty from "lodash/isEmpty";
import React, { FC, memo, useMemo, useState } from "react";

import PopUp from "@/components/common/formElements/popUp";
import dots from "@/style/img/icons/dots.svg";
import { Vessel } from "@/types";
import { closeSidePanel } from "@/zustand/useOrderStore";
import { useSettingsStore, useUserData } from "@/zustand/useSettingsStore";

import styles from "../index.module.scss";

const RELEASE_WO = gql`
  mutation releaseWorkOrder(
    $facilityId: String!
    $issuedBy: String!
    $workOrderId: String!
  ) {
    releaseWorkOrder(
      facilityId: $facilityId
      issuedBy: $issuedBy
      workOrderId: $workOrderId
    )
  }
`;
const COPY_VESSEL = gql`
  mutation copyRentalAgreementLine(
    $facilityId: String!
    $issuedBy: String!
    $rentalAgreementNumber: String!
    $rentalOrderLine: String!
  ) {
    copyRentalAgreementLine(
      facilityId: $facilityId
      issuedBy: $issuedBy
      rentalAgreementNumber: $rentalAgreementNumber
      rentalOrderLine: $rentalOrderLine
    )
  }
`;

type Props = {
  vessel: Vessel;
  isGhostActivity?: boolean;
  changeStart: () => void;
  changeStop: () => void;
  deleteVessel: () => void;
  style?: any;
};

const VesselActions: FC<Props> = ({
  vessel,
  changeStart,
  changeStop,
  deleteVessel,
  isGhostActivity = false,
  style = {},
}) => {
  const setSuperLinking = useSettingsStore((state) => state.setSuperLinking);

  const userData = useUserData();
  const selectedFacility = useSettingsStore((state) => state.selectedFacility);

  const [releaseWorkOrder] = useMutation(RELEASE_WO);
  const [copyRentalAgreementLine] = useMutation(COPY_VESSEL);
  const [isReleasePopUpOpen, setIsReleasePopUpOpen] = useState(false);
  const [isActionsOpen, setActionsOpen] = useState(false);
  const notAllocated = vessel.quay?.toLowerCase() === "not allocated";

  const requestedWorkOrders = useMemo(() => {
    const workOrders =
      vessel.workOrders &&
      !isEmpty(vessel.workOrders) &&
      vessel.workOrders.filter((el) => parseInt(el.status, 10) === 10);
    return workOrders || [];
  }, [vessel.workOrders]);

  const handleReleaseOrder = (workOrderId: string) => {
    releaseWorkOrder({
      variables: {
        facilityId: selectedFacility,
        issuedBy: userData.mail,
        workOrderId,
      },
    });
    setActionsOpen(false);
  };

  const handleCopyVessel = () => {
    copyRentalAgreementLine({
      variables: {
        facilityId: selectedFacility,
        issuedBy: userData.mail,
        rentalAgreementNumber: vessel.rentalAgreementNumber,
        rentalOrderLine: vessel.rentalAgreementLine,
      },
    });
    setActionsOpen(false);
  };

  const setOpenReleasePopUp = () => {
    setIsReleasePopUpOpen(!isReleasePopUpOpen);
  };

  return (
    <PopUp
      nested
      trigger={
        <section style={style}>
          <img src={dots} alt="settings" />
        </section>
      }
      isOpen={isActionsOpen}
      onClose={() => setActionsOpen(false)}
      onOpen={() => setActionsOpen(true)}
      content={
        <div className={`${styles.actionsPopUp} popUp-wrapper`}>
          <button
            type="button"
            className={styles.optionButton}
            disabled={
              !isGhostActivity &&
              vessel.status !== "REQUESTED" &&
              vessel.status !== "PLANNED"
            }
            style={
              !isGhostActivity &&
              vessel.status !== "REQUESTED" &&
              vessel.status !== "PLANNED"
                ? { cursor: "not-allowed" }
                : {}
            }
            title={
              !isGhostActivity &&
              vessel.status !== "REQUESTED" &&
              vessel.status !== "PLANNED"
                ? "Only allowed for Vessels in Requested or Planned status"
                : ""
            }
            onClick={() => {
              setActionsOpen(false);
              closeSidePanel();
              deleteVessel();
            }}
          >
            {isGhostActivity ? "DELETE GHOST ACTIVITY" : "DELETE VESSEL"}
          </button>
          {!isGhostActivity && (
            <>
              <button
                type="button"
                className={styles.optionButton}
                onClick={handleCopyVessel}
                disabled={isGhostActivity}
              >
                COPY VESSEL
              </button>
              <button
                type="button"
                className={styles.optionButton}
                onClick={() => {
                  setSuperLinking({
                    tooltipText:
                      "Click on the ghost activity to link to this vessel.",
                    orderId: vessel.id,
                    type: "vessel",
                  });
                  setActionsOpen(false);
                }}
              >
                LINK VESSEL TO GHOST ACTIVITY
              </button>
              <button
                disabled={
                  (notAllocated && vessel.status === "requested") ||
                  isGhostActivity
                }
                type="button"
                className={styles.optionButton}
                onClick={() => {
                  setActionsOpen(false);
                  changeStart();
                }}
              >
                START VESSEL
              </button>
              <button
                disabled={
                  (notAllocated && vessel.status === "requested") ||
                  // !vessel.startDate ||
                  isGhostActivity
                }
                type="button"
                className={styles.optionButton}
                onClick={() => {
                  setActionsOpen(false);
                  changeStop();
                }}
              >
                STOP VESSEL
              </button>
              <PopUp
                position="right center"
                onClose={setOpenReleasePopUp}
                onOpen={setOpenReleasePopUp}
                isOpen={isActionsOpen && isReleasePopUpOpen}
                trigger={
                  <button
                    type="button"
                    className={styles.optionButton}
                    onClick={setOpenReleasePopUp}
                    disabled={isEmpty(requestedWorkOrders)}
                  >
                    RELEASE WORK ORDER
                  </button>
                }
                content={
                  <>
                    {requestedWorkOrders.map(
                      (el) =>
                        el.status === "10" && (
                          <button
                            key={el.id}
                            type="button"
                            className={styles.optionButton}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleReleaseOrder(el.id);
                            }}
                          >
                            {`#${el.id}`}
                          </button>
                        ),
                    )}
                  </>
                }
              />
            </>
          )}
        </div>
      }
    />
  );
};
export default memo(VesselActions);
