import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import React, { FC, ReactElement } from "react";

interface Props {
  children: ReactElement;
  onClose: () => void;
  isOpen: boolean;
}

const style = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 10,
  p: 1,
};

const ModalWindow: FC<Props> = ({ children, isOpen, onClose }) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box sx={style}>{children}</Box>
    </Modal>
  );
};

export default ModalWindow;
