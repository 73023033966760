import { create } from "zustand";

type Callback = (data: any) => void;

type PubSubState = {
  subscribers: Record<string, Callback[]>;
  subscribe: (eventType: string, callback: Callback) => void;
  unsubscribe: (eventType: string, callback: Callback) => void;
  publish: (eventType: string, data: any) => void;
};

const usePubSubStore = create<PubSubState>((set, get) => ({
  subscribers: {},

  subscribe: (eventType, callback) =>
    set((state) => ({
      subscribers: {
        ...state.subscribers,
        [eventType]: [...(state.subscribers[eventType] || []), callback],
      },
    })),

  unsubscribe: (eventType, callback) =>
    set((state) => {
      const filteredCallbacks = (state.subscribers[eventType] || []).filter(
        (cb) => cb !== callback,
      );
      return {
        subscribers: {
          ...state.subscribers,
          [eventType]: filteredCallbacks,
        },
      };
    }),

  publish: (eventType, data) => {
    const currentSubscribers = get().subscribers;
    (currentSubscribers[eventType] || []).forEach((callback) => callback(data));
  },
}));

export default usePubSubStore;
