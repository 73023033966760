import React, { FC, memo } from "react";

import arrow from "@/style/img/arrow.svg";
import { useSettingsStore } from "@/zustand/useSettingsStore";

import SettingsForm from "./settingsForm";

import styles from "./index.module.scss";

const Settings: FC = () => {
  const closeSidebarTab = useSettingsStore((state) => state.closeSidebarTab);
  return (
    <div className={styles.settingsWrapper}>
      <nav>
        <button type="button" onClick={() => closeSidebarTab()}>
          <img src={arrow} alt="arrow" />
          SETTINGS
        </button>
      </nav>
      <SettingsForm />
    </div>
  );
};
export default memo(Settings);
