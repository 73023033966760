import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import React from "react";
import { createRoot } from "react-dom/client";

import NoInternetBanner from "@/components/common/NoInternetBanner";
import { msalConfig } from "@/utils/auth/authConfig";

import App from "./pages/app";

import "animate.css";
import "./index.scss";
import "./style/themes.scss";

const msalInstance = new PublicClientApplication(msalConfig);

let app;
// This fixes a SignIn issue, "hash_empty_error"
// "When using popup we recommend setting the redirectUri to a blank page or a page that does not implement MSAL." https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/login-user.md#redirecturi-considerations
// Also see related https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/4573 (just upgrading MSAL didn't fix it)
const IS_POP_UP = window.opener && window.opener !== window;
const WE_ARE_INSIDE_AN_IFRAME = window.parent !== window; // avoids error "redirect_in_iframe: Redirects are not supported for iframed or brokered applications. Please ensure you are using MSAL.js in a top frame of the window"
if (
  (IS_POP_UP || WE_ARE_INSIDE_AN_IFRAME) &&
  window.location?.hash?.includes("code=")
) {
  // See documentation/Authentication.md for tips for debugging the popup.
  app = <div>Redirecting to Planning Board</div>;
} else {
  app = (
    <MsalProvider instance={msalInstance}>
      <NoInternetBanner />
      <App />
    </MsalProvider>
  );
}

const container = document.getElementById("root");

if (container != null) {
  const root = createRoot(container);
  root.render(app);
} else {
  console.error("Unable to find react root.");
}
