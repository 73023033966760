import moment from "moment-timezone";

import { captureMessage } from "@/helpers/captureError";

type enoughData = {
  id: string;
  plannedStartDate: string;
  plannedStopDate: string;
};
export const checkStartBeforeStop = (data: enoughData, setSnack): boolean => {
  if (!data?.plannedStartDate || !data?.plannedStopDate) {
    const message = `No startDate or stopDate provided. Order id: ${data?.id}`;
    setSnack(true, message, true, "error");
    captureMessage(message);
    return false;
  }
  const startMoment = moment(data?.plannedStartDate);
  const stopMoment = moment(data?.plannedStopDate);
  const isStartBeforeStop = startMoment.isBefore(stopMoment);
  if (!isStartBeforeStop) {
    const message = `Start date must be before Stop date. Order id: ${data?.id}`;
    setSnack(true, message, true, "error");
    captureMessage(message);
    return false;
  }
  return isStartBeforeStop;
};
