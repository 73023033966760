import { useQuery } from "@apollo/client";
import isEqual from "lodash/isEqual";
import React, { forwardRef, useEffect, useRef, useState } from "react";

import { captureMessage } from "@/helpers/captureError";
import arrow90deg from "@/style/img/icons/arrow_up.svg";
import arrowUpDisabled from "@/style/img/icons/arrow_up_disabled.svg";
import closeIcon from "@/style/img/icons/remove.svg";
import search from "@/style/img/icons/search_icon.svg";
import {
  Assignment,
  Nullable,
  Vessel,
  WorkOrder as WorkOrderTypes,
} from "@/types";

import EmployeesList from "./employeesList";
import EquipmentList from "./equipmentList";
import {
  GET_WORK_ORDER_LINES_BY_ID,
  WorkOrderLineByIdInputType,
  WorkOrderLineByIdReturnType,
} from "./queries";

import styles from "../sidePanel.module.scss";
import inputStyle from "@/components/common/formElements/customFormStyles.module.scss";
import textStyles from "@/style/textStyles.module.scss";

type Props = {
  setResourcesPanelActive: (workOrderLineId?: string) => void;
  // activeWorkOrderId: string | undefined; // TODO: Not used
  activeWorkOrderLineId: string | undefined;
  // notifications: NotificationType | undefined; // TODO: Not used
  entry: Nullable<Vessel> | Nullable<WorkOrderTypes>;
};

const UNASSIGNED_VALUE = { name: "Unassigned", id: "1" };

const AssignResourcesPanel = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { setResourcesPanelActive, activeWorkOrderLineId, entry } = props;
  const { data, loading } = useQuery<
    WorkOrderLineByIdReturnType,
    WorkOrderLineByIdInputType
  >(GET_WORK_ORDER_LINES_BY_ID, {
    variables: {
      workOrderLineId: activeWorkOrderLineId as string,
    },
    skip: !activeWorkOrderLineId,
  });

  const [searchValue, setSearchValue] = useState("");
  const [isActiveEmployeesSection, setIsActiveEmployeesSection] =
    useState(false);
  const [canSetEmployees, setCanSetEmployees] = useState(false);
  const [isActiveEquipmentSection, setIsActiveEquipmentSection] =
    useState(true);
  const [equipment, setEquipment] = useState(UNASSIGNED_VALUE);
  const [assigned, setAssigned] = useState(UNASSIGNED_VALUE);
  const employeeElement = useRef<HTMLElement>(null);

  const capability = data?.workOrderLineById?.assignment as Assignment;
  if (!loading && !capability) {
    captureMessage(`Empty assignments for line ${activeWorkOrderLineId}`);
  }

  // Does it require to be an operator (person that operates equipment)
  const requiresEquipmentOperator =
    capability?.capabilityType === "WORK_CENTER";

  // This resets the state when changing to the next op line without closing Resource Panel.
  useEffect(() => {
    setIsActiveEquipmentSection(true);
  }, [activeWorkOrderLineId]);

  useEffect(() => {
    if (capability) {
      const equip = capability.workCenter
        ? {
            name: `${capability.workCenter?.name}`,
            id: capability.workCenter?.id,
          }
        : UNASSIGNED_VALUE;
      const assign = !capability.operator
        ? UNASSIGNED_VALUE
        : {
            name: `${capability.operator?.firstName} ${capability.operator?.lastName}`,
            id: capability.operator?.id,
          };

      setAssigned(assign);
      setEquipment(equip);
    } else {
      // reset it
      setAssigned(UNASSIGNED_VALUE);
      setEquipment(UNASSIGNED_VALUE);
    }
  }, [capability]);

  useEffect(() => {
    const isEquipmentChosen = !isEqual(equipment, UNASSIGNED_VALUE);

    if (isEquipmentChosen || !requiresEquipmentOperator) {
      setCanSetEmployees(true);
      setIsActiveEmployeesSection(true);
    } else {
      setCanSetEmployees(false);
      setIsActiveEmployeesSection(false);
    }
  }, [equipment, requiresEquipmentOperator]);

  const searchInput = () => {
    const render = (
      <div className={inputStyle.searchFilter}>
        <img src={search} alt="search" />
        <input
          type="text"
          placeholder="Search"
          value={searchValue}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setSearchValue(e.target.value)
          }
        />
      </div>
    );
    return render;
  };

  return (
    <div className={styles.assignResourcesPanel} ref={ref}>
      <div className={styles.assignResourcesHeader}>
        <span className={textStyles.primaryText}>
          ASSIGN EQUIPMENT AND EMPLOYEES
        </span>
        <button
          className={styles.iconButton}
          type="button"
          onClick={() => setResourcesPanelActive("")}
        >
          <img src={closeIcon} alt="Close" />
        </button>
      </div>
      <div className={styles.restOfPanel}>
        <div className={styles.infoBlock}>
          <div className={styles.fieldNames}>
            <span className={textStyles.secondaryText}>Work Center Type:</span>
            {requiresEquipmentOperator && (
              <span className={textStyles.secondaryText}>Equipment:</span>
            )}
            <span className={textStyles.secondaryText}>Employee:</span>
          </div>
          <div className={styles.fieldInfo}>
            <span className={textStyles.primaryText}>
              {data?.workOrderLineById?.description || "N/A"}
            </span>
            {requiresEquipmentOperator && (
              <span
                className={textStyles.primaryText}
                style={
                  equipment.name === "Unassigned" ? { color: "#BB0000" } : {}
                }
              >
                {equipment.name}
              </span>
            )}
            <span
              style={assigned.name === "Unassigned" ? { color: "#BB0000" } : {}}
              className={textStyles.primaryText}
            >
              {assigned.name}
            </span>
          </div>
        </div>
        <div className={styles.filters}>{searchInput()}</div>
        {requiresEquipmentOperator && (
          <>
            <section
              tabIndex={0}
              role="button"
              onKeyDown={() => null}
              onClick={() =>
                setIsActiveEquipmentSection(!isActiveEquipmentSection)
              }
              className={styles.expandedSection}
            >
              <img
                style={
                  !isActiveEquipmentSection
                    ? { transform: "rotate(180deg)" }
                    : {}
                }
                src={arrow90deg}
                alt="arrow"
              />
              <span className={textStyles.primaryText}>Equipment</span>
            </section>
            {!loading && isActiveEquipmentSection && (
              <EquipmentList
                activeWorkOrderLine={data?.workOrderLineById}
                searchValue={searchValue}
                setEquipment={(v: { name: string; id: string }) =>
                  setEquipment(v)
                }
                setIsActiveEquipmentSection={setIsActiveEquipmentSection}
                operatorId={
                  assigned.name !== "Unassigned" ? assigned.id : undefined
                }
              />
            )}
          </>
        )}

        <section
          ref={employeeElement}
          tabIndex={0}
          role="button"
          onKeyDown={() => null}
          onClick={() => {
            if (canSetEmployees) {
              setIsActiveEmployeesSection(!isActiveEmployeesSection);
            }
          }}
          className={styles.expandedSection}
        >
          {canSetEmployees ? (
            <img
              style={
                !isActiveEmployeesSection ? { transform: "rotate(180deg)" } : {}
              }
              src={arrow90deg}
              alt="arrow"
            />
          ) : (
            <img
              style={{ transform: "rotate(180deg)" }}
              src={arrowUpDisabled}
              alt="arrow disabled"
            />
          )}

          <span
            className={
              canSetEmployees ? textStyles.primaryText : textStyles.disabledText
            }
          >
            Employees
          </span>
        </section>
        {!canSetEmployees ? (
          <div className={textStyles.secondaryText} style={{ color: "#888" }}>
            Equipment must be added first.
          </div>
        ) : null}
        {!loading && isActiveEmployeesSection && (
          <EmployeesList
            entry={entry}
            activeWorkOrderLine={data?.workOrderLineById}
            searchValue={searchValue}
            setOperator={(v: { name: string; id: string }) => setAssigned(v)}
            equipment={equipment}
            requiresEquipmentOperator={requiresEquipmentOperator}
          />
        )}
      </div>
    </div>
  );
});
export default AssignResourcesPanel;
