import { gql } from "@apollo/client";

import { WorkOrderLineWithTimestampOperations } from "@/types";

export type WorkOrderLineByIdInputType = {
  workOrderLineId: string;
};
export type WorkOrderLineByIdReturnType = {
  workOrderLineById?: WorkOrderLineWithTimestampOperations;
};
export const GET_WORK_ORDER_LINES_BY_ID = gql`
  query workOrderLineById($workOrderLineId: String!) {
    workOrderLineById(workOrderLineId: $workOrderLineId) {
      id
      workOrderId
      capability
      description
      operationNumber
      plannedStartDate
      plannedStopDate
      assignment {
        description
        workOrderLineNumber
        workCenterType
        capabilityType
        capabilityTypeNumber
        operationElementId
        operator {
          id
          firstName
          middleName
          lastName
        }
        workCenter {
          id
          name
          description
        }
        workOrderType
      }
      # capabilityRequirements {
      #   id
      #   employeeNumber
      #   description
      #   operationElement
      #   capabilityType
      #   operator {
      #     firstName
      #     middleName
      #     lastName
      #     id
      #   }
      #   workcenter {
      #     id
      #     name
      #     description
      #   }
      # }
      # workCenter {
      #   id
      #   name
      #   description
      #   parentId
      # }
    }
  }
`;

export const GET_AVAILABLE_EQUIPMENT = gql`
  query getAvailableEquipment($workCenterType: String!, $facilityId: String!) {
    workCentersMachinesByType(
      facilityId: $facilityId
      workCenterType: $workCenterType
    ) {
      id
      name
      description
      workCenterId
      planningArea
    }
  }
`;

export const GET_AVAILABLE_EQUIPMENT_FOR_OPERATOR = gql`
  query getAvailableEquipmentForOperator(
    $workCenterType: String!
    $facilityId: String!
    $operatorId: String!
  ) {
    workCentersMachinesForOperator(
      workCenterType: $workCenterType
      facilityId: $facilityId
      operatorId: $operatorId
    ) {
      id
      name
      description
      workCenterId
      planningArea
    }
  }
`;

export type AssignWorkCenterToWorkOrderLineM3InputType = {
  facilityId: string;
  issuedBy: string;
  workCenterId: string;
  workOrderLineId: string;
  capabilityType: string;
};

export type AssignWorkCenterToWorkOrderLineM3ReturnType = {
  assignWorkCenterToWorkOrderLineM3: boolean;
};

export const ASSIGN_RESOURCE = gql`
  mutation assignWorkCenterToWorkOrderLineM3(
    $facilityId: String!
    $issuedBy: String!
    $workCenterId: String!
    $workOrderLineId: String!
    $capabilityType: String!
  ) {
    assignWorkCenterToWorkOrderLineM3(
      facilityId: $facilityId
      issuedBy: $issuedBy
      workCenterId: $workCenterId
      workOrderLineId: $workOrderLineId
      capabilityType: $capabilityType
    )
  }
`;

export const GET_CERTIFIED_OPERATORS = gql`
  query certifiedOperatorsWithinTimeframe(
    $from: DateTime!
    $to: DateTime!
    $facilityId: String!
    $operationElementId: String!
  ) {
    certifiedOperatorsWithinTimeframe(
      from: $from
      to: $to
      facilityId: $facilityId
      operationElementId: $operationElementId
    ) {
      firstName
      middleName
      lastName
      id
      planningAreaId
    }
  }
`;

export type AssignEmployeeToWorkOrderLineInputType = {
  facilityId: string;
  issuedBy: string;
  workCenterId: string;
  operatorId: string;
  workOrderLineId: string;
  capabilityType: string;
};

export type AssignEmployeeToWorkOrderLineReturnType = {
  assignEmployeeToWorkOrderLine: boolean;
};

export const ASSIGN_EMPLOYEE_TO_WORK_ORDER_LINE = gql`
  mutation assignEmployeeToWorkOrderLine(
    $facilityId: String!
    $issuedBy: String!
    $workCenterId: String!
    $operatorId: String!
    $workOrderLineId: String!
    $capabilityType: String!
  ) {
    assignEmployeeToWorkOrderLine(
      facilityId: $facilityId
      issuedBy: $issuedBy
      workCenterId: $workCenterId
      operatorId: $operatorId
      workOrderLineId: $workOrderLineId
      capabilityType: $capabilityType
    )
  }
`;

export const CREATE_WORK_ORDERLINE_MUTATION = gql`
  mutation createWorkOrderLine(
    $facilityId: String!
    $issuedBy: String!
    $capabilityId: String!
    $description: String!
    $parentWorkOrderId: String!
  ) {
    createWorkOrderLine(
      facilityId: $facilityId
      issuedBy: $issuedBy
      capabilityId: $capabilityId
      description: $description
      parentWorkOrderId: $parentWorkOrderId
    )
  }
`;

export const ASSIGN_TEAM_MUTATION = gql`
  mutation assignTeam(
    $facilityId: String!
    $teamId: UUID!
    $issuedBy: String!
    $workOrderId: String!
  ) {
    assignTeamToWorkOrder(
      facilityId: $facilityId
      teamId: $teamId
      issuedBy: $issuedBy
      workOrderId: $workOrderId
    )
  }
`;

export const GET_WORK_ORDER_LINES = gql`
  query getOrderLinesById($workOrderId: String!) {
    workOrderById(workOrderId: $workOrderId) {
      id
      lines {
        workOrderId
        capability
        id
        description
        # workCenter {
        #   id
        # }
        assignment {
          description
          workOrderLineNumber
          workCenterType
          capabilityType
          capabilityTypeNumber
          operationElementId
          operator {
            id
            firstName
            middleName
            lastName
          }
          workCenter {
            id
            name
            description
          }
          workOrderType
        }
        # capabilityRequirements {
        #   id
        #   description
        #   capabilityType
        #   operationElement
        #   operator {
        #     firstName
        #     lastName
        #     id
        #   }
        #   workcenter {
        #     id
        #     name
        #     description
        #   }
        # }
      }
    }
  }
`;

export const GET_TEAM_OPERATION_LINES = gql`
  query getOrderLinesByTeamId($facilityId: String!, $teamId: UUID!) {
    getOrderLinesByTeamId(teamId: $teamId) {
      id
      workCenterCategory(facilityId: $facilityId) {
        id
        workCenterId
        workCenterType
        name
      }
      workCenterMachine(facilityId: $facilityId) {
        id
        workCenterId
        workCenterType
        name
      }
      operatorId
      operator {
        id
        firstName
        lastName
      }
    }
  }
`;
