/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// App.js
import { gql, useQuery } from "@apollo/client";
import React, { FC, useEffect, useMemo, useRef, useState } from "react";

import { captureMessage } from "@/helpers/captureError";
import { WorkOrder, WorkOrderLineTypes } from "@/types";
import { useOrderStore } from "@/zustand/useOrderStore";
import { useSettingsStore } from "@/zustand/useSettingsStore";

import InvoiceTableStep1 from "./InvoiceTableStep1";
import InvoiceTableStep2 from "./InvoiceTableStep2";
import InvoiceTableStep3 from "./InvoiceTableStep3";

import styles from "./index.module.scss";

type Props = {
  wo: WorkOrder;
  onClickAway: () => void;
};

const GET_INVOICE_LINES = gql`
  query maintenanceCustomerOrderLine(
    $facilityId: String!
    $maintenanceCustomerOrderLineNumber: String!
    $maintenanceCustomerOrderNumber: String!
  ) {
    maintenanceCustomerOrderLine(
      facilityId: $facilityId
      maintenanceCustomerOrderLineNumber: $maintenanceCustomerOrderLineNumber
      maintenanceCustomerOrderNumber: $maintenanceCustomerOrderNumber
    ) {
      status
      invoiceLines {
        id
        costingTypeId
        costingTypeDescription
        discountPercentage
        itemName
        maintenanceCustomerOrderLineNumber
        maintenanceCustomerOrderNumber
        quantity
        quotation
        salesPrice
        totalSalesPrice
        transactionDate
        transactionNumber
        unit
        unitPrice
        workCenter
        workOrderNumber
      }
    }
  }
`;

const InvoicePanel: FC<Props> = ({ wo, onClickAway }) => {
  const selectedFacility = useSettingsStore((state) => state.selectedFacility);
  const [activeStep, setActiveStep] = useState<number>(1);
  const [waitingStep1Review, setWaitingStep1Review] = useState<boolean>(false);
  const [errorAtStep1Review, setErrorAtStep1Review] = useState<string>("");
  const [waitingStep2Review, setWaitingStep2Review] = useState<boolean>(false);
  const [errorAtStep2Review, setErrorAtStep2Review] = useState<string>("");
  const [recentlyApproved, setRecentlyApproved] = useState<boolean>(false);
  const [orderLines, setOrderLines] = useState<WorkOrderLineTypes[]>([]);
  const [invoiceLines, setInvoiceLines] = useState<WorkOrderLineTypes[]>([]);
  const [mcoLineStatus, setMcoLineStatus] = useState<string>();

  const scrollRef = useRef(null);

  const { loading, refetch } = useQuery(GET_INVOICE_LINES, {
    variables: {
      facilityId: selectedFacility,
      maintenanceCustomerOrderLineNumber: wo?.mCO?.referenceOrderLine,
      maintenanceCustomerOrderNumber: wo?.mCO?.referenceOrderNumber,
    },
    onCompleted: (res) => {
      setInvoiceLines(res?.maintenanceCustomerOrderLine?.invoiceLines);
      setMcoLineStatus(res?.maintenanceCustomerOrderLine?.status);
    },
  });

  const goToStep = (thisStep) => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0;
    }
    if (thisStep === 2) {
      setWaitingStep1Review(true);
      // console.log("start timeout", thisStep, waitingStep1Review); Still is false.
      setTimeout(() => {
        // console.log("end timeout", thisStep, waitingStep1Review);
        // if still waiting:
        if (waitingStep1Review) {
          setErrorAtStep1Review(
            "After 60 seconds, some data hasn't been synced yet. Please review and APPROVE again.",
          );
          setWaitingStep1Review(false);
          captureMessage(`Step1 took more than 60 seconds to approve:${wo.id}`);
        }
      }, 60000);
    }
    if (thisStep === 3) {
      setWaitingStep2Review(true);

      setTimeout(() => {
        // if still waiting:
        if (waitingStep2Review) {
          setErrorAtStep2Review(
            "After 60 seconds, some data hasn't been synced yet. Please review and APPROVE again.",
          );
          setWaitingStep2Review(false);
          captureMessage(`Step2 took more than 60 seconds to approve:${wo.id}`);
        }
      }, 60000);
    }
  };

  useEffect(() => {
    let invoiceStep = 1;
    const newStatus = parseInt(wo?.status, 10);
    if (newStatus === 94) {
      setWaitingStep1Review(false);
      setErrorAtStep1Review("");
      invoiceStep = 2;
    } else if (newStatus === 95) {
      if (activeStep === 2) {
        setRecentlyApproved(true);
      }
      setWaitingStep2Review(false);
      setErrorAtStep2Review("");
      invoiceStep = 3;
    }
    console.log(
      "Wo status changed to: ",
      wo.status,
      ". So change to step number: ",
      invoiceStep,
    );
    setActiveStep(invoiceStep);
  }, [activeStep, wo.status]); // Dependency array ensures the effect runs when wo.status changes

  const setData = (updatedWO) => {
    setOrderLines(updatedWO?.workOrderById?.lines);
    refetch();
    // setInvoiceLines(updatedWO?.workOrderById?.invoiceWorkOrderLines);
  };

  const broadcastWO = useOrderStore((state) => state.broadcastWO);
  useMemo(() => {
    return broadcastWO ? setData(broadcastWO) : null; // TODO: sanitize
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(broadcastWO)]);
  return (
    <div className={styles.invoicePanel} ref={scrollRef}>
      <div className={styles.workflow}>
        <div className={styles.workflowSteps}>
          <div
            className={activeStep <= 3 ? styles.active : ""}
            style={{ paddingLeft: "10px", width: "14%" }}
          >
            <span className="step-number">1</span>
            <div className="step-title ">REVIEW ORDER</div>
          </div>
          <div
            className={activeStep > 1 && activeStep <= 3 ? styles.active : ""}
          >
            <span className="step-number">2</span>
            <div className="step-title">REVIEW INVOICE</div>
          </div>
          <div
            className={activeStep === 3 ? styles.active : ""}
            style={{ paddingRight: "80px" }}
          >
            <span className="step-number">3</span>
            <div className="step-title">APPROVE INVOICE</div>
          </div>
        </div>
        <div className={styles.closeButton}>
          <span onClick={onClickAway}>&times;</span>
        </div>
      </div>
      {activeStep === 1 && (
        <InvoiceTableStep1
          workOrderId={wo?.id}
          goToStep={goToStep}
          orderLines={orderLines}
          setOrderLines={setOrderLines}
          waitingReview={waitingStep1Review}
          errorMessage={errorAtStep1Review}
        />
      )}
      {activeStep === 2 && (
        <InvoiceTableStep2
          workOrderId={wo?.id}
          goToStep={goToStep}
          invoiceLines={invoiceLines}
          mcoNumber={wo?.mCO?.referenceOrderNumber}
          mcoLineNumber={wo?.mCO?.referenceOrderLine}
          mcoLineStatus={mcoLineStatus}
          waitingReview={waitingStep2Review}
          errorMessage={errorAtStep2Review}
        />
      )}
      {activeStep === 3 && (
        <InvoiceTableStep3
          recentlyApproved={recentlyApproved}
          invoiceLines={invoiceLines}
        />
      )}
    </div>
  );
};

export default InvoicePanel;
