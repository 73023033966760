import { useQuery } from "@apollo/client";
import moment, { Moment } from "moment-timezone";
import React, { FC, memo, useEffect, useState } from "react";

import AutoCompleteInput from "@/components/common/formElements/autocompleteInput";
import DateAndTimeField from "@/components/common/formElements/dateAndTimeField";
import {
  SEARCH_CUSTOMERS,
  SEARCH_NAMES,
} from "@/components/common/ghostActivityForm/ghostQueries";
import { STATIC_TIMELINE_ROWS } from "@/helpers/constants";
import { validateStartBeforeStop } from "@/helpers/dateTimeValidator";
import { GhostActivity, SelectSingleProps } from "@/types";
import { useSettingsStore } from "@/zustand/useSettingsStore";

import CustomSelect from "../../common/formElements/reactSelect";
import DropZone from "./dropzone";
import {
  GET_GHOST_ACTIVITY_NOTES,
  UPSERT_GHOST_ACTIVITY_NOTE,
} from "./notes/notesQueriesAndMutations";
import OrderNotes, {
  NotesMutationParams,
  NotesQueryParams,
  NotesQueryPayloadIdentifier,
} from "./notes/orderNotes";

import styles from "../../common/ghostActivityForm/index.module.scss";
import textStyles from "@/style/textStyles.module.scss";

const REQUIRED_ERROR_MESSAGE = "We need your input here.";

type PartialGhostData = {
  plannedStartDate: string;
  plannedStopDate: string;
  name: string;
  customerName: string;
  selectedActivity: string;
  quay: string;
  reserve: string;
};

type Props = {
  data: GhostActivity;
  setUpdatedGhost: (data: Partial<PartialGhostData>) => void;
};

const GhostSidePanel: FC<Props> = ({ data: savedData, setUpdatedGhost }) => {
  const selectedFacility = useSettingsStore((state) => state.selectedFacility);
  const allRows = useSettingsStore((state) => state.allRows);

  const [customerName, setCustomerName] = useState(savedData.customerName);
  const [vesselName, setVesselName] = useState(savedData.name);

  const [startDate, setStartDate] = useState<Moment | null>(
    savedData.plannedStartDate ? moment(savedData.plannedStartDate) : null,
  );
  const [stopDate, setStopDate] = useState<Moment | null>(
    savedData.plannedStopDate ? moment(savedData.plannedStopDate) : null,
  );
  const [selectedActivity, setSelectedActivity] = useState<SelectSingleProps>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [errors, setErrors] = useState<any>({});

  const options = allRows.map((el) => {
    return { value: el.id, label: el.name };
  });

  const { data } = useQuery(SEARCH_CUSTOMERS, {
    variables: { searchTerm: customerName, facilityId: selectedFacility },
    skip: customerName === "",
  });

  const autocompleteNames = useQuery(SEARCH_NAMES, {
    variables: { searchTerm: vesselName },
    skip: vesselName === "",
  });

  const handleChangeReserve = (newValue: SelectSingleProps) => {
    setSelectedActivity(newValue);
    setUpdatedGhost({ reserve: newValue.value });
    setErrors({ ...errors, selectedActivity: false });
  };

  const handleFormErrors = (e: React.FocusEvent | FocusEvent) => {
    e.preventDefault();
    const eventTargetId = e.target ? (e.target as HTMLFormElement).id : e.type;
    if (eventTargetId === "Customer Name" && customerName === "") {
      setErrors({ ...errors, customerName: true });
    }
    if (eventTargetId === "Vessel/Order Name" && vesselName === "") {
      setErrors({ ...errors, vesselName: true });
    }
    if (eventTargetId === "Planned Start" && !startDate) {
      setErrors({ ...errors, startDate: true });
    }
    if (eventTargetId === "Planned Stop" && !stopDate) {
      setErrors({ ...errors, endDate: true });
    }
    if (eventTargetId === "SELECT ACTIVITY" && !selectedActivity) {
      setErrors({ ...errors, selectedActivity: true });
    }
  };

  const handleInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    const id =
      e.type === "change"
        ? (e.target as HTMLElement).id
        : (e.target as HTMLButtonElement).parentElement?.id;

    const value =
      e.type === "change"
        ? (e.target as HTMLInputElement).value
        : (e.target as HTMLButtonElement).innerHTML;

    if (id === "Customer Name") {
      setErrors({ ...errors, customerName: false });
      setCustomerName(value);
      setUpdatedGhost({ customerName: value });
    }
    if (id === "Vessel/Order Name") {
      setErrors({ ...errors, vesselName: false });
      setVesselName(value);
      setUpdatedGhost({ name: value });
    }
  };

  const handleSetStartDate = (newValue: Moment | null) => {
    setStartDate(newValue);
    setUpdatedGhost({ plannedStartDate: newValue?.toISOString() || "" });
  };

  const handleSetStopDate = (newValue: Moment | null) => {
    setStopDate(newValue);
    setUpdatedGhost({ plannedStopDate: newValue?.toISOString() || "" });
  };

  useEffect(() => {
    if (!selectedActivity) {
      // Ghost activities is not saved with the same "id_label" format when it's reserved in one of the STATIC_TIMELINE_ROWS
      // Find the right label:
      const nonQuayLabel = STATIC_TIMELINE_ROWS.find(
        (el) => el.id === savedData.normalizedQuay,
      )?.name?.toUpperCase();
      setSelectedActivity({
        value: savedData.normalizedQuay,
        label:
          savedData.normalizedQuay.split("_")[1]?.toUpperCase() ||
          nonQuayLabel ||
          savedData.normalizedQuay,
      });
    }
  }, [customerName, savedData, selectedActivity, vesselName]);

  const isStartBeforeStop = validateStartBeforeStop(startDate, stopDate);

  const uniqueCustomerNames = new Map();
  const filteredCustomerNames = (data?.searchRentalAgreements || []).filter(
    (item) => {
      if (uniqueCustomerNames.get(item.agreementCustomer)) {
        return false;
      }
      uniqueCustomerNames.set(item.agreementCustomer, true);
      return true;
    },
  );

  return (
    <div className={styles.ghostActivityForm}>
      <div className={styles.inputBlock}>
        <span className={textStyles.secondaryText}>Customer name:</span>
        <AutoCompleteInput
          value={customerName}
          setValue={(e) => handleInputChange(e)}
          placeholder="Customer Name"
          error={!!errors.customerName}
          errorMessage={REQUIRED_ERROR_MESSAGE}
          onBlur={(e) => handleFormErrors(e)}
          autoCompleteValues={filteredCustomerNames || []}
        />
      </div>
      <div className={styles.inputBlock}>
        <span className={textStyles.secondaryText}>Vessel Name:</span>
        <AutoCompleteInput
          value={vesselName}
          setValue={(e) => handleInputChange(e)}
          placeholder="Vessel/Order Name"
          error={!!errors.vesselName}
          errorMessage={REQUIRED_ERROR_MESSAGE}
          onBlur={(e) => handleFormErrors(e)}
          autoCompleteValues={autocompleteNames.data?.searchVessels || []}
        />
      </div>
      <div className={styles.dateInputBlock}>
        <span className={textStyles.secondaryText}>Planned Start:</span>
        <DateAndTimeField
          dateValue={startDate}
          errorJSX={isStartBeforeStop || !errors?.startDate ? undefined : ""}
          handleChange={(newValue: Moment | null) => {
            if (!newValue) {
              setErrors({ ...errors, startDate: true });
            } else {
              setErrors({ ...errors, startDate: false });
            }
            handleSetStartDate(newValue);
          }}
        />
      </div>
      <div className={styles.dateInputBlock}>
        <span className={textStyles.secondaryText}>Planned Stop:</span>
        <DateAndTimeField
          dateValue={stopDate}
          errorJSX={isStartBeforeStop || !errors?.stopDate ? undefined : ""}
          handleChange={(newValue: Moment | null) => {
            if (!newValue) {
              setErrors({ ...errors, stopDate: true });
            } else {
              setErrors({ ...errors, stopDate: false });
            }
            handleSetStopDate(newValue);
          }}
        />
      </div>
      <div className={styles.dateInputBlock}>
        <span className={textStyles.secondaryText}>Reserve:</span>
        <div>
          <CustomSelect
            value={selectedActivity}
            onChange={handleChangeReserve}
            onBlur={(e) => handleFormErrors(e)}
            placeholder="SELECT ACTIVITY"
            error={!!errors.selectedActivity}
            errorMessage={REQUIRED_ERROR_MESSAGE}
            options={options}
          />
        </div>
      </div>
      {!isStartBeforeStop && !errors?.startDate && !errors?.stopDate && (
        <p className="error-message">
          Check that the start date is later than the end date.
          <br /> Give it another try!
        </p>
      )}
      {(errors?.stopDate || errors?.startDate) && (
        <p className="error-message">{REQUIRED_ERROR_MESSAGE}</p>
      )}
      <div className={styles.notes}>
        <OrderNotes
          title="Handover Notes:"
          noteParentId={savedData.id}
          mutationParams={
            {
              create: UPSERT_GHOST_ACTIVITY_NOTE,
              update: UPSERT_GHOST_ACTIVITY_NOTE,
              createParam: "ghostActivityId",
              updateParam: "ghostActivityNoteId",
            } as NotesMutationParams
          }
          queryParams={
            {
              query: GET_GHOST_ACTIVITY_NOTES,
              param: "ghostActivityId",
              payloadIdentifier: {
                identifier1: "ghostActivityNotes",
                identifier2: "",
              } as NotesQueryPayloadIdentifier,
            } as NotesQueryParams
          }
        />
        <DropZone
          orderType="ghostactivity"
          attachments={savedData?.attachments}
          ghostActivityId={savedData?.id}
        />
      </div>
    </div>
  );
};
export default memo(GhostSidePanel);
