import { gql, useMutation } from "@apollo/client";
import React, { FC, memo, useEffect, useState } from "react";

import InputText from "@/components/common/formElements/inputText";
import trash from "@/style/img/icons/trash.svg";
import {
  addIdToMaterialItemInProgressList,
  removeIdFromMaterialItemInProgressList,
  useInProgressStore,
} from "@/zustand/useInProgressStore";
import { useUserData } from "@/zustand/useSettingsStore";

import styles from "./index.module.scss";
import textStyles from "@/style/textStyles.module.scss";

export type MaterialItem = {
  id: string;
  workOrderLineNumber: string;
  unitsOfMeasurement: string;
  itemNumber: string;
  quantity: string;
  itemName: string;
  materialStatus: string;
  sequenceNumber: number;
  workOrderNumber: string;
};

type Props = {
  data: MaterialItem;
  removeMaterial: (id: string) => void;
  updateMaterial: (id: string, quantity: number) => void;
};

const DELETE_MATERIAL_ITEM = gql`
  mutation deleteWorkOrderMaterial(
    $issuedBy: String!
    $sequenceNumber: Int!
    $workOrderNumber: String!
  ) {
    deleteWorkOrderMaterial(
      issuedBy: $issuedBy
      sequenceNumber: $sequenceNumber
      workOrderNumber: $workOrderNumber
    )
  }
`;

const UPDATE_MATERIAL_ITEM = gql`
  mutation updateWorkOrderMaterialQuantity(
    $issuedBy: String!
    $sequenceNumber: Int!
    $quantity: Float!
    $workOrderNumber: String!
  ) {
    updateWorkOrderMaterialQuantity(
      issuedBy: $issuedBy
      sequenceNumber: $sequenceNumber
      quantity: $quantity
      workOrderNumber: $workOrderNumber
    )
  }
`;

const EachMaterialItem: FC<Props> = ({
  data,
  removeMaterial,
  updateMaterial,
}) => {
  const userData = useUserData();
  const [hasGraphQLError, setHasGraphQLError] = useState<boolean>(false);
  // This is temporal, while editing. But always we want the fresh source-of-truth data it comes from data.quantity rendered
  const [materialQuantity, setMaterialQuantity] = useState<string>(
    (data.quantity ?? "").toString(),
  );
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isEditingQuantity, setIsEditingQuantity] = useState<string>("");
  const [deleteWorkOrderMaterial] = useMutation(DELETE_MATERIAL_ITEM);
  const [updateWorkOrderMaterialQuantity] = useMutation(UPDATE_MATERIAL_ITEM);
  const materialItemInProgress = useInProgressStore(
    (state) => state.materialItemInProgress,
  );

  const handleDeleteMaterialItem = async () => {
    const response = await deleteWorkOrderMaterial({
      variables: {
        issuedBy: userData.mail,
        sequenceNumber: data.sequenceNumber,
        workOrderNumber: data.workOrderNumber,
      },
    });
    setIsDeleting(true);
    if (response?.data?.deleteWorkOrderMaterial === false) {
      setHasGraphQLError(true);
      setIsDeleting(false);
      setTimeout(() => {
        setHasGraphQLError(false);
      }, 3000);
    } else {
      setTimeout(() => {
        removeMaterial(data.id);
      }, 2000);
    }
  };
  // Handle focus to set editing state
  const handleFocus = () => {
    setIsEditing(true);
  };

  const handleUpdateQuantity = async (e) => {
    const quantityValue = e?.target?.value || "";
    // Replace commas with dots and remove any non-numeric characters except for the dot
    const sanitizedQuantity = quantityValue
      .replace(",", ".")
      .replace(/[^0-9.]/g, "");

    setMaterialQuantity(sanitizedQuantity);
    setIsEditing(false);
    setIsEditingQuantity("unsaved");
    const itemId = `${data.itemNumber}-${data.workOrderNumber}-${data.workOrderLineNumber}`;
    addIdToMaterialItemInProgressList(itemId);
    updateMaterial(data.id, sanitizedQuantity);
    const response = await updateWorkOrderMaterialQuantity({
      variables: {
        issuedBy: userData.mail,
        sequenceNumber: data.sequenceNumber,
        quantity: parseFloat(sanitizedQuantity),
        workOrderNumber: data.workOrderNumber,
      },
    });
    removeIdFromMaterialItemInProgressList(itemId);

    if (response?.data?.updateWorkOrderMaterialQuantity === false) {
      setIsEditingQuantity("error");
      setTimeout(() => {
        setIsEditingQuantity("");
      }, 2000);
      setHasGraphQLError(true);
      setTimeout(() => {
        setHasGraphQLError(false);
      }, 3000);
    }
  };

  const isInProgress = materialItemInProgress.some(
    (o) => o.id === data.id, // `${wo?.mCO?.referenceOrderNumber}-${wo?.mCO?.referenceOrderLine}`
  );

  useEffect(() => {
    if (isEditingQuantity === "unsaved" && !isInProgress) {
      setIsEditingQuantity("success");
      setTimeout(() => {
        setIsEditingQuantity("");
      }, 1000);
    }
  }, [data, isEditingQuantity, isInProgress, materialItemInProgress]);
  // Replace commas with dots and remove any non-numeric characters except for the dot
  const sanitizedQuantity = materialQuantity
    ? materialQuantity.replace(",", ".").replace(/[^0-9.]/g, "")
    : "";

  return (
    <div className={styles.materialContainer} key={data.id}>
      <div
        className={`${styles.materialItem} ${
          isDeleting ? styles.isDeleting : ""
        }`}
      >
        <div
          className={`${styles.materialDescription} ${textStyles.secondaryText}`}
          style={{ fontWeight: 400, width: "78%" }}
        >
          {data.itemName}
        </div>
        <span
          className={`${styles.materialStatus} ${textStyles.secondaryText}`}
          style={{
            fontSize: 13,
            width: "13%",
            letterSpacing: "0.08px",
            textOverflow: "ellipsis",
            overflow: "hidden",
            padding: "1px 1px 1px 2px",
            backgroundColor: "#EDF6F8",
            position: "absolute",
            left: "55%",
          }}
        >{` ${data.materialStatus} `}</span>

        <InputText
          className={
            `${styles.inputText} ` +
            `${isEditingQuantity ? styles[isEditingQuantity] : ""}`
          }
          value={isEditing ? sanitizedQuantity : data.quantity}
          onBlur={handleUpdateQuantity}
          onFocus={handleFocus}
          setValue={setMaterialQuantity}
          placeholder="N/A"
          error={false}
          errorMessage=""
          disabled={
            !!(
              data.materialStatus === "COMPLETED" ||
              data.materialStatus === "DELETED"
            )
          }
        />

        <div
          className={`${styles.materialUnits} ${textStyles.secondaryText}`}
          style={{ fontWeight: 400 }}
        >
          {data.unitsOfMeasurement}
        </div>
        {!(
          data.materialStatus === "COMPLETED" ||
          data.materialStatus === "DELETED"
        ) ? (
          <div className={styles.operationActions}>
            <button
              onClick={handleDeleteMaterialItem}
              className={`${styles.deleteButton} stoppropagation`}
              type="button"
              title="Delete Item"
            >
              <img className="stoppropagation" src={trash} alt="trash" />
            </button>
          </div>
        ) : (
          <div className={styles.operationActions} />
        )}
      </div>{" "}
      {hasGraphQLError ? (
        <div style={{ color: "#ff6344", width: "100%" }}>error, try again</div>
      ) : (
        ""
      )}
    </div>
  );
};
export default memo(EachMaterialItem);
