import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import isEmpty from "lodash/isEmpty";
import uniqBy from "lodash/uniqBy";
import React, { FC, memo, useEffect, useState } from "react";

import ReactLoader from "@/components/common/formElements/loader";
import CustomMultiSelect from "@/components/common/formElements/multiselect/customMultiSelect";
import { captureMessage } from "@/helpers/captureError";
import plusIcon from "@/style/img/icons/plus.svg";
import {
  SelectSingleProps,
  WorkOrderLineEquipment,
  WorkOrderLineWithTimestampOperations,
} from "@/types";
import { useSettingsStore, useUserData } from "@/zustand/useSettingsStore";

import {
  ASSIGN_RESOURCE,
  AssignWorkCenterToWorkOrderLineM3InputType,
  AssignWorkCenterToWorkOrderLineM3ReturnType,
  GET_AVAILABLE_EQUIPMENT,
  GET_AVAILABLE_EQUIPMENT_FOR_OPERATOR,
} from "./queries";

import styles from "../sidePanel.module.scss";
import textStyles from "@/style/textStyles.module.scss";

const DEFAULT_PLANNING_AREA = "BASE";

type Props = {
  activeWorkOrderLine: WorkOrderLineWithTimestampOperations | undefined;
  searchValue: string;
  operatorId: string | undefined;
  setEquipment: (v: { name: string; id: string }) => void;
  setIsActiveEquipmentSection: (value: boolean) => void;
};

type FlatWorkOrderLineEquipment = {
  description: string;
  id: string;
  name: string;
  workCenterId: string;
  planningAreaId: string;
  planningAreaCount: string;
};
const EquipmentList: FC<Props> = ({
  activeWorkOrderLine,
  searchValue,
  operatorId,
  setEquipment,
  setIsActiveEquipmentSection,
}) => {
  const userData = useUserData();
  const selectedFacility = useSettingsStore((state) => state.selectedFacility);

  const [assignWorkCenterToWorkOrderLineM3] = useMutation<
    AssignWorkCenterToWorkOrderLineM3ReturnType,
    AssignWorkCenterToWorkOrderLineM3InputType
  >(ASSIGN_RESOURCE);
  const [allEquipment, setAllEquipment] = useState<
    FlatWorkOrderLineEquipment[]
  >([]);
  const [availableEquipment, setAvailableEquipment] = useState<
    FlatWorkOrderLineEquipment[]
  >([]);

  const possiblePlanningAreas = uniqBy(allEquipment, "planningAreaId").map(
    (el) => ({
      label: el.planningAreaId,
      value: el.planningAreaId,
      count: el.planningAreaCount,
    }),
  );

  const selectedPAsForEquip: SelectSingleProps[] = useSettingsStore(
    (state) => state.selectedPAsForEquip,
  );
  const setSelectedPAsForEquip = useSettingsStore(
    (state) => state.setSelectedPAsForEquip,
  );

  const defaultActivePAs =
    selectedPAsForEquip?.length > 0
      ? selectedPAsForEquip
      : [
          {
            value: DEFAULT_PLANNING_AREA,
            label: DEFAULT_PLANNING_AREA,
          },
        ];
  const possibleDefaultActivePAs = defaultActivePAs.filter((a) =>
    possiblePlanningAreas.find((b) => b.value === a.value),
  );

  const { loading } = useQuery(GET_AVAILABLE_EQUIPMENT, {
    variables: {
      facilityId: selectedFacility,
      workCenterType: activeWorkOrderLine?.assignment?.workCenterType,
    },
    onCompleted: (res) => {
      if (!isEmpty(res?.workCentersMachinesByType)) {
        const areaCounts = res?.workCentersMachinesByType.reduce((acc, el) => {
          acc[el?.planningArea] = (acc[el?.planningArea] || 0) + 1;
          return acc;
        }, {});
        const formattedData = res?.workCentersMachinesByType.map(
          (el: WorkOrderLineEquipment) => {
            return {
              id: el.id,
              name: el.name,
              description: el.description,
              workCenterId: el.workCenterId,
              planningAreaId: el.planningArea,
              planningAreaCount: areaCounts[el.planningArea],
            };
          },
        );
        if (!operatorId) {
          setAvailableEquipment(
            formattedData.filter((equip: FlatWorkOrderLineEquipment) =>
              !equip.planningAreaId || isEmpty(selectedPAsForEquip)
                ? [
                    {
                      value: DEFAULT_PLANNING_AREA,
                      label: DEFAULT_PLANNING_AREA,
                    },
                  ]
                    .map((i) => i.value)
                    .includes(equip.planningAreaId)
                : selectedPAsForEquip
                    .map((i) => i.value)
                    .includes(equip.planningAreaId),
            ),
          );
        }
        setAllEquipment(formattedData);
      }
    },
  });

  if (!loading && !activeWorkOrderLine?.assignment?.workCenterType) {
    captureMessage(
      `Error, line ${activeWorkOrderLine.id} from order ${activeWorkOrderLine?.workOrderId} doesn't have workCenterType, when trying to show equipment to be assigned`,
    );
  }

  const handleAssignEquipment = (workCenterId: string) => {
    if (!activeWorkOrderLine?.assignment?.capabilityTypeNumber) {
      captureMessage(
        `No assignment.capabilityTypeNumber in the mutation assignWorkCenterToWorkOrderLineM3. id: ${activeWorkOrderLine?.id}`,
      );
    }
    assignWorkCenterToWorkOrderLineM3({
      variables: {
        facilityId: selectedFacility,
        issuedBy: userData.mail,
        workCenterId,
        workOrderLineId: activeWorkOrderLine?.id ?? "",
        capabilityType:
          activeWorkOrderLine?.assignment?.capabilityTypeNumber?.toString() ??
          "",
      },
    });
  };

  const [getEquipmentForOperator, equipmentsForOperator] = useLazyQuery(
    GET_AVAILABLE_EQUIPMENT_FOR_OPERATOR,
    {
      variables: {
        fromDate: activeWorkOrderLine?.plannedStartDate,
        toDate: activeWorkOrderLine?.plannedStartDate,
        workOrderLineId: activeWorkOrderLine?.id,
      },
    },
  );

  useEffect(() => {
    if (operatorId) {
      setAvailableEquipment([]);
      getEquipmentForOperator({
        variables: {
          operatorId,
          workCenterType: activeWorkOrderLine?.assignment?.workCenterType,
          facilityId: selectedFacility,
        },
      });
    }
  }, [
    activeWorkOrderLine.id,
    activeWorkOrderLine.plannedStartDate,
    activeWorkOrderLine.plannedStopDate,
    selectedFacility,
    getEquipmentForOperator,
    operatorId,
    activeWorkOrderLine?.assignment?.workCenterType,
  ]);

  useEffect(() => {
    if (equipmentsForOperator.data?.workCentersMachinesForOperator) {
      const formattedArray =
        equipmentsForOperator.data?.workCentersMachinesForOperator
          .filter((equip: FlatWorkOrderLineEquipment) =>
            !equip.planningAreaId || isEmpty(selectedPAsForEquip)
              ? [
                  {
                    value: DEFAULT_PLANNING_AREA,
                    label: DEFAULT_PLANNING_AREA,
                  },
                ]
                  .map((i) => i.value)
                  .includes(equip?.planningArea)
              : selectedPAsForEquip
                  .map((i) => i.value)
                  .includes(equip?.planningArea),
          )
          .map((el: WorkOrderLineEquipment) => {
            return {
              id: el.id,
              name: el.name,
              description: el.description,
              workCenterId: el.workCenterId,
              planningAreaId: el.planningArea,
            };
          });

      // ! Now we re-fetch again the equipment list, this time according to what the operator can handle.
      setAvailableEquipment(formattedArray);
    }
  }, [
    equipmentsForOperator.data,
    equipmentsForOperator.data?.workCentersMachinesForOperator,
    selectedPAsForEquip,
  ]);

  return (
    <>
      {!loading && (
        <div className={styles.planningArea}>
          <span className={textStyles.secondaryText}>Planning Area:</span>
          <div
            style={{
              border: "1px solid transparent",
              borderColor: isEmpty(possibleDefaultActivePAs)
                ? "red"
                : "transparent",
            }}
          >
            <CustomMultiSelect
              onChange={(values: SelectSingleProps[]) => {
                const withDefaultPAS =
                  values?.length > 0
                    ? values
                    : [
                        {
                          value: DEFAULT_PLANNING_AREA,
                          label: DEFAULT_PLANNING_AREA,
                        },
                      ];

                setSelectedPAsForEquip(withDefaultPAS);
                if (operatorId) {
                  const formattedArray =
                    equipmentsForOperator.data?.workCentersMachinesForOperator
                      .filter((el: WorkOrderLineEquipment) =>
                        withDefaultPAS
                          .map((i) => i.value)
                          .includes(el?.planningArea),
                      )
                      .map((el: WorkOrderLineEquipment) => {
                        return {
                          id: el.id,
                          name: el.name,
                          description: el.description,
                          workCenterId: el.workCenterId,
                          planningAreaId: el.planningArea,
                        };
                      });
                  setAvailableEquipment(formattedArray);
                } else {
                  setAvailableEquipment(
                    allEquipment.filter(
                      (operator: FlatWorkOrderLineEquipment) =>
                        withDefaultPAS
                          .map((item) => item.value)
                          .includes(operator.planningAreaId),
                    ),
                  );
                }
              }}
              placeholder="PLANNING AREA"
              options={possiblePlanningAreas}
              allButtonName="Select all"
              noneButtonName="Deselect all"
              preselected={[]}
              activeOptions={possibleDefaultActivePAs}
              storageKey="equipment-list-dropdown"
            />
          </div>
        </div>
      )}

      <div className={styles.employeesList}>
        {(loading || equipmentsForOperator?.loading) && <ReactLoader />}
        {!(loading || equipmentsForOperator?.loading) &&
          availableEquipment.map(
            (el: FlatWorkOrderLineEquipment) =>
              el.name.toLowerCase().includes(searchValue.toLowerCase()) && (
                <div key={el.id} className={styles.employeesListItem}>
                  <div>
                    <span
                      className={`${styles.greenBlock} ${textStyles.primaryText}`}
                    >
                      {el.name}
                    </span>
                    {/* <span className={textStyles.primaryText}>{el.description}</span> */}
                  </div>
                  <div className={styles.assignButton}>
                    <button
                      className={styles.iconButton}
                      type="button"
                      onClick={() => {
                        handleAssignEquipment(el.workCenterId);
                        setEquipment({ name: el.name, id: el.id });
                        // UX improvement: Planners will proceed to choose equipment after employee is selected.
                        setIsActiveEquipmentSection(false);
                      }}
                    >
                      <img src={plusIcon} alt="Add input" />
                      <span className={textStyles.secondaryText}>Assign</span>
                    </button>
                  </div>
                </div>
              ),
          )}
        {!loading && isEmpty(availableEquipment) && (
          <div
            className={textStyles.secondaryText}
            style={{ paddingTop: "10px", color: "#888" }}
          >
            {operatorId
              ? "No available equipment for this operator"
              : "No available equipment in this Planning Area"}
            {!activeWorkOrderLine?.assignment?.workCenterType && (
              <span>. (No workCenterType)</span>
            )}
          </div>
        )}
      </div>
    </>
  );
};
export default memo(EquipmentList);
