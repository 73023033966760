import { gql, useMutation } from "@apollo/client";
import React, { FC, useState } from "react";

import CustomSelect from "@/components/common/formElements/reactSelect";
import clock from "@/style/img/clock2.svg";
import trash from "@/style/img/icons/trash.svg";
import { SelectSingleProps } from "@/types";
import { useUserData } from "@/zustand/useSettingsStore";

import OperationLineDates from "../operationLines/operationLineDates";

import styles from "./index.module.scss";
import styles2 from "@/components/common/teamBuilder/index.module.scss";

export type LaborCode = {
  id: string;
  name: string;
  description: string;
};

export type CostingType = {
  id: string;
  name: string;
  description: string;
};

export type TimestampInInvoices = {
  id: string;
  startDate: string;
  stopDate: string;
  laborChargeCodeId: string;
  costingTypeId: string;
  status: string;
  usedLabor: number;
};

const UPDATE_COSTING_TYPE = gql`
  mutation updateCostingType(
    $costingTypeId: String!
    $timestampId: String!
    $operatorId: String!
    $issuedBy: String!
  ) {
    updateCostingType(
      costingTypeId: $costingTypeId
      timestampId: $timestampId
      operatorId: $operatorId
      issuedBy: $issuedBy
    )
  }
`;

const UPDATE_LABOR_CODE = gql`
  mutation updateLaborChargeCode(
    $laborChargeCodeId: String!
    $timestampId: String!
    $operatorId: String!
    $issuedBy: String!
  ) {
    updateLaborChargeCode(
      laborChargeCodeId: $laborChargeCodeId
      timestampId: $timestampId
      operatorId: $operatorId
      issuedBy: $issuedBy
    )
  }
`;

const DELETE_TIMESTAMP = gql`
  mutation deleteTimestampOperation($timestampId: String!, $issuedBy: String!) {
    deleteTimestampOperation(timestampId: $timestampId, issuedBy: $issuedBy)
  }
`;

const Step1Row: FC<{
  timestamp: TimestampInInvoices;
  operatorId: string;
  removeTimestamp: (string) => void;
  allLaborCodes: LaborCode[];
  allCostingTypes: CostingType[];
}> = ({
  timestamp,
  operatorId,
  removeTimestamp,
  allLaborCodes,
  allCostingTypes,
}) => {
  const t = timestamp;
  const userData = useUserData();
  const [deleteTimestamp] = useMutation(DELETE_TIMESTAMP);
  const [updateCostingType] = useMutation(UPDATE_COSTING_TYPE);
  const [updateLaborCode] = useMutation(UPDATE_LABOR_CODE);

  const [isEditingLabor, setIsEditingLabor] = useState<string>("");
  const [isEditingCosting, setIsEditingCosting] = useState<string>("");
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const handleDeleteWorkOrderLine = () => {
    setTimeout(() => {
      removeTimestamp(t.id);
    }, 3000);
    setIsDeleting(true);
    deleteTimestamp({
      variables: {
        timestampId: t.id,
        issuedBy: userData.mail,
      },
    });
  };

  const handleLaborCodeUpdate = (newValue: SelectSingleProps) => {
    if (!newValue?.value) {
      return;
    }
    setIsEditingLabor("unsaved");
    setTimeout(() => {
      setIsEditingLabor("success");
    }, 2200);
    setTimeout(() => {
      setIsEditingLabor("");
    }, 4000);

    updateLaborCode({
      variables: {
        laborChargeCodeId: newValue?.value,
        timestampId: t.id,
        operatorId,
        issuedBy: userData.mail,
      },
    });
  };

  const handleCostingTypeUpdate = (newValue: SelectSingleProps) => {
    if (!newValue?.value) {
      return;
    }
    setIsEditingCosting("unsaved");
    setTimeout(() => {
      setIsEditingCosting("success");
    }, 2200);
    setTimeout(() => {
      setIsEditingCosting("");
    }, 4000);

    updateCostingType({
      variables: {
        costingTypeId: newValue?.value,
        timestampId: t.id,
        operatorId,
        issuedBy: userData.mail,
      },
    });
  };

  const selectedCostingType = allCostingTypes.find(
    (f) => f?.value === t.costingTypeId,
  );
  const selectedLaborChargeCode = allLaborCodes.find(
    (f) => f?.value === t.laborChargeCodeId,
  );

  return (
    <div
      className={`${styles.gridRow}  ${styles.gridRowStep1} ${isDeleting ? styles.deleting : ""}`}
    >
      <div
        className={styles.timestampDates}
        style={{
          marginBottom: 10,
          borderLeft: t?.status === "APPROVED" ? "6px solid #9DC9F5" : "none",
          borderRight: t?.usedLabor === 5 ? "3px solid #E6D2D1" : "none",
        }}
      >
        {!t?.stopDate && (
          <OperationLineDates
            key={t.id}
            dates={{
              startDate: t.startDate,
              stopDate: t.stopDate,
            }}
            timestampId={t.id}
            operatorId={operatorId}
          />
        )}
        {t?.stopDate && (
          <OperationLineDates
            key={t.id}
            dates={{
              startDate: t.startDate,
              stopDate: t.stopDate,
            }}
            timestampId={t.id}
            operatorId={operatorId}
          />
        )}
      </div>

      <div style={{ paddingTop: 5 }}>
        <img
          style={{ paddingTop: 4, paddingRight: 5, verticalAlign: "top" }}
          src={clock}
          alt="total time"
        />
        {t.usedLabor}
      </div>
      <div
        className={`${styles.dropdown} ${
          isEditingLabor !== ""
            ? `${styles.isEditing} ${styles[isEditingLabor]}`
            : ""
        }`}
      >
        <CustomSelect
          value={selectedLaborChargeCode}
          onChange={(value) =>
            handleLaborCodeUpdate(value as SelectSingleProps)
          }
          placeholder={selectedLaborChargeCode?.label || "N/A"}
          options={allLaborCodes || "N/A"}
        />
      </div>

      <div
        className={`${styles.dropdown} ${
          isEditingCosting !== ""
            ? `${styles.isEditing} ${styles[isEditingCosting]}`
            : ""
        }`}
      >
        <CustomSelect
          value={selectedCostingType}
          onChange={(value) =>
            handleCostingTypeUpdate(value as SelectSingleProps)
          }
          placeholder={selectedCostingType?.label || "N/A"}
          options={allCostingTypes}
        />
      </div>
      {!isDeleting ? (
        <button
          className={styles2.actionButton}
          style={{ marginTop: -9, width: 66, zIndex: 0 }}
          type="button"
          title="Delete line"
          onClick={(e) => {
            e.stopPropagation();
            handleDeleteWorkOrderLine();
          }}
        >
          <img src={trash} alt="delete" />
        </button>
      ) : (
        <div style={{ width: 55, display: "flex", justifyContent: "center" }}>
          ...
        </div>
      )}
    </div>
  );
};

export default Step1Row;
