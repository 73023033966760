import React from "react";

import GhostActivity from "@/components/common/ghostActivityForm/ghostActivityWrapper";
import Search from "@/components/common/search/searchPanel";
import Settings from "@/components/common/settings/index";
import TeamBuilder from "@/components/common/teamBuilder/teamBuilder";
import { useSettingsStore } from "@/zustand/useSettingsStore";

import GhostSVG from "./svgElements/ghost";
import SearchSVG from "./svgElements/search";
import SettingsSVG from "./svgElements/settings";
import TeamBuilderSVG from "./svgElements/teamBuilder";

import styles from "./index.module.scss";
import textStyles from "@/style/textStyles.module.scss";

const tabs = [
  {
    label: "GHOST ACTIVITY",
    link: "/ghost-activity",
    icon: <GhostSVG />,
  },
  {
    label: "TEAM BUILDER",
    link: "/team-builder",
    icon: <TeamBuilderSVG />,
  },
  {
    label: "SEARCH",
    link: "/search",
    icon: <SearchSVG />,
  },
  {
    label: "SETTINGS",
    link: "/settings",
    icon: <SettingsSVG />,
  },
];

const SideBar: React.FC = () => {
  const nameOfSidebarTab = useSettingsStore((state) => state.nameOfSidebarTab);
  const closeSidebarTab = useSettingsStore((state) => state.closeSidebarTab);
  const setNameOfSidebarTab = useSettingsStore(
    (state) => state.setNameOfSidebarTab,
  );
  return (
    <>
      <div className={`sidebar-db-click ${styles.mainWrapper}`}>
        {tabs.map((tab) => (
          <div key={tab.link}>
            <button
              className={`${styles.tab} ${
                nameOfSidebarTab === tab.label && styles.active
              }`}
              onClick={() =>
                tab.label === nameOfSidebarTab
                  ? closeSidebarTab()
                  : setNameOfSidebarTab(tab.label)
              }
              type="button"
            >
              {tab.icon}
              <span className={textStyles.primaryText}>{tab.label}</span>
            </button>
          </div>
        ))}
      </div>
      {nameOfSidebarTab === "TEAM BUILDER" && <TeamBuilder />}
      {nameOfSidebarTab === "GHOST ACTIVITY" && <GhostActivity />}
      {nameOfSidebarTab === "SETTINGS" && <Settings />}
      {nameOfSidebarTab === "SEARCH" && <Search />}
    </>
  );
};

export default SideBar;
