import { DesktopDateTimePicker } from "@mui/x-date-pickers";
import moment, { Moment } from "moment-timezone";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";

import { captureError } from "@/helpers/captureError";

type Props = {
  handleChange: (d: Moment | null) => void;
  dateValue: Moment | null;
  label?: string;
  dateFormat?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customStyle?: Record<string, any>;
  autoFocus?: boolean;
  readOnly?: boolean;
  errorJSX?: React.ReactNode;
  withShortcuts?: boolean;
  className?: string;
  disableOpenPicker?: boolean;
};

const style = {
  "&.unsaved .MuiOutlinedInput-root": {
    fieldset: {
      borderColor: "#ffde7d !important",
      borderWidth: "1px",
      borderLeft: "3px solid #ffde7d !important",
    },
  },
  "&.valid .MuiOutlinedInput-root": {
    fieldset: {
      borderColor: "#75d385 !important",
      borderWidth: "1px",
      borderLeft: "3px solid #75d385 !important",
    },
  },
  "&.error .MuiOutlinedInput-root": {
    fieldset: {
      borderColor: "#ff937d !important",
      borderWidth: "1px",
      borderLeft: "3px solid #ff937d !important",
    },
  },

  "& .MuiOutlinedInput-root": {
    fieldset: {
      borderColor: "transparent",
      borderWidth: "1px",
    },
    "&.Mui-focused fieldset, &:hover fieldset": {
      borderColor: "#00adbb",
      borderWidth: "1px",
    },
    "&:hover fieldset": {
      borderColor: "#00adbb",
      borderWidth: "1px",
    },
  },
};

const DEFAULT_DATE_FORMAT = "DD.MM.YYYY HH:mm";

function removeNonAsciiAndWhitespace(inputStr: string): string {
  return inputStr.replace(/[^A-Za-z0-9]/g, "").toLowerCase();
}

function containsLetters(str: string): boolean {
  const letterRegex = /[a-zA-Z]/; // Regex pattern to match any letter (case-insensitive)
  return letterRegex.test(str);
}

const DateAndTimeField: FC<Props> = ({
  dateValue,
  handleChange,
  className = "",
  dateFormat = DEFAULT_DATE_FORMAT,
  customStyle = {},
  autoFocus = false,
  readOnly = false,
  label = "",
  errorJSX = undefined,
  withShortcuts = true,
  disableOpenPicker = false,
}) => {
  // Temporal internal value because it changes as the user is making changes to the field,
  // it changes on every keypress interaction:
  const [tempDate, setTempDate] = useState<Moment | null>(null);
  const [status, setStatus] = useState<string>("");
  const [isTouched, setTouched] = useState<boolean>(false);
  const internalDate = useRef<Moment | null>(null);
  // To avoid error "Can't perform a React state update on an unmounted component"
  const isMounted = useRef(true);
  const finalDateFormat = dateFormat ?? DEFAULT_DATE_FORMAT;
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (dateValue && !isTouched && isMounted.current) {
      setTempDate(dateValue);
    }
  }, [dateValue, isTouched]);

  const saveTheDate = useCallback(
    (event) => {
      const target = event?.target?.value;
      if (
        target &&
        removeNonAsciiAndWhitespace(target) ===
          removeNonAsciiAndWhitespace(finalDateFormat)
      ) {
        setStatus("");
        handleChange(null);
      } else if (tempDate === null || containsLetters(target)) {
        setStatus("unsaved");
      } else if (tempDate && isTouched) {
        // Not sure why, seconds and ms are not 0. Let's reset them before bubbling up the value
        handleChange(
          tempDate.set({
            second: 0,
            millisecond: 0,
          }),
        );

        setStatus("valid");
        setTimeout(() => {
          setStatus("");
        }, 1000);
      }
    },
    [finalDateFormat, handleChange, isTouched, tempDate],
  );

  const handleClickShortcut = useCallback(() => {
    // We can't rely on tempDate, it doesn't reflect the most up-to-date value. We have to use useRef hook
    if (internalDate.current) {
      handleChange(
        internalDate.current.set({
          second: 0,
          millisecond: 0,
        }),
      );
      setTouched(false);
      setStatus("valid");
      setTimeout(() => {
        setStatus("");
      }, 1000);
    }
  }, [handleChange]);

  return (
    <>
      <DesktopDateTimePicker
        value={tempDate}
        className={`${className} nsg-datetime-picker${
          errorJSX !== undefined ? " error" : ""
        }`}
        ampm={false}
        disableOpenPicker={disableOpenPicker}
        sx={{ ...style, ...customStyle }}
        label={label}
        format={finalDateFormat}
        autoFocus={autoFocus}
        readOnly={readOnly}
        // Why viewRenderers like this ? Because https://github.com/mui/mui-x/issues/9326#issuecomment-1590986843
        viewRenderers={{
          hours: null,
          minutes: null,
          seconds: null,
        }}
        onChange={(newValue) => {
          setStatus("unsaved");
          setTempDate(newValue ?? null);
          internalDate.current = newValue ?? null;
          setTouched(true);
        }}
        onError={(error) => {
          setStatus("error");
          captureError(error);
        }}
        slotProps={{
          field: {
            className: status,
          },
          textField: {
            spellCheck: "false",
            // We assume "onBlur" triggers after "onChange" event in the component
            onBlur: saveTheDate,
          },
          ...(withShortcuts && {
            shortcuts: {
              onClick: handleClickShortcut,
              items: [
                {
                  label: "Now",
                  getValue: () => {
                    return moment();
                  },
                },
                {
                  label: "At 8:00",
                  getValue: () => {
                    return moment().hour(8).minute(0).second(0).millisecond(0);
                  },
                },
                {
                  label: "At 12:00",
                  getValue: () => {
                    return moment().hour(12).minute(0).second(0).millisecond(0);
                  },
                },
                {
                  label: "At 12:30",
                  getValue: () => {
                    return moment()
                      .hour(12)
                      .minute(30)
                      .second(0)
                      .millisecond(0);
                  },
                },
                {
                  label: "At 16:00",
                  getValue: () => {
                    return moment().hour(16).minute(0).second(0).millisecond(0);
                  },
                },
              ],
            },
          }),
        }}
      />
      {errorJSX}
    </>
  );
};
export default DateAndTimeField;
